import { NoRecordsFound, PageTitle } from "../../components";
import useAuth from "../../hooks/Log/useAuth";
import { useEffect, useMemo, useState } from "react";
import { DocThumbnail, PdfThumbnail, PictureThumbnail, ProductivityCallFlow, ProductivitySalesCharts, StudentThumbnail, Thumbnail } from "../../assets/img";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { Accordion } from "../Member/components";
import { UserGuide } from "../../assets/docs";

const Academy = () => {

    const strings = useLocalization();

    const academyData = useMemo(() => [
        {
            title: strings.academy.general_operations,
            roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 16, 17, 18, 19],
            contents: [
                {
                    name: "İletişim İzni Güncelleme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=0oW-PjLaibc"
                },
                {
                    name: "Eğitmen Yorumlarının Yapay Zeka İle Analizi",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/QIWZcwiWIGk"
                },
                {
                    name: "Konuşarak Geliş Kullanımı",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=A30u9oouZzE"
                },
                {
                    name: "12 Aylık Paketlerde %20 ek indirim",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/1pIh1IeH5sk?si=j2dcM4AgPqQi5sja"
                },
                {
                    name: "Seri ve Lider Tablosu Özelliği",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/2S1G07ofhAY"
                },
                {
                    name: "Demo Katılım Artırıcı Ekran Güncellemeleri V3",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/n7wq1LZnlzs"
                },
                {
                    name: "Kitap Ses Kayıtlarını Dinleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=kLdOlhDaXL0"
                },
                {
                    name: "Hatalı Cümlelerin Doğru Telaffuzlarını Dinleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=OtdAMiLTP7s"
                },
                {
                    name: "İletişim İzinlerini Güncelleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=U8rLfdmUQ10"
                },
                {
                    name: "Quiz Ekranları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=mYI2Ndg-9t0"
                },
                {
                    name: "Eğitmene Hediye Gönderme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=hIHmRCpIVYU"
                },
                {
                    name: "NPS",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=Ii0AbR-Ve1UU"
                },
                {
                    name: "Ses Kayıtlarının Altyazıları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=Ef76tYqT5Fg"
                },
                {
                    name: "Ders Erteleme Hakkı Satın Alma",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=V75hdz7nFv4"
                },
                {
                    name: "Cümle Kalıpları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=x4GOM3xKF7E"
                },
                {
                    name: "Level Test Kitapları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=ivTn0YKHZsE"
                },
                {
                    name: "Profil Ayarları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=apknRNUXSnc"
                },
                {
                    name: "Ders Öncesi Hatırlatmalar",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/shorts/BJZ102kSuRI"
                },
                {
                    name: "Eğitmen Filtreleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=KmCjORorze0"
                },
                {
                    name: "Panel Kullanım Kılavuzu",
                    contentType: "pdf",
                    academyType: "docs",
                    source: UserGuide
                },
                {
                    name: "Kurumsal Rapor Sayfası",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=GNH9lfd9eOY"
                },
                {
                    name: "Öğrenci İsmi Güncelleme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=fgbGDdFsec0"
                },
                {
                    name: "Whatsapp Bilgilendirme Mesajı Gönderme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=qgAMSt3wQiI"
                },
                {
                    name: "Eğitmen Puanlama",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=7rSQyUJWjQY"
                },
                {
                    name: "Eğitmen Aramaları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=Q5e7expjoj8"
                },
                {
                    name: "AI Yazı/Konuşma Modu",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=HF52jU-Egic"
                },
                {
                    name: "Mentor Kartı",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=dGmWoCJrf_g"
                },
                {
                    name: "Arama Zamanında Hoparlörü Açma",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=eOEvcEmVt2Y"
                },
                {
                    name: "Click2Call Tanıtım",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=a3xNb8UCE5k"
                },
                {
                    name: "Ders Katılım Raporları",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=fgxXmVR6ufY"
                },
                {
                    name: "Dersimi Kaçırdım Hatırlatması ",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/shorts/1aoNfGZTsgk"
                },
                {
                    name: "Alotech Ses Kayıtları",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=g-tqrBu-bnQ"
                },
                {
                    name: "Eğitmen Ses Kaydı",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=rjvU7os1R1A"
                },
                {
                    name: "Satış İşlemleri",
                    contentType: "panel",
                    academyType: "sales",
                    source: "https://www.youtube.com/watch?v=iZfvIKxroro"
                },
                {
                    name: "Referans İşlemleri",
                    contentType: "panel",
                    academyType: "sales",
                    source: "https://www.youtube.com/watch?v=ujBGYA7Dk1Y"
                },
                {
                    name: "48 Saat İndirimleri",
                    contentType: "panel",
                    academyType: "sales",
                    source: "https://www.youtube.com/watch?v=_2EiXwYM0Rc"
                },
                {
                    name: "Onboarding Egzersizleri",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=R372usVGZQQ"
                },
                {
                    name: "Gramer Kitap Videoları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=6O0bf-lK0TE"
                },
                {
                    name: "Teacher Josiah Kurgusu",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=Oaz_fX6dZBg"
                },
                {
                    name: "Ders Sonrası Bildirimler",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/shorts/t4o6CGvrGTA"
                },
                {
                    name: "Ders Dondurmuş Öğrenci",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=i_LYSjJme6g"
                },
                {
                    name: "Hesaba Giriş Linki",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=TXhKuYQjuLg"
                },
                {
                    name: "Paket Değişikliği Güncelleme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=nOKHyqJH2-k"
                },
                {
                    name: "Eğitim Programları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=6XibTmy2TgU"
                },
                {
                    name: "Konuşarak Öğren Referans Kurgusu",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=YpYxUJDAcW8"
                },
                {
                    name: "Yapay Zeka İle Seviye Tespiti",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=qDU2uCnlkFI"
                },
                {
                    name: "Demo Dersi Alma",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=TMF4_I9pVKs"
                },
                {
                    name: "Tüm Kaynaklar",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=sNaGqoJAKe0"
                },
                {
                    name: "Market Ekranları Güncelleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=C7E4F3sVTPg"
                },
                {
                    name: "İzinler",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=hv0_ln1ON78"
                },
                {
                    name: "Ders Dondurma Sonrası Bildirimler",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/shorts/ge1MqT57Ljg"
                },
                {
                    name: "Tüm Kaynaklar - İş İngilizcesi",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=Q9MvRWNW1RQ"
                },
                {
                    name: "Videolarla Öğren",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=DylDTmbvrx4"
                },
                {
                    name: "Bilgi Mimarisi",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=5zZL-913JIE"
                },
                {
                    name: "Yurtdışı Arama Logları",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=RtyCUEP_MhI"
                },
                {
                    name: "Sınavlara Hazırlık",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=BM72fhauzdk"
                },
                {
                    name: "Karşılama Ekranı Güncelleme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=heJUwrUweqw"
                },
                {
                    name: "Dashboard Demo Öğrenci Güncellemesi",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=XQDMxcrf6HM"
                },
                {
                    name: "BIN Sorgulama",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=CHAaY8sglaE"
                },
                {
                    name: "Yapay Zeka İle Seviye Tespiti",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=KoIc7UnmrzI"
                },
                {
                    name: "Aile Paketi İndirimi",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=-ulQ-5uPRME"
                },
                {
                    name: "Demo Ders Kitap Seçimi Ve Puanlama",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=pofJC-iODPA"
                },
                {
                    name: "Ziraat Pos Güncelleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://www.youtube.com/watch?v=k_tCEsL1Vak"
                },
                {
                    name: "Demo Ders Kaydı Açma",
                    contentType: "video",
                    academyType: "panel_and_productivity",
                    source: "https://www.youtube.com/watch?v=Egfk9vZvKVc"
                },
                {
                    name: "Demo Katılım Artırıcı Ekran Güncellemeleri",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/ZmBgyPr6oww"
                },
                {
                    name: "Başlangıç Araması",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/zkkqNSKbJ1A"
                },
                {
                    name: "Ek Paket Kaydının Açılması",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/teVwoSKVtuo"
                },
                {
                    name: "Yeniden Dene Kaydının Açılması",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/uI1sSPfKwYw"
                },
                {
                    name: "Habbit Bildirimleri",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/bm_mX4wJf5g"
                },
                {
                    name: "Profil Ekranı Güncellemesi",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/M1WOCbeQVR0"
                },
                {
                    name: "AI Demo Satış Sistemi Yorumu",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/6ZbjUrye3ew"
                },
                {
                    name: "Market Ekran Güncellemeleri",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/ZGPbxSVL8fg"
                },
                {
                    name: "Cümle Bankası",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/zCRJTDMzbu8"
                },
                {
                    name: "İzinler - KVKK Sözleşmesi Onayı",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/2bRqszgxd2g"
                },
                {
                    name: "Konuşma Labı",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/hiOr2N6P4Yo"
                },
                {
                    name: "Mülakat Pro ve Seviye Testi AI Satış Sistemi",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/mCVrmxOhuCg"
                },
                {
                    name: "Whatsapp Mesajına Numarayı Kaydetme Notu Ekleme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/EUy_YV4WraE"
                },
                {
                    name: "Kuveyttürk Bankası 3d İle Ödeme",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/QnSqCrNTgWI"
                },
                {
                    name: "3CX click2call Entegrasyonu",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/NqmGCWjunZ4"
                },
                {
                    name: "Demo Katılım Artırıcı Ekran Güncellemeleri V4",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/EImNwRL27KA"
                },
                {
                    name: "Aile Paketi Telefon İle Üye Ekleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/M2_d70JmYR4"
                },
                {
                    name: "Kitapların Toplu PDF'lerinin Gösterilmesi",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/BBXpiqaIV60"
                },
                {
                    name: "Onboarding V2",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/v_INUtPAWhs"
                },
                {
                    name: "Telefon Numarası İle Referans Ekleme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/YS4k5UUjYSc"
                },
                {
                    name: "Mentor NPS",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/b8ic6uZ4vqE"
                },
                {
                    name: "Üye Profil Sayfası Güncellemeleri",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/THHk4mjVyDU"
                },
                {
                    name: "Yeniden Dene Yeni Görevler",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/fm3lJOAkk78"
                },
                {
                    name: "Market Otomatik İndirim Uygulama",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/M5sp9CsRDDE"
                },
                {
                    name: "Aile Paketinin Öğrenci Detay Sayfasında Görünmesi",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/YeKN8WhRHow"
                },
                {
                    name: "Teacher Sandy'de Sabitle Butonu",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/FjTnXvJBa2c"
                },
                {
                    name: "2024 Yıl Özeti",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/-hoEwhzv5oA"
                },
                {
                    name: "Kitapların PDF'lerini Toplu İndirme ",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/7ePi7TBmLXg"
                },
                {
                    name: "Aileye Katılma İsteği Gönderme",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/k3JDWf2GITM"
                },
                {
                    name: "AI-Tutor Özelliği",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/BY0InSLk9wo"
                },
                {
                    name: "Kumbara İndirimi Aktarma",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/OYP-Xqwyflk"
                },
                {
                    name: "Mentör Mesajları",
                    contentType: "student_video",
                    academyType: "student_screen",
                    source: "https://youtu.be/V1v6KkRb2c0"
                }
            ]
        },
        {
            title: strings.academy.productivity_operations,
            roles: [1, 2, 3, 7, 8, 9, 17, 18, 19],
            contents: [
                {
                    name: "Verimlilik Temsilcisinin Görevleri",
                    contentType: "document",
                    academyType: "docs",
                    source: "https://docs.google.com/document/d/1jwyIWiVelI-q9PWyFPbGEbJ-EwH8B3IZ/edit?pli=1"
                },
                {
                    name: "Paket Uzatma",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=gjz1TY2VeyY&ab_channel=Konu%C5%9Farak%C3%96%C4%9Fren%C4%B0ngilizceE%C4%9Fitimleri"
                },
                {
                    name: "Paket Değişikliği",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/WiFINU-yMTo"
                },
                {
                    name: "Eğitim Paketleri",
                    contentType: "document",
                    academyType: "docs",
                    source: "https://docs.google.com/document/d/1lh3Mtq6g0fSQtzBvwZ-pWKGXUUfU1Z9w"
                },
                {
                    name: "Verimlilik Departmanı Arama Akışı",
                    contentType: "picture",
                    academyType: "docs",
                    source: ProductivityCallFlow
                },
                {
                    name: "Verimlilik Departmanı Satış Baremleri",
                    contentType: "picture",
                    academyType: "docs",
                    source: ProductivitySalesCharts
                },
                {
                    name: "Eğitmen&Saat Değişikliği Nasıl Yapılır? Teacher Josiah ve Sandy Nedir?",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/M5ZfqdQSw0w"
                },
                {
                    name: "Eğitim Paketleri Nelerdir ve Genel İngilizce Nedir?",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/jEEMOgbmuok"
                },
                {
                    name: "Gramer Paketi Nedir?",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/XH2-k2ef-qY"
                },
                {
                    name: "Kampüs Paketi Nedir?",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/oPpPEaww8ZE"
                },
                {
                    name: "Premium Paket Nedir?",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/Q-icFSrmHsM"
                },
                {
                    name: "Sunum İngilizcesi Nedir?",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/ayResywsbOs"
                },
                {
                    name: "İş ingilizcesi Nedir? (Part 1)",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/TDd8hGw0Khk"
                },
                {
                    name: "İş ingilizcesi Nedir? (Part 2)",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/Oj45eTkdGbw"
                },
                {
                    name: "Sınavların Eğitim İçerikleri",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/7X5MNTLoe4A"
                },
                {
                    name: "Başlangıç Araması (Part 1)",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://youtu.be/2uVL9CeKdeQ"
                }
            ]
        },
        {
            title: strings.academy.sales_operations,
            roles: [1, 2, 3, 4, 5, 6, 16, 17, 18, 19],
            contents: [
                {
                    name: "İlk Kayıt",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=xwEPd2zh9po"
                },
                {
                    name: "Marka Taşıma",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=OkIqyvLP3XU"
                },
                {
                    name: "Whatsapp’tan Dönüş Butonu",
                    contentType: "video",
                    academyType: "panel",
                    source: "https://www.youtube.com/watch?v=8vg-6Efakvw"
                },
                {
                    name: "Eğitim Paketleri",
                    contentType: "document",
                    academyType: "docs",
                    source: "https://docs.google.com/document/d/1UZmo5IZ3ZkAHUHVK-NVe4_1bL_8_C6pc"
                }
            ]
        }
    ]);


    const { employeeTypeId } = useAuth();

    const [viewData, setViewData] = useState([]);

    useEffect(() => {
        let tmpData = [];

        academyData.length > 0 && academyData.map((item) => {
            if (item.roles.includes(+employeeTypeId)) {
                tmpData.push(item);
            }
        })

        setViewData(viewData => tmpData);
    }, [employeeTypeId])

    return (
        <>
            {viewData.map((item, index) => (
                <div key={index}>
                    <PageTitle title={item.title} />
                    {item.contents.filter(content => content.academyType === "panel").length > 0 && (
                        <div className="mb-5">
                            <Accordion key={index}
                                outSideClose={false}
                                isOpen={true}
                                title={strings.academy.panel_usage}

                                content={
                                    <div className="flex flex-wrap gap-y-10 max-md:justify-center gap-x-5 px-5 py-1">
                                        {item.contents
                                            .filter(content => content.academyType === "panel")
                                            .map((content, contentIndex) => (
                                                <div key={contentIndex} className="w-[320px] flex flex-col gap-2">
                                                    <a href={content.source} target="_blank" className="hover:scale-[1.02]" rel="noreferrer">
                                                        <div className="relative">
                                                            <img
                                                                src={
                                                                    content.contentType === "video" ? Thumbnail
                                                                        : content.contentType === "document" ? DocThumbnail
                                                                            : content.contentType === "picture" ? PictureThumbnail
                                                                                : content.contentType === "student_video" ? StudentThumbnail
                                                                                    : content.contentType === "pdf" ? PdfThumbnail
                                                                                        : Thumbnail
                                                                }
                                                                className="w-full rounded-md border border-md-gray"
                                                            />
                                                            <p className="absolute bottom-2 left-0 right-0 text-center text-white font-bold">{content.name}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                    </div>
                                }
                            />
                        </div>
                    )}
                    {item.contents.filter(content => content.academyType === "student_screen").length > 0 && (
                        <div className="mb-5">
                            <Accordion key={index}
                                outSideClose={false}
                                title={strings.academy.student_screen_usage}

                                content={
                                    <div className="flex flex-wrap gap-y-10 max-md:justify-center gap-x-5 px-5 py-1">
                                        {item.contents
                                            .filter(content => content.academyType === "student_screen")
                                            .map((content, contentIndex) => (
                                                <div key={contentIndex} className="w-[320px] flex flex-col gap-2">
                                                    <a href={content.source} target="_blank" className="hover:scale-[1.02]" rel="noreferrer">
                                                        <div className="relative">
                                                            <img
                                                                src={
                                                                    content.contentType === "video" ? Thumbnail
                                                                        : content.contentType === "document" ? DocThumbnail
                                                                            : content.contentType === "picture" ? PictureThumbnail
                                                                                : content.contentType === "student_video" ? StudentThumbnail
                                                                                    : content.contentType === "pdf" ? PdfThumbnail
                                                                                        : Thumbnail
                                                                }
                                                                className="w-full rounded-md border border-md-gray"
                                                            />
                                                            <p className="absolute bottom-2 left-0 right-0 text-center text-white font-bold">{content.name}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                    </div>
                                }
                            />
                        </div>
                    )}
                    {item.contents.filter(content => content.academyType === "docs").length > 0 && (
                        <div className="mb-5">
                            <Accordion key={index}
                                outSideClose={false}
                                title={strings.academy.document}

                                content={
                                    <div className="flex flex-wrap gap-y-10 max-md:justify-center gap-x-5 px-5 py-1">
                                        {item.contents
                                            .filter(content => content.academyType === "docs")
                                            .map((content, contentIndex) => (
                                                <div key={contentIndex} className="w-[320px] flex flex-col gap-2">
                                                    <a href={content.source} target="_blank" className="hover:scale-[1.02]" rel="noreferrer">
                                                        <div className="relative">
                                                            <img
                                                                src={
                                                                    content.contentType === "video" ? Thumbnail
                                                                        : content.contentType === "document" ? DocThumbnail
                                                                            : content.contentType === "picture" ? PictureThumbnail
                                                                                : content.contentType === "student_video" ? StudentThumbnail
                                                                                    : content.contentType === "pdf" ? PdfThumbnail
                                                                                        : Thumbnail
                                                                }
                                                                className="w-full rounded-md border border-md-gray"
                                                            />
                                                            <p className="absolute bottom-2 left-0 right-0 text-center text-white font-bold">{content.name}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                    </div>
                                }
                            />
                        </div>
                    )}
                    {item.contents.filter(content => content.academyType === "sales").length > 0 && (
                        <div className="mb-5">
                            <Accordion key={index}
                                outSideClose={false}
                                title={strings.academy.sales}

                                content={
                                    <div className="flex flex-wrap gap-y-10 max-md:justify-center gap-x-5 px-5 py-1">
                                        {item.contents
                                            .filter(content => content.academyType === "sales")
                                            .map((content, contentIndex) => (
                                                <div key={contentIndex} className="w-[320px] flex flex-col gap-2">
                                                    <a href={content.source} target="_blank" className="hover:scale-[1.02]" rel="noreferrer">
                                                        <div className="relative">
                                                            <img
                                                                src={
                                                                    content.contentType === "video" ? Thumbnail
                                                                        : content.contentType === "document" ? DocThumbnail
                                                                            : content.contentType === "picture" ? PictureThumbnail
                                                                                : content.contentType === "student_video" ? StudentThumbnail
                                                                                    : content.contentType === "pdf" ? PdfThumbnail
                                                                                        : Thumbnail
                                                                }
                                                                className="w-full rounded-md border border-md-gray"
                                                            />
                                                            <p className="absolute bottom-2 left-0 right-0 text-center text-white font-bold">{content.name}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                    </div>
                                }
                            />
                        </div>
                    )}
                    {item.contents.filter(content => content.academyType === "panel_and_productivity").length > 0 && (
                        <div className="mb-5">
                            <Accordion key={index}
                                outSideClose={false}
                                title={strings.academy.sales_and_productivity}

                                content={
                                    <div className="flex flex-wrap gap-y-10 max-md:justify-center gap-x-5 px-5 py-1">
                                        {item.contents
                                            .filter(content => content.academyType === "panel_and_productivity")
                                            .map((content, contentIndex) => (
                                                <div key={contentIndex} className="w-[320px] flex flex-col gap-2">
                                                    <a href={content.source} target="_blank" className="hover:scale-[1.02]" rel="noreferrer">
                                                        <div className="relative">
                                                            <img
                                                                src={
                                                                    content.contentType === "video" ? Thumbnail
                                                                        : content.contentType === "document" ? DocThumbnail
                                                                            : content.contentType === "picture" ? PictureThumbnail
                                                                                : content.contentType === "student_video" ? StudentThumbnail
                                                                                    : content.contentType === "pdf" ? PdfThumbnail
                                                                                        : Thumbnail
                                                                }
                                                                className="w-full rounded-md border border-md-gray"
                                                            />
                                                            <p className="absolute bottom-2 left-0 right-0 text-center text-white font-bold">{content.name}</p>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                    </div>
                                }
                            />
                        </div>
                    )}
                </div>
            ))}

            {viewData.length === 0 && <NoRecordsFound />}
        </>
    )
}

export default Academy;