import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Ai } from "../../../../../../assets/svg";
import { Loading } from "../../../../../../components";
import { AIKeys, SalesAiType } from "../../../../../../consts/Constants";
import useAiRouting from "../../../../../../hooks/Utils/useAiRouting";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { CheckAiAnalysisShowStatus } from "../../../../../../services/Member/MemberSalesAiAnalysisService";

const MemberSalesAiAnalysis = ({ memberId, showAiDemoAnalysis, aiUseCaseSessionExists, modal, modalContent, setModal, setModalContent }) => {

    const strings = useLocalization();    

    const levelTestUseCaseId = useAiRouting(AIKeys.LEVEL_FINDER);
    const interviewProUseCaseId = useAiRouting(AIKeys.INTERVIEW_PR0);

    const [loading, setLoading] = useState(false);
    const [aiSummaryAndStorytellingShowStatus, setAiSummaryAndStorytellingShowStatus] = useState(false);
    const [previousModalState, setPreviousModalState] = useState(false);

    const fetchAiAnalysisStatus = () => {
        if(memberId) {
            setLoading(true);
            CheckAiAnalysisShowStatus(memberId)
                .then(result => {
                    if(result.status === 200)
                        setAiSummaryAndStorytellingShowStatus(JSON.parse(result.content));
                })
                .catch()
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    // Initial data fetch
    useEffect(() => {
        fetchAiAnalysisStatus();
    }, [memberId]);

    // Trigger service call when modal is closed and modalContent.element is "salesaianalysis"
    useEffect(() => {
        // Check if modal was previously open and is now closed
        if (previousModalState === true && modal === false) {
            // Check if the modal that was closed was a salesaianalysis modal
            if (modalContent && modalContent.element === "salesaianalysis") {
                fetchAiAnalysisStatus();
            }
        }
        
        // Update previous modal state
        setPreviousModalState(modal);
    }, [modal, modalContent]);

    return (
        <div className="flex flex-col gap-4">
            {loading && <Loading className="-mt-2" />}
            {!loading && (
                <>
                    {aiSummaryAndStorytellingShowStatus &&
                        <>
                            {/* AI Demo Özeti */}
                            <AnalysisSection
                                title={strings.member.member_detail.profile.sales_analysis.summary_title}
                                analysisType={SalesAiType.SUMMARY}
                                onShowDetail={() => {
                                    setModal(true);
                                    setModalContent({
                                        element: "salesaianalysis",
                                        props: {
                                            analysisType: SalesAiType.SUMMARY
                                        }
                                    });
                                }}
                            />

                            {/* AI Storytelling */}
                            <AnalysisSection
                                title={strings.member.member_detail.profile.sales_analysis.storytelling_title}
                                analysisType={SalesAiType.STORYTELLING}
                                onShowDetail={() => {
                                    setModal(true);
                                    setModalContent({
                                        element: "salesaianalysis",
                                        props: {
                                            analysisType: SalesAiType.STORYTELLING
                                        }
                                    });
                                }}
                            />
                        </>
                    }

                    {/* AI Demo Satış Sistemi Yorumu */}
                    {showAiDemoAnalysis &&
                        <AnalysisSection
                            title={strings.member.member_detail.profile.sales_analysis.sales_analysis_title}
                            analysisType={SalesAiType.DEMO}
                            onShowDetail={() => {
                                setModal(true);
                                setModalContent({
                                    element: "salesaianalysis",
                                    props: {
                                        analysisType: SalesAiType.DEMO
                                    }
                                });
                            }}
                        />
                    }

                    {/* AI Onboarding Satış Sistemi Yorumu */}
                    <AnalysisSection
                        title={strings.member.member_detail.profile.sales_analysis.onboarding_title}
                        analysisType={SalesAiType.ONBOARDING}
                        onShowDetail={() => {
                            setModal(true);
                            setModalContent({
                                element: "salesaianalysis",
                                props: {
                                    analysisType: SalesAiType.ONBOARDING
                                }
                            });
                        }}
                    />

                    {/* AI Level Test Satış Sistemi Yorumu */}
                    {aiUseCaseSessionExists.levelTestUseCaseExisting &&
                        <AnalysisSection
                            title={strings.member.member_detail.profile.sales_analysis.level_test_title}
                            analysisType={SalesAiType.LEVEL_TEST}
                            onShowDetail={() => {
                                setModal(true);
                                setModalContent({
                                    element: "salesaianalysis",
                                    props: {
                                        analysisType: SalesAiType.LEVEL_TEST,
                                        useCaseId: levelTestUseCaseId
                                    }
                                });
                            }}
                        />
                    }

                    {/* AI Mülakat Pro Satış Sistemi Yorumu */}
                    {aiUseCaseSessionExists.interviewProUseCaseExisting &&
                        <AnalysisSection
                            title={strings.member.member_detail.profile.sales_analysis.interview_pro_title}
                            analysisType={SalesAiType.INTERVIEW_PRO}
                            onShowDetail={() => {
                                setModal(true);
                                setModalContent({
                                    element: "salesaianalysis",
                                    props: {
                                        analysisType: SalesAiType.INTERVIEW_PRO,
                                        useCaseId: interviewProUseCaseId
                                    }
                                });
                            }}
                        />
                    }
                </>
            )}
        </div>
    );
};

const AnalysisSection = ({ title, analysisType, onShowDetail }) => {

    const strings = useLocalization();
    const isHighlighted = analysisType === SalesAiType.SUMMARY || analysisType === SalesAiType.STORYTELLING;

    return (
        <div className={`${isHighlighted ? 'bg-blue/5 border-blue/20' : 'bg-white border-[#ebebeb]'} rounded-lg shadow-sm border transition-all hover:shadow-md`}>
            <div className="flex items-center justify-between p-4">
                <div className="flex items-center gap-2">
                    <div className={`${isHighlighted ? 'bg-blue/20' : 'bg-blue/10'} p-2 rounded-lg`}>
                        <img src={Ai} alt="ai" width={24} height={24} />
                    </div>
                    <div className="flex items-center gap-1">
                        <p className={`font-semibold ${isHighlighted ? 'text-blue-700' : ''}`}>{title}</p>
                        {isHighlighted && (
                            <div className="group relative">
                                <span className="material-symbols-outlined text-[16px] text-blue-600 cursor-pointer mt-1.5">
                                    info
                                </span>
                                <div className={classNames("tooltip-top", {
                                    "-top-[90px]": analysisType === SalesAiType.SUMMARY,
                                    "-top-[70px]": analysisType === SalesAiType.STORYTELLING
                                })}>
                                    {analysisType === SalesAiType.SUMMARY && strings.member.member_detail.profile.sales_analysis.summary_description}
                                    {analysisType === SalesAiType.STORYTELLING && strings.member.member_detail.profile.sales_analysis.storytelling_description}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <button
                    type="button"
                    className={`flex items-center gap-0.5 ${isHighlighted ? 'text-blue-600 hover:bg-blue/15' : 'text-blue hover:bg-blue/10'} px-4 py-2 rounded-lg transition-colors`}
                    onClick={onShowDetail}
                >
                    <span className="material-symbols-outlined text-[18px]">
                        visibility
                    </span>
                    <span> {strings.member.member_detail.profile.sales_analysis.show_detail}</span>
                </button>
            </div>
        </div>
    )
};

export default MemberSalesAiAnalysis;