import React, { useState } from 'react';
import { Input, showTopAlert } from '../../../../../../../components';
import { RightType } from '../../../../../../../consts/Constants';
import { checkNumber } from '../../../../../../../components/Utility';
import { useLocalization } from '../../../../../../../hooks/Utils/useLocalization';
import { UpdateTermRight } from '../../../../../../../services/Term/TermService';
import useAuth from "../../../../../../../hooks/Log/useAuth";

const TeacherTimeChangeRightModal = (props) => {

  const { right, closeButton, termId, sendRequestToTeacherTimeChangeRightEndpoint } = props;
  const strings = useLocalization();
  const { employee } = useAuth();

  const [input, setInput] = useState(right);

  const submitHandle = async (e) => {

    e.preventDefault();

    //Kullanıcı herhangi bir değişiklik yapmadan 'kaydet' butonuna basarsa endpointe istek atmadan kullanıcıya başarılı alert'ini döndürüyoruz.
    if(right === input){
        showTopAlert(strings.member.member_detail.terms.modal.teacher_time_change_right_success, "success");
        setTimeout(() => closeButton() , 1000);

    } else {
        const model = {
            termId: termId,
            rightTypeId: RightType.TeacherHourChange,
            right: parseInt(input),
            createUserId: employee?.EmployeeId
        };
    
        const result = await UpdateTermRight(model);
    
        if(result.status === 200){
            showTopAlert(strings.member.member_detail.terms.modal.teacher_time_change_right_success, "success");
            sendRequestToTeacherTimeChangeRightEndpoint();
            setTimeout(() => closeButton() , 1000);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }
  }

  return (
    <>
        <form onSubmit={submitHandle} className="flex flex-col text-start">
            <label htmlFor="right_count">{strings.member.member_detail.terms.teacher_time_change_right}</label>
            <Input 
                id="right_count"
                type="number"
                onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}   
                defaultValue={right}
                onChange={(e) => setInput(e.target.value)}
            />
            <div className="flex gap-3 justify-center">
                <button
                    type='submit' 
                    className="button search-button gap-3 text-white bg-blue border-blue mt-7 px-10"
                >
                    {strings.button.save_upper}
                </button>
                <button 
                    type='button'
                    className="button search-button gap-3 text-white bg-red border-red focus:border-red mt-7 px-10"
                    onClick={closeButton}
                >
                    {strings.button.cancel_upper}
                </button>
            </div>
        </form>
    </>
  )
}

export default TeacherTimeChangeRightModal;