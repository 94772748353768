import { PageTitle } from "../../components";
import { useLocalization } from "../../hooks/Utils/useLocalization";

const CallTracking = () => {
    const strings = useLocalization();

    return (
        <>
            <PageTitle title={strings.sidebar.report.call_tracking} />

            <div className="flex justify-center mt-5">
                <iframe 
                    title="call-tracking" 
                    width="1725" 
                    height="1725" 
                    src="https://phpapi.clickivo.com/_prime-api/_daily_call_reports/dcr.php" 
                    frameBorder="0" 
                    allowFullScreen>
                </iframe>
            </div>
        </>
    );
};

export default CallTracking;
