import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AddInvoiceModal, AddPaymentModal, EditPaymentModal } from '../../..';
import { LargeButton, Loading, Modal, NoRecordsFound, PageTitle, showCenteredAlertTwoButtons, showCenteredAlertTwoButtonsWithParameters, showTopAlert } from '../../../../../../components';
import { SquareButtonWithoutLink } from '../../../../../../components/Buttons';
import {
    AccountingStatusType,
    Brands,
    Channels,
    GeneralStatusType,
    OrderStatusType,
    PaymentStatusType,
    PaymentTypeName
} from '../../../../../../consts/Constants';
import {
    addTimeZoneToFullDate2,
    formatPrice,
    getCurrencySymbolByBrandId,
    isItSpecified,
    roundPrice
} from '../../../../../../components/Utility';
import useAuth from '../../../../../../hooks/Log/useAuth';
import { useLocalization } from '../../../../../../hooks/Utils/useLocalization';
import { GetOrders, ListPayments } from '../../../../../../services/Accounting/PaymentService';
import { ApproveOrder, ApprovePayment, DeletePayment, UndoDeletePayment } from '../../../../../../services/Payment/PaymentService';
import Accordion from '../../../Common/Accordion';
import { getButtonPermission } from "../../../../../../rights/Utility";
import classNames from "classnames";
import useMemberBrand from "../../../../../../hooks/Member/useMemberBrand";
import AppliedDiscountModal from '../../../../../Accounting/components/Payment/AppliedDiscountModal';

const Payments = () => {

    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;

    const { memberId } = useParams() || false;
    const { employee, employeeTypeId, checkPermission } = useAuth();
    const memberBrandId = useMemberBrand(memberId);

    const language = useSelector(state => state.localizationStore.language);
    const currencySymbol = getCurrencySymbolByBrandId(memberBrandId);

    const topRef = useRef();

    const approvePaymentButtonRoles = getButtonPermission({ group: "memberDetailAccounting", name: "approvePayment" });
    const approvePaymentPermission = checkPermission(approvePaymentButtonRoles, employeeTypeId);

    const deletePaymentButtonRoles = getButtonPermission({ group: "memberDetailAccounting", name: "deletePayment" });
    const deletePaymentPermission = checkPermission(deletePaymentButtonRoles, employeeTypeId);

    const addInvoiceButtonRoles = getButtonPermission({ group: "memberDetailAccounting", name: "addInvoice" });
    const addInvoicePermission = checkPermission(addInvoiceButtonRoles, employeeTypeId);

    const addPaymentButtonRoles = getButtonPermission({ group: "memberDetailAccounting", name: "addPayment" });
    const addPaymentPermission = checkPermission(addPaymentButtonRoles, employeeTypeId);

    const completePaymentButtonRoles = getButtonPermission({ group: "memberDetailAccounting", name: "completePayment" });
    const completePaymentPermission = checkPermission(completePaymentButtonRoles, employeeTypeId);

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [orders, setOrders] = useState([]);
    const [orderId, setOrderId] = useState("");
    const [payments, setPayments] = useState([]);

    const closeButton = () => {
        setModal(false);
        getOrdersAndPayments(memberId);
    }

    const areAlmostEqual = (num1, num2, threshold) => {
        return Math.abs(num1 - num2) < threshold;
    }

    //Bir siparişteki tüm ödemelerin onaylı olup olmadığını kontrol eden fonksiyon.
    const checkAllPaymentsApproved = (orderId) => {
        const filteredPayments = payments && payments.filter(payment => payment.orderId === orderId);
        const notChecked = filteredPayments && filteredPayments.some(fp => fp.accountingStatusType === AccountingStatusType.NotChecked);

        return notChecked;
    }

    //Siparişleri ve ödemeleri getiren fonksiyon.
    const getOrdersAndPayments = async (memberId) => {
        const resultOrders = await GetOrders(memberId);

        if (resultOrders.status === 200) {
            setOrders(JSON.parse(resultOrders.content));
        }

        const resultPayments = await ListPayments(memberId);

        if (resultPayments.status === 200) {
            setPayments(JSON.parse(resultPayments.content));
        }

        if (resultPayments.status === 200 && resultOrders.status === 200) {
            setLoading(loading => false);
        }
    }

    //'Sipariş Tamamlandı' işlemini gerçekleştiren fonksiyon.
    const approveOrder = async (orderId) => {

        const result = await ApproveOrder(orderId);

        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.accounting.order_approve_success, "success");
            topRef.current.focus();
            sendRequestToEndpoint();
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    //'Ödemeyi Onayla' işlemini gerçekleştiren fonksiyon.
    const approvePayment = async (paymentId) => {

        const result = await ApprovePayment(paymentId, employee?.EmployeeId);

        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.accounting.approve_payment_success, "success");
            sendRequestToEndpoint();
            window.location.reload();
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    //Onaylanmayan Ödemeleri silme işlemini gerçekleştiren fonksiyon.
    const deletePayment = async (paymentId) => {

        const result = await DeletePayment(paymentId);

        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.accounting.delete_payment_success, "success");
            sendRequestToEndpoint();
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    //Silinen ödemeleri geri alma işlemini gerçekleştiren fonksiyon.
    const undoDeletePayment = async (paymentId) => {

        const result = await UndoDeletePayment(paymentId);

        if (result.status === 200) {
            showTopAlert(strings.member.member_detail.accounting.undo_delete_success, "success");
            sendRequestToEndpoint();
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    const sendRequestToEndpoint = () => {
        if (memberId) {
            getOrdersAndPayments(memberId);
        }
    }

    useEffect(() => {
        sendRequestToEndpoint();
    }, [])

    return (
        <>
            <PageTitle title={strings.member.member_detail.header.content.payments} />
            {loading ? (
                <Loading />
            ) : (
                <>
                    <p tabIndex={-1} ref={topRef} className='h-0'></p>

                    {/* Sipariş Oluştur Butonu */}
                    {/* <div className="mt-5 flex justify-end px-5">
                        <button
                            className="button search-button gap-3 text-white bg-green border-green focus:border-green"
                            onClick={() => { setModal(true); setModalContent({ element: "createOrder" }) }}
                        >
                            <span className="material-symbols-outlined">add</span>
                            <p>{strings.member.member_detail.accounting.create_order}</p>
                        </button>
                    </div> */}

                    {orders.length > 0 ? orders.map((order, index) => {

                        let orderPayments = payments.filter(p => p.orderId === order.id);

                        //Başarılı ve aktif ama ödemesi onaylanmamış ödemeleri topla
                        let unConfirmedPayments = payments.filter(
                            p => p.orderId === order.id &&
                                p.paymentStatusType === PaymentStatusType.SuccessfulPayment &&
                                p.accountingStatusType === AccountingStatusType.NotChecked &&
                                p.status === GeneralStatusType.Active).reduce((s, a) => s + a.price, 0
                                );

                        //Başarılı ve aktif ödemeleri topla
                        let totalPayment = payments.filter(
                            p => p.orderId === order.id &&
                                p.paymentStatusType === PaymentStatusType.SuccessfulPayment &&
                                p.status === GeneralStatusType.Active).reduce((s, a) => s + a.price, 0
                                );

                        //Başarılı ödemelerin sayısını bul
                        let successfulPayment = Object.keys(payments.filter(
                            p => p.orderId === order.id &&
                                p.paymentStatusType === PaymentStatusType.SuccessfulPayment
                        )).length;

                        return (
                            <Accordion key={index}
                                outSideClose={true}
                                title={order.packageName}
                                button={
                                    <>
                                        {/* Sipariş tamamlanmadı ve toplam ödeme ile başarılı ödeme tutarları eşit değil.
                                            Ödeme ekle modalı açılabilir.
                                        */}
                                        {order.status === OrderStatusType.NotPaid && areAlmostEqual(totalPayment) !== areAlmostEqual(order.price) && (
                                            <>
                                                <LargeButton
                                                    iconName="library_add"
                                                    iconClassnames="bg-blue border-blue"
                                                    iconText={strings.member.member_detail.accounting.add_payment}
                                                    isDisabled={!addPaymentPermission}
                                                    action={() => {
                                                        setModal(true);
                                                        setModalContent({ element: "addPayment", orderId: order.id, maxValue: parseFloat(order.price) - parseFloat(totalPayment) });
                                                    }}
                                                />
                                            </>
                                        )}
                                        {/* Sipariş tamamlanmadı. Ama toplam ödeme ile başarılı ödeme tutarları eşit.
                                            Siparişi Tamamlama modalı açılabilir.
                                        */}
                                        {order.status === OrderStatusType.NotPaid && successfulPayment > 0 && areAlmostEqual(totalPayment) === areAlmostEqual(order.price) && (
                                            <>
                                                <LargeButton
                                                    iconName="library_add"
                                                    iconClassnames="bg-blue border-blue"
                                                    iconText={strings.member.member_detail.sales_operations.complete_order}
                                                    isDisabled={checkAllPaymentsApproved(order.id) || !completePaymentPermission}
                                                    action={() => {
                                                        const secondQuestion = () => showCenteredAlertTwoButtonsWithParameters(
                                                            strings.alert.centered_alert_two_buttons.save_question,
                                                            strings.alert.centered_alert_two_buttons.irreversible_action,
                                                            strings.button.confirm,
                                                            strings.alert.centered_alert_two_buttons.cancel_text,
                                                            approveOrder,
                                                            [order.id]
                                                        )
                                                        showCenteredAlertTwoButtons(
                                                            strings.alert.centered_alert_two_buttons.title,
                                                            strings.member.member_detail.accounting.approve_order_question,
                                                            strings.button.confirm,
                                                            strings.alert.centered_alert_two_buttons.cancel_text,
                                                            secondQuestion
                                                        )
                                                    }}
                                                />
                                            </>
                                        )}
                                        {/* Sipariş tamamlandı. Fatura eklenebilir. */}
                                        {order.status === OrderStatusType.Paid && (
                                            <>
                                                <LargeButton
                                                    iconClassnames="border-[#808080] bg-[#808080] cursor-default"
                                                    iconText={strings.member.member_detail.accounting.order_completed}
                                                />
                                                <LargeButton
                                                    iconName="library_add"
                                                    iconClassnames="bg-blue border-blue"
                                                    iconText={strings.member.member_detail.accounting.add_invoice}
                                                    isDisabled={!addInvoicePermission}
                                                    action={() => {
                                                        setModal(true);
                                                        setModalContent({ element: "addInvoice" }); setOrderId(orderId => order.id);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </>
                                }
                                content={
                                    <>
                                        <div className="flex gap-x-4 gap-y-2 flex-wrap justify-center w-full">
                                            <div className="rounded-md border border-[#808080] bg-card p-2 text-center w-full sm:max-w-max">
                                                <p>{strings.member.member_detail.accounting.order_amount}: <span className='font-bold' >{formatPrice(order.price)}{currencySymbol}</span> </p>
                                            </div>
                                            <div className="rounded-md border border-[#808080] bg-card p-2 text-center w-full sm:max-w-max">
                                                <p>{strings.member.member_detail.accounting.successfull_payment_amount}: <span className='font-bold' >{formatPrice(totalPayment)} {currencySymbol}</span> </p>
                                            </div>
                                            <div className="rounded-md border border-[#808080] bg-card p-2 text-center w-full sm:max-w-max">
                                                <p>{strings.member.member_detail.accounting.not_approved_payment_amount}: <span className='font-bold' >{formatPrice(unConfirmedPayments)} {currencySymbol}</span> </p>
                                            </div>
                                            {areAlmostEqual(totalPayment) !== areAlmostEqual(order.price) && successfulPayment > 0 && (
                                                <div className="rounded-md border border-red bg-red text-white p-2 text-center w-full sm:max-w-max">
                                                    <p>{strings.member.member_detail.accounting.underpayment_amount}: <span className='font-bold' >{formatPrice(parseFloat(order.price) - parseFloat(totalPayment))} {currencySymbol}</span> </p>
                                                </div>
                                            )}
                                            <button
                                                className="rounded-md border border-[#808080] bg-card hover:bg-[#E5E5E5] p-2 text-center w-full sm:max-w-max cursor-pointer relative group"
                                                onClick={() => {
                                                    setModal(true);
                                                    setModalContent({ element: "discounts", orderId: order.id });
                                                }}
                                            >
                                                <p>{strings.table.all_applied_discounts}: <span className='font-bold' > %{order.totalDiscountRate}</span> </p>
                                                <span className="tooltip-top">
                                                    {strings.table.click_for_show_all_discounts}
                                                </span>
                                            </button>

                                        </div>
                                        <hr className="w-full mt-6 mb-2" />
                                        <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto w-full">
                                            <div className="inline-block min-w-full rounded-lg overflow-hidden mb-3">
                                                <table className="min-w-full leading-normal text-header">
                                                    <thead>
                                                        <tr>
                                                            <th className="th">
                                                                {strings.table.operation_informations}
                                                            </th>
                                                            <th className="th">
                                                                {strings.table.package_payment_informations}
                                                            </th>
                                                            <th className="th">
                                                                {strings.member.member_detail.header.operations}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderPayments.length > 0 ? orderPayments.map((payment, index) => (
                                                            <tr key={index}>
                                                                <td className="td bg-[#f0f0f0]">
                                                                    <div className="flex flex-col items-center gap-1 min-w-max md:max-w-max">
                                                                        <p className={`${payment.paymentStatusType === 1 ? "bg-green" : "bg-red"} text-white text-center w-[125px] px-3 py-1 align-middle text-[11.3px] border border-[#dddddd] rounded-full`}>
                                                                            {payment.paymentStatusType === 1 ? strings.member.member_detail.accounting.successfull_payment : strings.member.member_detail.accounting.unsuccessfull_payment}
                                                                        </p>
                                                                        {
                                                                            payment.isReferencePayment && (
                                                                                <div className='bg-[#666666] text-white w-[125px] text-center px-3 py-1 align-middle text-[11.3px] border border-[#dddddd] rounded-full'>
                                                                                    <p>{strings.member.member_detail.accounting.reference_payment}</p>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            payment.isFamilyPayment && (
                                                                                <div className='bg-md-gray w-[125px] text-center px-3 py-1 align-middle text-[11.3px] border border-[#dddddd] rounded-full'>
                                                                                    <p>{strings.member.member_detail.accounting.family_payment}</p>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <p>{payment.memberFullName}</p>
                                                                        <p>{addTimeZoneToFullDate2(payment.paymentDate, employee.TimeZone)}</p>
                                                                    </div>
                                                                </td>
                                                                <td className="td align-middle bg-[#f0f0f0]">
                                                                    <div className="min-w-max">
                                                                        <div className="flex items-center">
                                                                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                                                                apartment
                                                                            </span>
                                                                            <p>{isItSpecified(payment.brandLocalCompanyName, notSpecified)}</p>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                                                                account_balance
                                                                            </span>
                                                                            <p>
                                                                                <span>{payment.bankName && (payment.bankName + " - ")}{language === "tr" ? PaymentTypeName.find(pt => pt.id === payment.paymentType).tr : PaymentTypeName.find(pt => pt.id === payment.paymentType).name}</span>
                                                                                <span> - {formatPrice(payment.price)} {currencySymbol}</span>
                                                                                {memberBrandId !== Brands.KO && <span> - {payment.turkishLiraPrice} ₺</span>}
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                                                                info
                                                                            </span>
                                                                            <p>{isItSpecified(payment.statusMessage, strings.errors.it_is_not_specified)}</p>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                                                                credit_card
                                                                            </span>
                                                                            <p>
                                                                                <span className="font-bold">{strings.member.member_detail.sales_operations.payment_option}:</span> {payment.installment} -
                                                                                <span className="font-bold"> {strings.member.member_detail.sales_operations.kdv}:</span> {payment.taxValue} -
                                                                                <span className="font-bold"> {strings.member.member_detail.accounting.source}:</span> {Channels.find(st => st.id === payment.sourceType)[language]}
                                                                                {payment.approvalCode && (<> - <span className="font-bold"> {strings.form_info.approve_code}:</span> {payment.approvalCode}</>)}
                                                                            </p>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                                                                how_to_reg
                                                                            </span>
                                                                            <p>{payment.salesType}</p>
                                                                        </div>
                                                                        <div className="flex items-center">
                                                                            <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                                                                                bookmark
                                                                            </span>
                                                                            <p>
                                                                                <span className="font-bold">TermId: </span>
                                                                                {isItSpecified(payment.termId !== "00000000-0000-0000-0000-000000000000" ? payment.termId : "", strings.errors.it_is_not_specified)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="td align-middle bg-[#f0f0f0]">
                                                                    <div className="flex gap-1">
                                                                        {/* Ödemenin başarılı olduğu durumda 'Ödemeyi Onayla' butonu gösterilir. */}
                                                                        {payment.paymentStatusType === PaymentStatusType.SuccessfulPayment &&
                                                                            (payment.accountingStatusType === AccountingStatusType.NotChecked && payment.status === GeneralStatusType.Active) &&
                                                                            <SquareButtonWithoutLink
                                                                                classnames={classNames("bg-blue", {
                                                                                    "opacity-70 hover:opacity-70 !cursor-not-allowed": !approvePaymentPermission
                                                                                })}
                                                                                iconClassnames={classNames("", {
                                                                                    "opacity-70 hover:opacity-70 !cursor-not-allowed": !approvePaymentPermission
                                                                                })}
                                                                                iconName="check_circle"
                                                                                tooltip={approvePaymentPermission ? strings.button.confirm : strings.errors.not_authorized}
                                                                                tooltipClassnames="tooltip-top"
                                                                                action={() => {
                                                                                    if (approvePaymentPermission) {
                                                                                        showCenteredAlertTwoButtonsWithParameters(
                                                                                            strings.alert.centered_alert_two_buttons.title,
                                                                                            strings.member.member_detail.accounting.approve_payment_question,
                                                                                            strings.button.confirm,
                                                                                            strings.alert.centered_alert_two_buttons.cancel_text,
                                                                                            approvePayment,
                                                                                            [payment.id]
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        {/* 
                                                                            Ödemenin başarılı olduğu durumda ve ödemenin onaylandığı durumda 'Ödeme Onaylandı' kutucuğu gösterilir.
                                                                            Sadece bilgi vermek amaçlıdır. Herhangi bir işlem yapmaz.
                                                                        */}
                                                                        {payment.paymentStatusType === PaymentStatusType.SuccessfulPayment &&
                                                                            payment.accountingStatusType === AccountingStatusType.Checked &&
                                                                            <LargeButton
                                                                                iconName="check_circle"
                                                                                iconClassnames="border-green bg-green hover:opacity-100 cursor-default"
                                                                                iconText={strings.member.member_detail.accounting.payment_approved}
                                                                            />
                                                                        }
                                                                        {/* 
                                                                            Ödemenin başarılı olduğu fakat onaylanmadığı durumda 'Ödemeyi Sil' butonu gösterilir.
                                                                            Ödeme durumunun 'akfit' olması gerekmektedir.
                                                                        */}
                                                                        {payment.paymentStatusType === PaymentStatusType.SuccessfulPayment &&
                                                                            (payment.accountingStatusType === AccountingStatusType.NotChecked && payment.status === GeneralStatusType.Active) &&
                                                                            <SquareButtonWithoutLink
                                                                                classnames={classNames("bg-red", {
                                                                                    "opacity-70 hover:opacity-70 !cursor-not-allowed": !deletePaymentPermission
                                                                                })}
                                                                                iconClassnames={classNames("", {
                                                                                    "opacity-70 hover:opacity-70 !cursor-not-allowed": !deletePaymentPermission
                                                                                })}
                                                                                iconName="delete"
                                                                                tooltip={approvePaymentPermission ? strings.button.delete : strings.errors.not_authorized}
                                                                                tooltipClassnames="tooltip-top"
                                                                                action={() => {
                                                                                    if (deletePaymentPermission) {
                                                                                        showCenteredAlertTwoButtonsWithParameters(
                                                                                            strings.alert.centered_alert_two_buttons.title,
                                                                                            strings.member.member_detail.accounting.delete_payment_question,
                                                                                            strings.button.confirm,
                                                                                            strings.alert.centered_alert_two_buttons.cancel_text,
                                                                                            deletePayment,
                                                                                            [payment.id]
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        {/* 
                                                                            Ödemenin başarılı olduğu ve durumunun 'pasif' olduğu (Silinmiş) durumda sadece 'Geri Al' butonu gösterilir.
                                                                            Silme işlemi geri alınabilir
                                                                        */}
                                                                        {payment.paymentStatusType === PaymentStatusType.SuccessfulPayment &&
                                                                            payment.status === GeneralStatusType.Passive &&
                                                                            <SquareButtonWithoutLink
                                                                                classnames="bg-red"
                                                                                iconName="undo"
                                                                                tooltip={roundPrice(totalPayment) + roundPrice(payment.price) > roundPrice(order.price) ? strings.member.member_detail.accounting.undo_delete_error : strings.button.undo}
                                                                                tooltipClassnames="tooltip-top !-top-[57px]"
                                                                                isDisabled={roundPrice(totalPayment) + roundPrice(payment.price) > roundPrice(order.price)}
                                                                                action={() => {
                                                                                    showCenteredAlertTwoButtonsWithParameters(
                                                                                        strings.alert.centered_alert_two_buttons.title,
                                                                                        strings.member.member_detail.accounting.undo_delete_question,
                                                                                        strings.button.confirm,
                                                                                        strings.alert.centered_alert_two_buttons.cancel_text,
                                                                                        undoDeletePayment,
                                                                                        [payment.id]
                                                                                    )
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )) : (
                                                            (
                                                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                                                    <td colSpan="3">
                                                                        <NoRecordsFound />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </>
                                }
                            />)
                    }) : (<NoRecordsFound />)}
                </>
            )}

            {(Object.keys(modalContent).length !== 0) && (
                <Modal modal={modal} setModal={setModal} classes={{ modal: modalContent.element === "create" || modalContent.element === "edit" ? 'h-[90vh] md:h-[70vh] max-w-[1140px]' : modalContent.element === "discounts" ? "h-[450px] max-w-[620px]" : 'h-[90vh] max-w-[1140px]' }}>
                    {/* {modalContent.element === "createOrder" && <CreateOrderModal memberId={memberId} closeButton={closeButton} classes="max-md:!h-[65vh]" />} */}
                    {modalContent.element === "addPayment" && <AddPaymentModal memberId={memberId} orderId={modalContent.orderId} maxValue={modalContent.maxValue} closeButton={closeButton} classes="max-md:!h-[65vh]" />}
                    {modalContent.element === "editPayment" && <EditPaymentModal closeButton={closeButton} classes="max-md:!h-[65vh]" />}
                    {modalContent.element === "addInvoice" && <AddInvoiceModal closeButton={closeButton} memberId={memberId} orderId={orderId} />}
                    {modalContent.element === "discounts" && <AppliedDiscountModal orderId={modalContent.orderId} />}
                </Modal>
            )}
        </>
    )
}

export default Payments;