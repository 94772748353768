import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Ai } from "../../../../../../assets/svg";
import { Loading } from "../../../../../../components";
import CopyToClipboard from "../../../../../../components/CopyToClipboard";
import { TextBadge } from "../../../../../../components/Badges";
import { SalesAiType, ServiceErrorMessages } from "../../../../../../consts/Constants";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetAiHistoryToAiAnalysis, GetAiUseCaseAiAnalysis, GetMemberAiSalesAnalysis, GetStorytellingAiAnalysis, GetSummaryAiAnalysis } from "../../../../../../services/Member/MemberSalesAiAnalysisService";

const SalesAiAnalysisModal = ({ analysisType, useCaseId }) => {
    
    const strings = useLocalization();

    const { memberId } = useParams();

    const [loading, setLoading] = useState(false);
    const [analysis, setAnalysis] = useState("");
    const [title, setTitle] = useState("");
    const [showRenewedBadge, setShowRenewedBadge] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const fetchAnalysis = useCallback(async () => {
        if (loading) return;

        setLoading(true);
        setError(false);

        try {
            if (analysisType === SalesAiType.DEMO) {
                const result = await GetMemberAiSalesAnalysis(memberId);
                if (result.status === 200) {
                    setAnalysis(result.content);
                } else {
                    if(ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                        setError(true);
                    } else if(ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.sales_analysis_not_found);
                    } else if(ServiceErrorMessages.TERM_SCHEDULE_NOT_FOUND === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.demo_term_schedule_not_found);
                    } else {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.sales_analysis_not_found);
                    }
                }
            } else if (analysisType === SalesAiType.ONBOARDING) {
                const result = await GetAiUseCaseAiAnalysis(memberId);
                if (result.status === 200) {
                    setAnalysis(result.content);
                } else {
                    if(ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                        setError(true);
                    } else if(ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.onboarding_not_found);
                    } else if(ServiceErrorMessages.MEMBER_NOT_HAVE_ONBOARDING_USE_CASE === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.member_not_have_onboarding_use_case);
                    } else {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.onboarding_not_found);
                    }
                }
            } else if (analysisType === SalesAiType.SUMMARY) {
                const result = await GetSummaryAiAnalysis(memberId);
                if (result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setAnalysis(resultContent.aiAnalysisResult);
                    setShowRenewedBadge(resultContent.isContentRefreshed);
                } else {
                    if(ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                        setError(true);
                    } else if(ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content
                            || ServiceErrorMessages.SUMMARY_DOES_NOT_SUPPORT_LESS_THAN_TWO_AI_ANALYSIS_RESULTS === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.summary_not_found);
                    } else if(ServiceErrorMessages.MEMBER_DOES_NOT_HAVE_ANY_AI_ANALYSIS_RESULT === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.member_does_not_have_any_ai_analysis_result);
                    } else {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.summary_not_found);
                    }
                }
            } else if (analysisType === SalesAiType.STORYTELLING) {
                const result = await GetStorytellingAiAnalysis(memberId);
                if (result.status === 200) {
                    const resultContent = JSON.parse(result.content);
                    setAnalysis(resultContent.aiAnalysisResult);
                    setShowRenewedBadge(resultContent.isContentRefreshed);
                } else {
                    if(ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                        setError(true);
                    } else if(ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content 
                        || ServiceErrorMessages.STORYTELLING_DOES_NOT_SUPPORT_LESS_THAN_TWO_AI_ANALYSIS_RESULTS === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.storytelling_not_found);
                    } else if(ServiceErrorMessages.MEMBER_DOES_NOT_HAVE_ANY_AI_ANALYSIS_RESULT === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.member_does_not_have_any_ai_analysis_result);
                    } else {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.storytelling_not_found);
                    }
                }
            } else {
                const result = await GetAiHistoryToAiAnalysis(memberId, analysisType, useCaseId);
                if (result.status === 200) {
                    setAnalysis(result.content);
                } else {
                    setError(true);
                    if(ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content) {
                        setError(true);
                    } else if(ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content) {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.onboarding_not_found);
                    } else {
                        setErrorMessage(strings.member.member_detail.profile.sales_analysis.onboarding_not_found);
                    }
                }
            }
        } catch (error) {
            setError(true);
        }

        setLoading(false);
    }, [memberId, analysisType, loading, useCaseId]);

    const formatContent = (text) => {
        if (!text) return "";

        // ai'den </br> şeklinde gelen yazıları silip boşlukları düzenlemek için kullanılıyor
        let formattedText = text.replace(/\s*<\/br>/g, '\n');

        // ai'den gelen ** işaretleri arasındaki yazıları kalın yazı yapmak için kullanılıyor
        const parts = formattedText.split(/(\*\*.*?\*\*)/g);

        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <strong key={index} className="font-bold">
                        {part.slice(2, -2)}
                    </strong>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };

    useEffect(() => {
        if (analysisType === SalesAiType.DEMO) {
            setTitle(strings.member.member_detail.profile.sales_analysis.sales_analysis_title);
        } else if (analysisType === SalesAiType.ONBOARDING) {
            setTitle(strings.member.member_detail.profile.sales_analysis.onboarding_title);
        } else if (analysisType === SalesAiType.LEVEL_TEST) {
            setTitle(strings.member.member_detail.profile.sales_analysis.level_test_title);
        } else if (analysisType === SalesAiType.INTERVIEW_PRO) {
            setTitle(strings.member.member_detail.profile.sales_analysis.interview_pro_title);
        } else if (analysisType === SalesAiType.SUMMARY) {
            setTitle(strings.member.member_detail.profile.sales_analysis.summary_title);
        } else if (analysisType === SalesAiType.STORYTELLING) {
            setTitle(strings.member.member_detail.profile.sales_analysis.storytelling_title);
        }
    }, [analysisType]);

    useEffect(() => {
        let abortController = new AbortController();

        fetchAnalysis();

        return () => {
            abortController.abort();
        }
    }, []);

    return (
        <div className="flex flex-col gap-3 p-4">
            <div className="flex items-center gap-1 justify-between">
                <div className="flex items-center gap-1">
                    <img src={Ai} alt="ai" width={20} height={20} />
                    <p className="font-bold py-3 text-[14px]">{title}</p>
                    {showRenewedBadge && (
                        <TextBadge className="hidden md:inline-flex bg-green border-green text-white ml-2 mb-1 h-5">
                            {strings.member.member_detail.profile.sales_analysis.analysis_renewed}
                        </TextBadge>
                    )}
                </div>
                {!loading && !error && analysis.length > 0 && (
                    <CopyToClipboard text={analysis} className="hover:bg-gray-100 p-1 rounded-full mt-1" />
                )}
            </div>
            <hr />
            <div className="h-[calc(100vh-250px)] overflow-y-auto">
                {loading && <Loading />}
                {!loading && error && (
                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="flex items-center gap-1 border border-dark-gray hover:bg-card h-6 max-w-max px-3 rounded-[10px]"
                            onClick={fetchAnalysis}
                        >
                            <span className="material-symbols-outlined text-[16px] font-bold">
                                sync
                            </span>
                            {strings.button.try_again}
                        </button>
                    </div>
                )}
                {!loading && !error && analysis.length === 0 ? (
                    <p className="text-center">{errorMessage}</p>
                ) : (
                    <div className="whitespace-pre-line leading-relaxed">
                        {formatContent(analysis)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SalesAiAnalysisModal;