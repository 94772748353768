import { fetchApi } from '../index';

export const GetMemberAiSalesAnalysis = async (memberId) => {

    const url = '/member/v1/member_sales_ai_analysis/send_captions_to_ai_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetAiHistoryToAiAnalysis = async (memberId, salesAiType, aiUseCaseId) => {

    const url = '/member/v1/member_sales_ai_analysis/send_ai_history_to_ai_analysis/';

    const payload = {
        memberId: memberId,
        salesAiType: salesAiType,
        aiUseCaseId: aiUseCaseId
    };

    return await fetchApi(url, payload);
}

export const GetAiUseCaseAiAnalysis = async (memberId) => {

    const url = '/member/v1/member_sales_ai_analysis/send_use_cases_to_ai_analysis/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const CheckAiAnalysisShowStatus = async (memberId) => {

    const url = '/member/v1/member_ai_analysis_narrative/check_ai_analysis_show_status/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetStorytellingAiAnalysis = async (memberId) => {

    const url = '/member/v1/member_ai_analysis_narrative/send_ai_analysis_results_to_storytelling/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetSummaryAiAnalysis = async (memberId) => {

    const url = '/member/v1/member_ai_analysis_narrative/send_ai_analysis_results_to_summary/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}