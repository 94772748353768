import AuthRoute from "../pages/AuthRoute";
import { CheckAuth } from "../pages/Redirect";
import { AuthLayout, Login, ForgotPassword, VerifyCode, UpdatePassword } from "../pages/Auth";
import { Dashboard, DashboardLayout } from "../pages/Dashboard";
import { MemberLayout, Student, AddCandidate, CandidatePool, Candidate, NewCandidate, TermSchedule, MemberDetailLayout } from "../pages/Member";
import {
    AddReferrerMember,
    CreateReference,
    Log,
    Moneybox,
    Profile,
    ReferencedMember,
    TaskNote,
    SendSms,
    SendEmail,
    SendOffer,
    CurrentTasks,
    CompletedTasks,
    Applications,
    Employees,
    Tickets,
    SetReminder,
    DropPool,
    CallTypes,
    ContactInformations,
    AddStudentRequest,
    AddStudentTransfer,
    DemoRegistration,
    HourlyDiscount,
    Registration,
    Payments,
    CurrentCards,
    Invoices,
    AdditionalService,
    ChangeTerm,
    FormNote,
    MemberNote,
    CallRecords,
    MoveBrand,
    LessonRecord, 
    OldSystemNote, 
    Pending
} from "../pages/Member/components";
import { EmployeeLayout, Employee, CreateEmployee, EditEmployee, EmployeeDetail } from "../pages/Employee";
import { TicketLayout, Ticket, TicketDetail } from "../pages/Ticket";
import { TaskLayout, Task } from "../pages/Task";
import { Reminder, ReminderLayout } from "../pages/Reminder";
import { AccountingLayout, CurrentCard, Invoice, Payment, Campaign, AddCampaign, DiscountCode, CampaignDetail, DiscountCodeDetail } from "../pages/Accounting";
import {AllTeacherProgram, TeacherLayout, TeacherProgram} from "../pages/Teacher";
import { UseCaseLayout, UseCaseList, CreateUseCase, UpdateUseCase } from "../pages/UseCase";
import { UseCaseHistoryLayout, UseCaseHistoryList, UseCaseHistoryDetail } from "../pages/UseCaseHistory";
import { ErrorLayout } from "../pages/Error404";
import {
    CandidatePoolReport,
    DailyReport,
    NPSReport,
    ReportLayout,
    SalesmanReport,
    LessonAttendReport,
    TargetFollowAnalysis,
    CallReport,
    ReferenceDashboard,
    InactiveReport,
    MarketReport,
    DemoDashboard, PanelEvents,
    MentorNpsReport,
    CallTracking,
} from "../pages/Report";
import { Academy, AcademyLayout} from "../pages/Academy";
import { PhoneNumbersReport, AppCommentDashboard, FamilyPackageDashboard, ProductReportLayout } from "../pages/ProductReport";

const routes = [

    {
        name: "home",
        path: "/",
        roles: [],
        element: <CheckAuth />,
    },
    {
        name: "auth",
        path: "auth",
        roles: [],
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Login />
            },
            {
                name: "login",
                path: "login",
                roles: [],
                element: <Login />
            },
            {
                name: "forgotpassword",
                path: "forgotpassword",
                roles: [],
                element: <ForgotPassword />
            },
            {
                name: "verifycode",
                path: "verifycode",
                roles: [],
                element: <VerifyCode />
            },
            {
                name: "updatepassword",
                path: "updatepassword",
                roles: [],
                element: <UpdatePassword />
            },
        ]
    },
    {
        name: "dashboard",
        path: "dashboard",
        auth: true,
        roles: [],
        element: <DashboardLayout />,
        children: [
            {
                index: true,
                auth: true,
                roles: [],
                element: <Dashboard />
            },
        ]
    },
    {
        name: "academy",
        path: "academy",
        auth: true,
        roles: [],
        element: <AcademyLayout />,
        children: [
            {
                index: true,
                auth: true,
                roles: [],
                element: <Academy />
            }
        ]
    },
    {
        name: "member",
        path: "member",
        auth: true,
        roles: [],
        element: <MemberLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <Student />
            },
            {
                name: "student",
                path: "student",
                auth: true,
                roles: [],
                element: <Student />
            },
            {
                name: "candidate",
                path: "candidate",
                auth: true,
                roles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 24],
                element: <Candidate />
            },
            {
                name: "candidatepool",
                path: "candidatepool",
                auth: true,
                roles: [2, 3, 5, 8, 14, 15, 18, 20],
                element: <CandidatePool />
            },
            {
                name: "newcandidate",
                path: "newcandidate",
                auth: true,
                roles: [1, 2, 3, 5, 15, 18, 20],
                element: <NewCandidate />
            },
            {
                name: "addcandidate",
                path: "addcandidate",
                auth: true,
                roles: [2, 3, 5, 8, 14, 15, 18, 20, 24],
                element: <AddCandidate />
            },
            {
                name: "termschedule",
                path: "termschedule/:termId",
                auth: true,
                roles: [],
                element: <TermSchedule />
            },
            {
                name: "detail",
                path: "detail",
                auth: true,
                roles: [],
                element: <MemberDetailLayout />,
                children: [
                    {
                        index: true,
                        auth: true,
                        name: "profile",
                        path: "profile/:memberId",
                        roles: [],
                        element: <Profile />
                    },
                    {
                        name: "createreference",
                        path: "createreference/:memberId",
                        auth: true,
                        roles: [],
                        element: <CreateReference />
                    },
                    {
                        name: "moneybox",
                        path: "moneybox/:memberId",
                        auth: true,
                        roles: [],
                        element: <Moneybox />
                    },
                    {
                        name: "addreferrer",
                        path: "addreferrer/:memberId",
                        auth: true,
                        roles: [],
                        element: <AddReferrerMember />
                    },
                    {
                        name: "referenced",
                        path: "referenced/:memberId",
                        auth: true,
                        roles: [],
                        element: <ReferencedMember />
                    },
                    {
                        name: "log",
                        path: "log/:memberId",
                        auth: true,
                        roles: [],
                        element: <Log />
                    },
                    {
                        name: "tasknote",
                        path: "tasknote/:memberId",
                        auth: true,
                        roles: [],
                        element: <TaskNote />
                    },
                    {
                        name: "membernote",
                        path: "membernote/:memberId",
                        auth: true,
                        roles: [],
                        element: <MemberNote />
                    },
                    {
                        name: "formnote",
                        path: "formnote/:memberId",
                        auth: true,
                        roles: [],
                        element: <FormNote />
                    },
                    {
                        name: "oldsystemnote",
                        path: "oldsystemnote/:memberId",
                        auth: true,
                        roles: [],
                        element: <OldSystemNote />
                    },
                    {
                        name: "sendsms",
                        path: "sendsms/:memberId",
                        auth: true,
                        roles: [],
                        element: <SendSms />
                    },
                    {
                        name: "sendemail",
                        path: "sendemail/:memberId",
                        auth: true,
                        roles: [],
                        element: <SendEmail />
                    },
                    {
                        name: "sendoffer",
                        path: "sendoffer/:memberId",
                        auth: true,
                        roles: [],
                        element: <SendOffer />
                    },
                    {
                        name: "currenttasks",
                        path: "currenttasks/:memberId",
                        auth: true,
                        roles: [],
                        element: <CurrentTasks />
                    },
                    {
                        name: "completedtasks",
                        path: "completedtasks/:memberId",
                        auth: true,
                        roles: [],
                        element: <CompletedTasks />
                    },
                    {
                        name: "applications",
                        path: "applications/:memberId",
                        auth: true,
                        roles: [],
                        element: <Applications />
                    },
                    {
                        name: "employees",
                        path: "employees/:memberId",
                        auth: true,
                        roles: [],
                        element: <Employees />
                    },
                    {
                        name: "tickets",
                        path: "tickets/:memberId",
                        auth: true,
                        roles: [],
                        element: <Tickets />
                    },
                    {
                        name: "calltypes",
                        path: "calltypes/:memberId",
                        auth: true,
                        roles: [],
                        element: <CallTypes />
                    },
                    {
                        name: "contact",
                        path: "contact/:memberId",
                        auth: true,
                        roles: [],
                        element: <ContactInformations />
                    },
                    {
                        name: "addrequest",
                        path: "addrequest/:memberId",
                        auth: true,
                        roles: [],
                        element: <AddStudentRequest />
                    },
                    {
                        name: "addtransfer",
                        path: "addtransfer/:memberId",
                        auth: true,
                        roles: [],
                        element: <AddStudentTransfer />
                    },
                    {
                        name: "setreminder",
                        path: "setreminder/:memberId",
                        auth: true,
                        roles: [],
                        element: <SetReminder />
                    },
                    {
                        name: "droppool",
                        path: "droppool/:memberId",
                        auth: true,
                        roles: [],
                        element: <DropPool />
                    },
                    {
                        name: "demo",
                        path: "demo/:memberId",
                        auth: true,
                        roles: [],
                        element: <DemoRegistration />
                    },
                    {
                        name: "registration",
                        path: "registration/:memberId",
                        auth: true,
                        roles: [],
                        element: <Registration />
                    },
                    {
                        name: "hourlydiscount",
                        path: "hourlydiscount/:memberId",
                        auth: true,
                        roles: [],
                        element: <HourlyDiscount />
                    },
                    {
                        name: "payments",
                        path: "payments/:memberId",
                        auth: true,
                        roles: [],
                        element: <Payments />
                    },
                    {
                        name: "currentcard",
                        path: "currentcard/:memberId",
                        auth: true,
                        roles: [],
                        element: <CurrentCards />
                    },
                    {
                        name: "invoices",
                        path: "invoices/:memberId",
                        auth: true,
                        roles: [],
                        element: <Invoices />
                    },
                    {
                        name: "additionalservice",
                        path: "additionalservice/:memberId",
                        auth: true,
                        roles: [],
                        element: <AdditionalService />
                    },
                    {
                        name: "changeterm",
                        path: "changeterm/:memberId",
                        auth: true,
                        roles: [],
                        element: <ChangeTerm />
                    },
                    {
                        name: "lessonrecord",
                        path: "lessonrecord/:memberId",
                        auth: true,
                        roles: [],
                        element: <LessonRecord />
                    },
                    {
                        name: "movebrand",
                        path: "movebrand/:memberId",
                        auth: true,
                        roles: [],
                        element: <MoveBrand />
                    },
                    {
                        name: "callrecords",
                        path: "callrecords/:memberId",
                        auth: true,
                        roles: [],
                        element: <CallRecords />
                    },
                ]
            }
        ]
    },
    {
        name: "sales_pending",
        path: "sales_pending/:base64",
        element: <Pending/>
    },
    {
        name: "employee",
        path: "employee",
        auth: true,
        roles: [2, 3, 5, 8, 18],
        element: <EmployeeLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <Employee />
            },
            {
                name: 'list',
                path: 'list',
                auth: true,
                roles: [2, 3, 5, 8, 18],
                element: <Employee />
            },
            {
                name: 'create',
                path: 'create',
                auth: true,
                roles: [2, 3, 18],
                element: <CreateEmployee />
            },
            {
                name: 'edit',
                path: 'edit/:employeeId',
                auth: true,
                roles: [],
                element: <EditEmployee />
            },
            {
                name: 'detail',
                path: 'detail/:employeeId',
                auth: true,
                roles: [],
                element: <EmployeeDetail />
            }
        ]
    },
    {
        name: "report",
        path: "report",
        auth: true,
        roles: [],
        element: <ReportLayout />,
        children: [
            {
                name: "candidatepoolreport",
                path: "candidatepoolreport",
                auth: true,
                roles: [1,2,3,4,5,6,15,17,18],
                element: <CandidatePoolReport />
            },
            {
                name: "salesmanreport",
                path: "salesmanreport",
                auth: true,
                roles: [1,2,3,5,6,17,18],
                element: <SalesmanReport />
            },
            {
                name: "dailyreport",
                path: "dailyreport",
                auth: true,
                roles: [1,2,3,5,8,11,14,15,17,18],
                element: <DailyReport />
            },
            {
                name: "npsreport",
                path: "npsreport",
                auth: true,
                roles: [],
                element: <NPSReport />
            },
            {
                name: "mentornpsreport",
                path: "mentornpsreport",
                auth: true,
                roles: [],
                element: <MentorNpsReport />
            },
            {
                name: "lessonattendreport",
                path: "lessonattendreport",
                auth: true,
                roles: [],
                element: <LessonAttendReport />
            },
            {
                name: "target",
                path: "target",
                auth: true,
                roles: [],
                element: <TargetFollowAnalysis />
            },
            {
                name: "call",
                path: "call",
                auth: true,
                roles: [],
                element: <CallReport />
            },
            {
                name: "calltracking",
                path: "calltracking",
                auth: true,
                roles: [],
                element: <CallTracking />
            },
            {
                name: "reference",
                path: "reference",
                auth: true,
                roles: [],
                element: <ReferenceDashboard />
            },
            {
                name: "inactive",
                path: "inactive",
                auth: true,
                roles: [],
                element: <InactiveReport />
            },
            {
                name: "market",
                path: "market",
                auth: true,
                roles: [],
                element: <MarketReport />
            },
            {
                name: "demo",
                path: "demo",
                auth: true,
                roles: [],
                element: <DemoDashboard />
            },
            {
                name: "events",
                path: "events",
                auth: true,
                roles: [],
                element: <PanelEvents />
            }
        ]
    },
    {
        name: "productreports",
        path: "productreports",
        auth: true,
        roles: [1,2,3,18],
        element: <ProductReportLayout />,
        children: [
            {
                name: "phonenumbersreport",
                path: "phonenumbersreport",
                auth: true,
                roles: [1,2,3,18],
                element: <PhoneNumbersReport />
            },
            {
                name: "familypackage",
                path: "familypackage",
                auth: true,
                roles: [1,2,3,18],
                element: <FamilyPackageDashboard />
            },
            {
                name: "appcomment",
                path: "appcomment",
                auth: true,
                roles: [1,2,3,18],
                element: <AppCommentDashboard />
            }
        ]
    },
    {
        name: "accounting",
        path: "accounting",
        auth: true,
        roles: [2, 3, 14],
        element: <AccountingLayout />,
        children: [

            {
                index: true,
                auth: true,
                element: <CurrentCard />
            },

            {
                name: "currentcard",
                path: "currentcard",
                auth: true,
                roles: [2, 3, 14],
                element: <CurrentCard />
            },
            {
                name: "invoice",
                path: "invoice",
                auth: true,
                roles: [2, 3, 14],
                element: <Invoice />
            },
            {
                name: "payment",
                path: "payment",
                auth: true,
                roles: [2, 3, 5, 8, 14],
                element: <Payment />
            },
            {
                name: "campaign",
                path: "campaign",
                auth: true,
                roles: [2, 3, 5, 8, 14, 18],
                element: <Campaign />
            },
            {
                name: "addcampaign",
                path: "addcampaign",
                auth: true,
                roles: [2, 3, 5, 8, 14, 18],
                element: <AddCampaign />
            },
            {
                name: "campaigndetail",
                path: "campaigndetail",
                auth: true,
                roles: [2, 3, 5, 8, 14, 18],
                element: <CampaignDetail />
            },
            {
                name: "discount",
                path: "discount",
                auth: true,
                roles: [2, 3, 5, 8, 14, 18],
                element: <DiscountCode />
            },
            {
                name: "discountdetail",
                path: "discountdetail",
                auth: true,
                roles: [2, 3, 5, 8, 14, 18],
                element: <DiscountCodeDetail />
            }
        ]
    },
    {
        name: "teacher",
        path: "teacher",
        auth: true,
        roles: [],
        element: <TeacherLayout />,
        children: [
            {
                name: "program",
                path: "program",
                auth: true,
                roles: [],
                element: <TeacherProgram />
            },
            {
                name: "allprogram",
                path: "allprogram",
                auth: true,
                roles: [],
                element: <AllTeacherProgram />
            }
        ]
    },
    {
        name: "ticket",
        path: "ticket",
        auth: true,
        roles: [],
        element: <TicketLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <Ticket />
            },
            {
                name: 'detail',
                path: 'detail/:ticketId',
                auth: true,
                roles: [],
                element: <TicketDetail />
            }
        ]
    },
    {
        name: "task",
        path: "task",
        auth: true,
        roles: [],
        element: <TaskLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <Task />
            }
        ]
    },
    {
        name: "reminder",
        path: "reminder",
        auth: true,
        roles: [],
        element: <ReminderLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <Reminder />
            }
        ]
    },
    {
        name: "usecase",
        path: "usecase",
        auth: true,
        roles: [],
        element: <UseCaseLayout />,
        children: [
            {
                name: "list",
                path: "list",
                auth: true,
                roles: [],
                element: <UseCaseList />
            },
            {
                name: "create",
                path: "create",
                auth: true,
                roles: [],
                element: <CreateUseCase />
            },
            {
                name: "update",
                path: "update",
                auth: true,
                roles: [],
                element: <UpdateUseCase />
            },
        ]
    },
    {
        name: "history",
        path: "history",
        auth: true,
        roles: [],
        element: <UseCaseHistoryLayout />,
        children: [
            {
                name: "list",
                path: "list",
                auth: true,
                roles: [],
                element: <UseCaseHistoryList />
            },
            {
                name: "detail",
                path: "detail",
                auth: true,
                roles: [],
                element: <UseCaseHistoryDetail />
            }
        ]
    },
    {
        name: "error404",
        path: "*",
        auth: true,
        roles: [],
        element: <ErrorLayout />
    }
]

const authMap = routes => routes.map(route => {
    if (route?.auth) {
        route.element = <AuthRoute>{route.element}</AuthRoute>;
    }
    if (route?.children) {
        route.children = authMap(route.children)
    }
    return route
})

export default authMap(routes);
