import classNames from "classnames";
import tr from "date-fns/locale/tr";
import React, {useEffect, useRef, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import {useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {Accept} from "../../../../../assets/img";
import {
  Dropdown,
  DropdownMultiSelect,
  Input,
  LargeButton,
  Loading,
  Modal,
  PageStep,
  PageTitle,
  showTopAlert
} from "../../../../../components";
import {toggleTicketAccordion} from "../../../../../components/Toggle";
import {
  formatPrice,
  fullDateToShortDate,
  generateOrderNumber,
  getLanguageTypeByLanguageKey,
  handleCheckbox,
  isValidIdentityNumber,
  parseSecurePaymentKeyToJson,
  removeSpaces,
  replaceTurkishChars,
  validateEmail
} from "../../../../../components/Utility";
import {
  Brands,
  CurrentCardType,
  MemberStatus,
  RegistrationType,
  SalesTypeEnum,
  ServiceErrorMessages,
  SourceType,
  TermStatusType
} from "../../../../../consts/Constants";
import useAuth from "../../../../../hooks/Log/useAuth";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import useMemberStatus from "../../../../../hooks/Member/useMemberStatus";
import usePrimeData from "../../../../../hooks/Utils/usePrimeData";
import {
  CreateCurrentCard,
  GetCurrentCardSelectListByMemberId
} from "../../../../../services/Accounting/CurrentCardService";
import {GetBankInfos, GetBankPoses} from "../../../../../services/Bank/BankService";
import {GetHolidayList} from "../../../../../services/Member/MemberHolidayService";
import {GetMemberProfile} from "../../../../../services/Member/MemberProfileService";
import {GetEmailByMemberId, GetMemberBrandId, UpdateMemberEmail} from "../../../../../services/Member/MemberService";
import {
  PayPackageBy3DCreditCard,
  PayPackageByCreditCard,
  PayPackageByTransfer
} from "../../../../../services/Payment/PaymentService";
import {
  CalculatePriceByDiscountRate,
  GetCampaignTypeDetailsForSales,
  GetDiscountsByMemberId,
  GetProductCategorySelectList,
  GetSalesProductDetails,
  GetSalesProducts
} from "../../../../../services/Product/ProductService";
import {AddMemberTerm, GetTermEndDateAndDay} from "../../../../../services/Term/TermService";
import {Accordion, CreditCard, PriceShape} from "../../../components";
import './../../../../../assets/css/payment-card.css';
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";
import Installment from "../Common/Installment";
import useMemberName from "../../../../../hooks/Member/useMemberName";

const Registration = () => {

  registerLocale("tr", tr);
  const strings = useLocalization();
  const dataLayer = useDataLayer();
  const language = useSelector(state => state.localizationStore.language);
  const location = useLocation();
  const primeData = usePrimeData();
  const { memberId } = useParams() || false;
  const memberStatus = useMemberStatus(memberId) || false;
  const memberName = useMemberName(memberId) || "";
  const registrationType = location.state?.registrationType;
  const termId = location.state?.termId || false;

  const studentNo = location.state?.studentNo || null;
  const { employee } = useAuth();

  const securePaymentIframeLinks = {
    "Ziraat Bankası": "https://phpapi.clickivo.com/_virtual-pos-api/ziraat-panel/verify.php",        
    "Kuveyt Türk": "https://phpapi.clickivo.com/_virtual-pos-api/kuveytturk-panel/verify.php",
    "Akbank": "https://phpapi.clickivo.com/_virtual-pos-api/akbank/verify.php"
  };

  //registrationType kullanan yerler
  /*
  
        "FirstSale": 1,
        "ExtraPackage": 2,
        "Extension": 3,
        "TryAgain": 4,

  */

  const firstVisit = useRef(true);

  //Sayfa içerisindeki adımları kontrol eder. 
  const [step, setStep] = useState(1);
  const [title, setTitle] = useState("");
  const [isOperationsCompleted, setIsOperationsCompleted] = useState(false);


  useEffect(() => {

    switch (registrationType) {
      case 1:
        let tmpFirst = strings.member.member_detail.sales_operations.registiration_title + ' / ' + strings.member.member_detail.header.content.first_registration;
        setTitle(tmpFirst);
        break;
      case 2:
        let tmpSecond = strings.member.member_detail.sales_operations.registiration_title + ' / ' + strings.member.member_detail.header.content.second_registration;
        setTitle(tmpSecond);
        break;
      case 3:
        let tmpExtend = strings.member.member_detail.sales_operations.registiration_title + ' / ' + strings.member.member_detail.terms.extend;
        setTitle(tmpExtend);
        break;
      case 4:
        let tmpAgain = strings.member.member_detail.sales_operations.registiration_title + ' / ' + strings.member.member_detail.header.content.again;
        setTitle(tmpAgain);
        break;
      default:
        let tmpDefault = strings.member.member_detail.sales_operations.registiration_title
        setTitle(tmpDefault);
    }
  }, [registrationType])

  useEffect(() => {
    //Sayfa yüklendikten sonra false yaparak her değişiklikte
    //servisin ziyaret edilmesini sağlıyoruz
    firstVisit.current = false;
  }, [])

  //Step 1 Başlangıç
  const accordionRef = useRef();
  const minusSignRef = useRef();
  const plusSignRef = useRef();
  const topRef = useRef();

  const firstProductCategory = { id: -1, name: strings.member.member_detail.inform_operations.choose_education_package };
  const firstMonth = { id: -1, name: strings.member.member_detail.inform_operations.choose_education_time };
  const firstDayOfWeek = { id: -1, name: strings.member.member_detail.inform_operations.choose_weekly_lesson_count };
  const firstMinute = { id: -1, name: strings.member.member_detail.sales_operations.choose_daily_lesson_time };
  const firstCampaign = { id: -1, name: strings.member.member_detail.sales_operations.choose_special_campaign };

  const [isClick, setIsClick] = useState(false);
  const [brandId, setBrandId] = useState(0);

  const [bankPosList, setBankPosList] = useState([]);
  const [bankInfoList, setBankInfoList] = useState([]);

  const [productCategory, setProductCategory] = useState(firstProductCategory);
  const [productCategoryList, setProductCategoryList] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductPrices, setSelectedProductPrices] = useState({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
  const [productLessonCount, setProductLessonCount] = useState(0);
  const [productGiftLessonCount, setProductGiftLessonCount] = useState(0);

  const [month, setMonth] = useState(firstMonth);
  const [monthList, setMonthList] = useState([]);

  const [dayOfWeek, setDayOfWeek] = useState(firstDayOfWeek);
  const [dayOfWeekList, setDayOfWeekList] = useState([]);

  const [minute, setMinute] = useState(firstMinute);
  const [minuteList, setMinuteList] = useState([]);
  const [tempMinuteList, setTempMinuteList] = useState([]);

  const [campaign, setCampaign] = useState(firstCampaign);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignListWithDetail, setCampaignListWithDetail] = useState([]);
  const [discountDiscountRate, setDiscountDiscountRate] = useState(0);
  const [campaignDiscountRate, setCampaignDiscountRate] = useState(0);

  const [discount, setDiscount] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [discountListWithDetail, setDiscountListWithDetail] = useState([]);

  const [baseDiscountedPrice, setBaseDiscountedPrice] = useState(0);

  //Ödeme işlemi onaylıysa gelen orderID yi saklıyoruz
  const [orderId, setOrderId] = useState(null);

  //Havale ile ödeme için loading butonu
  const [payPackageByTransferLoading, setPayPackageByTransferLoading] = useState(false);
  //Kredi Kartı ile ödeme için loading butonu
  const [payPackageByCreditCardLoading, setPayPackageByCreditCardLoading] = useState(false);
  const [waitCreateTerm, setWaitCreateTerm] = useState(false);

  //Kullanıcının brandId'sini getirir.
  const getMemberBrandId = async (memberId) => {
    const result = await GetMemberBrandId(memberId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setBrandId(brandId => parseInt(resultContent));
    }
  }

  const getProductCategoryList = async (language) => {

    let arrayList = [];

    const result = await GetProductCategorySelectList(language);

    if (result.status === 200) {

      let data = JSON.parse(result.content);

      data.map((item) => {
        // Listeden global taraf için kampüs çocuk çıkartılıyor.
        if(+brandId !== Brands.KO && +item.value === 3) return;

        let arrayItem = { id: parseInt(item.value), name: item.text };
        arrayList.push(arrayItem);
      })

      const opportunityEnglishPackageId = 10;
      if(MemberStatus.Candidate !== memberStatus)
        arrayList = arrayList.filter(d => d.id !== opportunityEnglishPackageId);
    }

    setProductCategoryList(productCategoryList => arrayList);
  }

  //Banka pos listesi getiriliyor
  const getBankPosList = async () => {
    const result = await GetBankPoses();
    if (result.status === 200) {
      setBankPosList(JSON.parse(result.content));
    }
  }

  //Marka listesi getiriliyor
  const getBankInfoList = async () => {
    const result = await GetBankInfos();

    if (result.status === 200) {
      setBankInfoList(JSON.parse(result.content));
    }
  }

  //Seçilen pakete göre dakika ve ders süresi bilgileri getiriliyor
  const getSalesProducts = async (productCategoryId, brandId, language) => {

    let tmpProductMonths = [];
    let tmpProductDays = [];
    let tmpProductMinutes = [];

    const result = await GetSalesProducts(productCategoryId, brandId, language);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      resultContent?.productMonthList.map((item) => {
        tmpProductMonths.push({ id: +item.value, name: item.text });
      })

      resultContent?.productDayList.map((item) => {
        tmpProductDays.push({ id: +item.value, name: item.text });
      })

      resultContent?.productMinuteList.map((item) => {
        tmpProductMinutes.push({ id: +item.value, name: item.text });
      })

      setMonthList([firstMonth, ...tmpProductMonths]);
      setDayOfWeekList([firstDayOfWeek, ...tmpProductDays]);
      setMinuteList([firstMinute, ...tmpProductMinutes]);
      setTempMinuteList([...tmpProductMinutes]);

    }
  }

  //Seçilen ürüne ait kampanya Varsa kampanyaları getir
  const getCampaignTypeDetailsForSales = async (productId, salesTypeId) => {
    /*let model = {
      productId: productId,
      memberStatus: memberStatus
    }*/
    const result = await GetCampaignTypeDetailsForSales(productId, salesTypeId);
    let arrayList = [];
    let arrayListWithDetail = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate };
        arrayList.push(arrayItem);
        arrayListWithDetail.push(item);
      })
    }

    setCampaignList([firstCampaign, ...arrayList]);
    setCampaignListWithDetail(arrayListWithDetail);
  }

  //Kampanya seçildiğinde fiyatın güncellenmesi gerekiytor.
  const calculatePriceByDiscountRate = async (model) => {
    const result = await CalculatePriceByDiscountRate(model);
    if (result.status === 200) {
      const data = JSON.parse(result.content);
      let prices = {
        totalPrice: data?.totalPrice,
        discountPrice: data?.discountPrice,
        taxPrice: data?.taxPrice,
        totalPriceWithoutFormat: data?.totalPriceWithoutFormat,
        discountPriceWithoutFormat: data?.discountPriceWithoutFormat,
        taxPriceWithoutFormat: data?.taxPriceWithoutFormat,
        turkishLiraPrice: data?.turkishLiraPrice,
        turkishLiraPriceWithoutFormat: data?.turkishLiraPriceWithoutFormat,
      };
      setSelectedProductPrices(prices);
    }
  }

  useEffect(() => {

    const handleBeforeUnload = (e) => {
      try {
        if (/www\/(signin|signout)/i.test(window.location.href)) {
          return;
        } else if(!isOperationsCompleted) {
          e.preventDefault();
          e.returnValue = 'reload / leave site?';
        }
      } catch (error) {
        console.error(error);
      }
    };

    if(step === 2 || step === 3 || step === 4) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [step, isOperationsCompleted]);

  /* ödeme ve dönem işlemleri */
  /* ödeme ve dönem işlemleri */
  /* ödeme ve dönem işlemleri */

  useEffect(() => {
    if (discount) {
      //İndirimler seçildiğinde bir indirim tipinden yalnızca 1 tane eklenebilmesi için
      //aşağıdaki kodu kullanıyoruz
      let discountArray = [];
      let totalDiscount = 0;

      discountListWithDetail.map(dsl => {
        discount.map(ds => {
          if (dsl.campaignId === ds) {
            if (dsl.campaignType !== 2) { // Eğer kampanya tipi 2 değilse bu bloğu çalıştır
              let findCampaignType = discountArray.find(dsa => dsa.campaignType === dsl.campaignType);
              if (typeof findCampaignType === "undefined") {
                discountArray.push(dsl);
                totalDiscount = totalDiscount + dsl.discountRate;
              } else {
                showTopAlert(strings.errors.same_campaign_type_error, "warning");
                setDiscount([]);
                totalDiscount = 0;
              }
            } else {
              discountArray.push(dsl);
              totalDiscount = totalDiscount + dsl.discountRate;
            }
          }
        })
      })


      //Toplam indirim oranına buradan seçilen indirimlerin oranlarını ekliyoruz
      setDiscountDiscountRate(discountDiscountRate => totalDiscount);
      let modelDiscountRate = (totalDiscount === 0 && campaignDiscountRate === 0) ? selectedProduct.priceDetail?.discountRate : totalDiscount + campaignDiscountRate;
      if(brandId !== 0) {
        let model = {
          price: (totalDiscount === 0 && campaignDiscountRate === 0) ? selectedProduct?.priceDetail?.priceWithoutFormat : baseDiscountedPrice,
          discountRate: modelDiscountRate > 100 ? 100 : modelDiscountRate,
          brandId: brandId
        };
        calculatePriceByDiscountRate(model);
      }

    }
  }, [discount, selectedProduct, baseDiscountedPrice, brandId])

  useEffect(() => {
    //Kampanya değişirse seçili olan indirimleri sıfırlamamız gerekiyor
    setDiscount([]);
    if (campaign.id !== -1 && brandId !== 0) {
      if (Object.keys(campaignListWithDetail).length > 0) {
        let selectedCampaign = campaignListWithDetail.find(c => c.campaignId === campaign.id);
        if (typeof selectedCampaign !== "undefined") {
          setCampaignDiscountRate(selectedCampaign.discountRate);
          let modelDiscountRate = (selectedCampaign.discountRate === 0 && discountDiscountRate === 0) ? selectedProduct.priceDetail?.discountRate : selectedCampaign.discountRate + discountDiscountRate;
          let model = {
            price: (selectedCampaign.discountRate === 0 && discountDiscountRate === 0) ? selectedProduct?.priceDetail?.priceWithoutFormat : baseDiscountedPrice,
            discountRate: modelDiscountRate > 100 ? 100 : modelDiscountRate,
            brandId: brandId
          };
          calculatePriceByDiscountRate(model);

          //Özel indirim alanı yeniden seçiliyor
          let model2 = {
            memberId: memberId,
            moneyboxDiscountLimit: modelDiscountRate > 0 ? 100 : 100 - modelDiscountRate,
            productId: selectedProduct.productId,
            selectedCampaignId: campaign.id,
            salesTypeId: registrationType
          };
          getDiscountsByMemberId(model2);
        }
      }
    } else {
      setSelectedProductPrices({ totalPrice: selectedProduct?.priceDetail?.totalPrice, discountPrice: selectedProduct?.priceDetail?.discountPrice, taxPrice: selectedProduct?.priceDetail?.taxPrice, totalPriceWithoutFormat: selectedProduct?.priceDetail?.totalPriceWithoutFormat, discountPriceWithoutFormat: selectedProduct?.priceDetail?.discountPriceWithoutFormat, taxPriceWithoutFormat: selectedProduct?.priceDetail?.taxPriceWithoutFormat });
      setCampaignDiscountRate(0);
      setDiscountDiscountRate(0);
    }
  }, [campaign, registrationType, baseDiscountedPrice, brandId])


  const [campaignState, setCampaignState] = useState(false);
  const [discountState, setDiscountState] = useState(false);

  useEffect(() => {
    if (discount.length > 0) {
      setCampaignState(true);
    }
  }, [campaign.id]);

  useEffect(() => {
    if (discount.length > 0) {
      setDiscountState(true);
    } else if (discount.length === 0) {
      setDiscountState(false);
    }
  }, [discount.length])

  useEffect(() => {

    if (Object.keys(selectedProduct).length > 0 && campaignState && discountState) {
      if (campaign.id !== -1)
        showTopAlert(strings.errors.discount_reseted, "warning");
      setCampaignState(false);
      setDiscountState(false);
    }
  }, [campaignState, selectedProduct, discountState]);

  useEffect(() => {
    if (Object.keys(selectedProduct).length > 0 && campaign.id === -1)
      showTopAlert(strings.errors.total_discount_reseted, "warning");
  }, [campaign.id]);

  //Seçilen ürüne ait indirim varsa indirimleri getir
  const getDiscountsByMemberId = async (model) => {
    const result = await GetDiscountsByMemberId(model);
    let arrayList = [];
    let arrayListWithDetail = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate };
        arrayList.push(arrayItem);
        arrayListWithDetail.push(item);
      })
    }

    setDiscountList([...arrayList]);
    setDiscountListWithDetail(arrayListWithDetail);
  }

  //Seçili ürün değişince ürüne özel kampanya ve indirimleri getiriyoruz
  useEffect(() => {
    if (Object.keys(selectedProduct).length > 0) {
      getCampaignTypeDetailsForSales(selectedProduct.productId, registrationType);
      let model = {
        memberId: memberId,
        moneyboxDiscountLimit: 100 - selectedProduct.priceDetail?.discountRate,
        productId: selectedProduct.productId,
        selectedCampaignId: campaign.id === -1 ? null : campaign.id,
        salesTypeId: registrationType
      };
      getDiscountsByMemberId(model);
    }
  }, [selectedProduct, registrationType, campaign.id])

  useEffect(() => {
    if (memberId) {
      getMemberBrandId(memberId);
      getBankPosList();
      getBankInfoList();
    }
  }, [])

  useEffect(() => {
    if (language && brandId !== 0) {
      getProductCategoryList(language);
    }
  }, [language, brandId])

  //Paket seçme kısmı değiştiğinde süre kısımları sıfırlanır.
  useEffect(() => {
    //Ürün kategorisi değiştiğinde tüm dakika, gün ve ay bilgilerini sıfırlıyoruz
    setMonth(firstMonth);
    setDayOfWeek(firstDayOfWeek);
    setMinute(firstMinute);
    setMonthList([firstMonth]);
    setDayOfWeekList([firstDayOfWeek]);
    setMinuteList([firstMinute]);
    setCampaignList([firstCampaign]);
    setCampaign(firstCampaign);
    setDiscountList([]);
    setDiscount([]);
    setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
    setDiscountDiscountRate(0);
    setCampaignDiscountRate(0);
    if (productCategory.id !== -1) {
      getSalesProducts(productCategory.id, brandId, language)
    }
  }, [productCategory, language])

  //Dil değiştiğinde seçili ürünü sıfırlıyoruz

  useEffect(() => {
    setProductCategory(firstProductCategory);
  }, [language])

  //Eğer ay değişirse aydan sonra gelen veriler sıfırlanıyor
  useEffect(() => {

    setCampaign(firstCampaign);
    setCampaignList([firstCampaign]);
    setDiscount([]);
    setDiscountList([]);
    setCampaignDiscountRate(0);
    setDiscountDiscountRate(0);

    if (month.id === -1) {
      setDayOfWeek(firstDayOfWeek);
      setMinute(firstMinute);
      setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
    }

  }, [month])

  //2 ve 3 günlük paketlerde 40 ve üzeri dakikalarda ders olmadığı için üst değerler alınmıyor
  useEffect(() => {
    setCampaign(firstCampaign);
    setCampaignList([firstCampaign]);
    setDiscount([]);
    setDiscountList([]);
    setCampaignDiscountRate(0);
    setDiscountDiscountRate(0);

    if (dayOfWeek.id === 2 || dayOfWeek.id === 3) {
      let tmpMinute = brandId === 1 ? tempMinuteList.filter(m => m.id < 50) : tempMinuteList;
      if (minute.id === 50 && brandId === 1) {
        setMinute(firstMinute);
      }
      setMinuteList([firstMinute, ...tmpMinute]);
    } else {
      setMinuteList([firstMinute, ...tempMinuteList]);
    }

    if (dayOfWeek.id === -1) {
      setMinute(firstMinute);
      setMinuteList([firstMinute]);
      setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
    }
  }, [dayOfWeek])

  useEffect(() => {
    setCampaign(firstCampaign);
    setCampaignList([firstCampaign]);
    setDiscount([]);
    setDiscountList([]);
    setCampaignDiscountRate(0);
    setDiscountDiscountRate(0);
    setSelectedProductPrices({ totalPrice: "0.00", discountPrice: "0.00", taxPrice: "0.00", totalPriceWithoutFormat: 0, discountPriceWithoutFormat: 0, taxPriceWithoutFormat: 0 });
  }, [minute])

  //Eğer tüm seçimler yapıldıysa paket ayrıntılarını getir

  const getSalesProductDetails = async (model) => {
    const result = await GetSalesProductDetails(model);
    if (result.status === 200) {
      const data = JSON.parse(result.content);
      let prices = { totalPrice: data?.priceDetail?.totalPrice, discountPrice: data?.priceDetail?.discountPrice, taxPrice: data?.priceDetail?.taxPrice, totalPriceWithoutFormat: data?.priceDetail?.totalPriceWithoutFormat, discountPriceWithoutFormat: data?.priceDetail?.discountPriceWithoutFormat, taxPriceWithoutFormat: data?.priceDetail?.taxPriceWithoutFormat };
      setSelectedProductPrices(prices);
      setSelectedProduct(data);
      setBaseDiscountedPrice(baseDiscountedPrice => data?.priceDetail?.discountPriceWithoutFormat)
    }
  }


  useEffect(() => {
    if (productCategory.id !== -1 && month.id !== -1 && minute.id !== -1 && dayOfWeek.id !== -1) {

      let model = {
        brandId: brandId,
        memberStatus: memberStatus,
        categoryId: productCategory.id,
        productDayCount: dayOfWeek.id,
        productMonthId: month.id,
        productMinute: minute.id
      }

      getSalesProductDetails(model);
    } else {
      setSelectedProduct({});
    }
  }, [month, minute, dayOfWeek])

  useEffect(() => {
    if(Object.keys(selectedProduct).length > 0) {
      setProductLessonCount(productLessonCount =>
          selectedProduct?.priceDetail?.totalLessonCount - selectedProduct?.priceDetail?.extraGiftLessonCount
      );

      setProductGiftLessonCount(productGiftLessonCount => selectedProduct?.priceDetail?.extraGiftLessonCount);
    }
  }, [selectedProduct])

  //Step 1 Son

  //Step 2 Başlangıç

  const hiddenInputRef = useRef();

  //Sayfa içerisindeki tab'ları kontrol eder
  //0 Kredi Kartı
  //1 Havale
  //2 Alışveriş Kredisi
  const [tab, setTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [cardholderName, setCardholderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [bank, setBank] = useState(null);
  const [bankName, setBankName] = useState("");
  const [isForeignCard, setIsForeignCard] = useState(false);
  const [isOneShot, setIsOneShot] = useState(false);
  const [isRecurringPayment, setIsRecurringPayment] = useState(false);
  const [paymentOption, setPaymentOption] = useState(0);
  const [isCompletePayment, setIsCompletePayment] = useState(false);

  /* 3D Ödeme İşlemleri İçin Stateler */

  // 3D modalı açıkken arkada çalışan counter state'i sürekli gizli input'un kontrol edilmesini tutan state
  const [counter, setCounter] = useState(0);
  // 3D ödeme bilgilerinin olduğu base64 stringi tutan state
  const [securePaymentResult, setSecurePaymentResult] = useState("");
  // 3D ödeme order numarasının tutulduğu state
  const [securePaymentOrderNumber, setSecurePaymentOrderNumber] = useState("");
  // 3D ödeme linkini tutan state
  const [securePaymentBase64Content, setSecurePaymentBase64Content] = useState("");
  // 3d ödemeler için kullanılan, 3d servislerinden cevap geldiğinde tetiklenen loading
  const [securePaymentLoading, setSecurePaymentLoading] = useState(false);

  /* ödeme ve dönem işlemleri */
  /* ödeme ve dönem işlemleri */
  /* ödeme ve dönem işlemleri */

  //Banka havalesi ya da EFT ile yapılan ödemelerde servise giden fonksiyon
  const payPackageByTransfer = async () => {

    //Kampanya listesini dolduracağız
    let campaignIdList = [];

    if (campaign.id !== -1) {
      campaignIdList.push(campaign.id)
    }

    if (discount.length > 0) {
      discount.map(ds => campaignIdList.push(ds));
    }
    //Kampanya listesini dolduracağız

    setPayPackageByTransferLoading(payPackageByTransferLoading => true);

    let model = {
      brandId: brandId,
      memberId: memberId,
      salesType: registrationType,
      productId: selectedProduct?.productId,
      price: selectedProduct?.priceDetail?.priceWithoutFormat,
      discountPrice: selectedProductPrices.discountPriceWithoutFormat,
      discountRate: discountDiscountRate + campaignDiscountRate > 100 ? 100 : discountDiscountRate + campaignDiscountRate,
      posType: 1,
      createUser: employee.EmployeeId,
      taxValue: selectedProduct?.priceDetail?.taxRate,
      sourceType: SourceType.Crm,
      taxPrice: selectedProductPrices.taxPriceWithoutFormat,
      totalPrice: selectedProductPrices.totalPriceWithoutFormat,
      campaignIdList: campaignIdList,
      bankInfoId: bank,
    }

    const result = await PayPackageByTransfer(model);

    if (result.status === 200) {
      setOrderId(orderId => JSON.parse(result.content))
      showTopAlert(strings.member.member_detail.sales_operations.order_success, "success");
      //Ödemeyi Tamamla butonuna tıklandıktan sonra butonun disabled olması (herhangi bir bug'dan dolayı butona iki defa tıklanıp servise 2 defa istek atmasını önlemek) için kullanıldı
      setIsCompletePayment(true)
      setStep(3);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
    setPayPackageByTransferLoading(payPackageByTransferLoading => false);
  }

  const createPayPackageModel = () => {
    //Kampanya listesini dolduracağız
    let campaignIdList = [];

    if (campaign.id !== -1) {
      campaignIdList.push(campaign.id)
    }

    if (discount.length > 0) {
      discount.map(ds => campaignIdList.push(ds));
    }

    return {
      brandId: brandId,
      memberId: memberId,
      salesType: registrationType,
      productId: selectedProduct?.productId,
      price: selectedProduct?.priceDetail?.priceWithoutFormat,
      discountPrice: selectedProductPrices.discountPriceWithoutFormat,
      discountRate: discountDiscountRate + campaignDiscountRate > 100 ? 100 : discountDiscountRate + campaignDiscountRate,
      posType: 1,
      bankPosId: bank,
      createUser: employee.EmployeeId,
      installment: paymentOption,
      taxValue: selectedProduct?.priceDetail?.taxRate,
      sourceType: SourceType.Crm,
      taxPrice: selectedProductPrices.taxPriceWithoutFormat,
      totalPrice: selectedProductPrices.totalPriceWithoutFormat,
      campaignIdList: campaignIdList,
      isRecurringPayment: isRecurringPayment,
      isForeignCard: isForeignCard
    }
  }

  const createCreditCardModel = () => {
    return {
      month: cardExpiration.split("/")[0],
      year: cardExpiration.split("/")[1],
      number: cardNumber,
      name: cardholderName,
      cvc: cardCvv,
      cardType: "credit",
      isSecurity: false
    };
  }

  //Banka havalesi ya da EFT ile yapılan ödemelerde servise giden fonksiyon
  const payPackageByCreditCard = async () => {

    setPayPackageByCreditCardLoading(true);

    let model = createPayPackageModel();
    model.creditCardDetail = createCreditCardModel();

    const result = await PayPackageByCreditCard(model);

    if (result.status === 200) {
      setOrderId(orderId => JSON.parse(result.content))
      showTopAlert(strings.member.member_detail.sales_operations.order_success, "success");
      //Ödemeyi Tamamla butonuna tıklandıktan sonra butonun disabled olması (herhangi bir bug'dan dolayı butona iki defa tıklanıp servise 2 defa istek atmasını önlemek) için kullanıldı
      setIsCompletePayment(true)
      setStep(3);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
    setPayPackageByCreditCardLoading(false);
  }

  const payPackageWith3d = async (paymentResult) => {

    setPayPackageByCreditCardLoading(true);

    const model = createPayPackageModel();
    model.orderNumber = securePaymentOrderNumber;
    model.virtualPosResultModel = {
      status: paymentResult?.Response === "Approved",
      statusMessage: paymentResult?.Response === "Approved" ? "" : paymentResult?.ErrorMessage,
      authCode: paymentResult?.Response === "Approved" ? paymentResult?.AuthCode : null,
      bankErrorCode: null
    };

    const result = await PayPackageBy3DCreditCard(model);
    const createdOrderId = JSON.parse(result.content);
    /*
      * Ödeme başarıyla alınır ve order kaydederken problem yaşanırsa bir problem yaşanırsa kullanıcıya bilgi veriliyor.
    */
    if(result.status !== 200 && paymentResult?.Response === "Approved"){
      showTopAlert(strings.member.member_detail.sales_operations.order_failed, "error");
      setPayPackageByCreditCardLoading(false);
      return;
    }

    if (model.virtualPosResultModel.status) {
      setOrderId(createdOrderId);
      showTopAlert(strings.member.member_detail.sales_operations.order_success, "success");
      //Ödemeyi Tamamla butonuna tıklandıktan sonra butonun disabled olması (herhangi bir bug'dan dolayı butona iki defa tıklanıp servise 2 defa istek atmasını önlemek) için kullanıldı
      setIsCompletePayment(true)
      setStep(3);
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + paymentResult?.ErrorMessage, "error");
    }

    setPayPackageByCreditCardLoading(false);
  }

  const handlePayPackage = () => {
    const securePaymentBankLinks = Object.keys(securePaymentIframeLinks);
    if(securePaymentBankLinks.includes(paymentOption < 2 ? "Ziraat Bankası" : bankName)) {
      // Order Id oluşturuluyor ve bir state'e kaydediliyor
      const tempMemberName = memberName !== ""
          ? memberName
          : cardholderName;
      const tempOrderNumber = generateOrderNumber(tempMemberName);
      setSecurePaymentOrderNumber(tempOrderNumber);

      const currentYear = new Date().getFullYear();
      const firstTwoDigitsOfYear = currentYear.toString().substring(0, 2);

      //Ödeme linki için base64 content oluşturuluyor
      const tempBase64Object = {
        amount: selectedProductPrices.totalPriceWithoutFormat,
        installment: paymentOption,
        cardNumber: cardNumber,
        cardHolderName: replaceTurkishChars(tempMemberName),
        cardMonth: cardExpiration.split("/")[0],
        cardYear: firstTwoDigitsOfYear + cardExpiration.split("/")[1],
        cardCvc: cardCvv,
        orderId: tempOrderNumber,
        source: "panel"
      };

      const tempBase64Content = `Amount=${tempBase64Object.amount}&Installment=${tempBase64Object.installment}&CardHolderName=${tempBase64Object.cardHolderName}&CardNumber=${tempBase64Object.cardNumber}&Year=${tempBase64Object.cardYear}&Month=${tempBase64Object.cardMonth}&Cvv=${tempBase64Object.cardCvc}&Oid=${tempBase64Object.orderId}&Source=${tempBase64Object.source}`;
      setSecurePaymentBase64Content(btoa(tempBase64Content));

      setModal(true);
      setModalContent({element: "3dModal"});
    } else {
      payPackageByCreditCard();
    }
  }

  //3D modalı açıldığında counter çalışmaya başlar.
  useEffect(() => {
    if(modal && Object.keys(modalContent).length > 0) {
      const interval = setInterval(() => {
        setCounter(prevCounter => prevCounter + 1);
      }, 500);

      return () => clearInterval(interval);
    }
  }, [modal, modalContent]);

  // Counter'a göre gizli input kontrol edilir
  useEffect(() => {
    if(hiddenInputRef?.current?.value?.length > 0) {
      // Modal kapatılır
      setModal(false);
      setModalContent({});

      // Gizli input bir state'e atılıyor ve boşaltılıyor.
      setSecurePaymentResult(hiddenInputRef.current.value);
      hiddenInputRef.current.value = "";

      // Sayfa loading'e sokuluyor
      setSecurePaymentLoading(true);

      // Sayfa yukarı scroll ettiriliyor
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [counter]);

  useEffect(() => {
    if(securePaymentLoading && securePaymentResult.length > 0) {
      const decodedSecurePaymentResult = parseSecurePaymentKeyToJson(securePaymentResult);
      //Order numarası oluşturma işlemi bittikten sonra ödeme 3d order oluşumu için servise gidiliyor
      payPackageWith3d(decodedSecurePaymentResult)
          .then()
          .catch();
    }
  }, [securePaymentLoading, securePaymentResult]);

  const sendEventByRegistrationType = () => {
    switch (registrationType){
      case SalesTypeEnum.FirstSale:
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.FIRST_SALE_COMPLETED);
        break;
      case SalesTypeEnum.ExtraPackage:
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.SECOND_REGISTRATION_COMPLETED);
        break;
      case SalesTypeEnum.Extension:
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.EXTEND_PACKAGE_COMPLETED);
        break;
      case SalesTypeEnum.TryAgain:
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.TRY_AGAIN_PACKAGE_COMPLETED);
        break;
    }
  }

  const addMemberTerm = async () => {
    setWaitCreateTerm(waitCreateTerm => true);

    let model = {
      studentNo: registrationType === RegistrationType.Extension ? studentNo : null,
      memberId: memberId,
      createUserId: employee.EmployeeId,
      salesType: registrationType,
      // Kullanıcı uzatma sayfasından işlem yapıyorsa dönem durumu default olarak 3 gönderiliyor.
      // Bu sayede kredi karti ile ödeme yaptığında dönemin gelecek oluşması sağlanıyor.
      // Havale ile ödeme yapıldığında ise arka planda bu kontrol edilip pasif dönem olarak açılıyor(onaylanması için)
      status: SalesTypeEnum.Extension === registrationType ? TermStatusType.Future : TermStatusType.Active,
      memberStatus: memberStatus,
      beginDate: fullDateToShortDate(startDate),
      orderId: orderId,
      productOptions: {
        monthId: month.id,
        minute: minute.id,
        dayCount: dayOfWeek.id,
        categoryId: productCategory.id
      }
    }

    const result = await AddMemberTerm(model);

    if (result.status === 200) {
      showTopAlert(strings.member.member_detail.sales_operations.add_term_success, "success");

      //DATA LAYER LOGGED
      sendEventByRegistrationType();

      setStep(4);
    } else {

      if (result.content === ServiceErrorMessages.EXTEND_PACKAGE_INVALID_TIME) {
        showTopAlert(strings.errors.extend_package_invalid_time_error, "error");
      } else {
        showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
      }

      setWaitCreateTerm(waitCreateTerm => false);
    }
  }

  const handleCreateTermWithUpdateEmail = async () => {
    let valid = true;

    memberEmailErrorRef.current.innerText = '';

    if(memberEmail.length === 0) {
      valid = false;
      memberEmailErrorRef.current.innerText = strings.errors.email_null_error;
    }
    if(memberEmail.length !== 0 && !validateEmail(memberEmail)) {
      valid = false;
      memberEmailErrorRef.current.innerText = strings.errors.email_format_error;
    }

    if(valid) {
      setWaitCreateTerm(waitCreateTerm => true);
      const result = await UpdateMemberEmail(memberId, memberEmail);

      if(result.status === 200) {
        addMemberTerm();
      } else {
        setWaitCreateTerm(waitCreateTerm => false);
        showTopAlert(strings.errors.an_error_occurred, "error");
        console.error("email error");
        setWaitCreateTerm(waitCreateTerm => false);
      }
    }
  }

  //Tekrarlı ödeme seçildiğinde otomatik olarak taksit seçeneğini 1 olarak belirler
  useEffect(() => {
    if (isRecurringPayment) {
      setPaymentOption(1);
    }
  }, [isRecurringPayment])

  //Tab değiştiğinde seçili bankayı ve taksit sayısını sıfırlıyor
  useEffect(() => {
    if (firstVisit.current === false) {
      setBank(null);
      if (tab !== 0) {
        setPaymentOption(0);
      }
      if (tab === 0) {
        setPaymentOption(0);
      }
    }
  }, [tab])
  //Step 2 Son

  //Step 3 Başlangıç

  const memberEmailErrorRef = useRef();

  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [holidays, setHolidays] = useState({});
  const [memberEmail, setMemberEmail] = useState("");
  const [baseMemberEmail, setBaseMemberEmail] = useState("");
  const [activeTermEndDate, setActiveTermEndDate] = useState(null);

  const getTermEndDateAndDay = async (termId) => {

    const result = await GetTermEndDateAndDay(termId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      let tmpDate = new Date(resultContent.date);

      let newDate = new Date(tmpDate);
      newDate.setDate(tmpDate.getDate() + 1);
      newDate.setHours(23, 59, 59, 999);

      setActiveTermEndDate(activeTermEndDate => newDate);
    }
  }

  const getMemberEmail = async (memberId) => {

    const result = await GetEmailByMemberId(memberId);

    if(result.status === 200) {

      if(result.content === "Belirtilmedi") {
        setMemberEmail(memberEmail => "");
        setBaseMemberEmail(baseMemberEmail => "");
      } else {
        let resultContent = removeSpaces(result.content);
        setMemberEmail(memberEmail => resultContent);
        setBaseMemberEmail(baseMemberEmail => resultContent);
      }
    }
  }

  const getHolidayList = async () => {
    const result = await GetHolidayList()

    if (result.status === 200) {
      const resultContent = JSON.parse(result.content);
      setHolidays(holidays => resultContent)
    }
  }

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const isPassedTwoMonth = (date) => {
    let tmpDate = registrationType === SalesTypeEnum.Extension ? activeTermEndDate : new Date();
    const dayDifference = date.getTime() / (1000 * 60 * 60 * 24) - tmpDate.getTime() / (1000 * 60 * 60 * 24);
    const targetDateDifference = +process.env.REACT_APP_UPDATE_TERM_START_DATE_MAX_DIFFERENCE;

    //Eğer hedef tarihten daha ileri bir tarih olursa false değeri dönmesi sağlanır
    return dayDifference > targetDateDifference;
  };

  const isFriday = (date) => {
    const day = date.getDay();
    return day === 5;
  };

  const isHoliday = (date) => {
    if (Object.keys(holidays).length > 0) {
      return holidays.some((holiday) => new Date(holiday.date).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0,0 ,0));
    }
  };

  const isNoLessonDay = (date, dayOfWeek) => {
    const day = date.getDay();

    // Eğer haftada 5 günlük bir paket seçilirse sadece haftasonları listelenecektir. 
    //Onu da zaten isHoli,day'de filtreliyoruz

    // Eğer haftada 3 günlük bir paket seçilirse Salı ve Perşmebe günleri filtrelenir.
    if (dayOfWeek.id === 3) {
      return day === 2 || day === 4;
    }

    // Eğer haftada 2 günlük bir paket seçilirse Pazartesi, Çarşamba ve Cuma günleri filtrelenir
    if (dayOfWeek.id === 2) {
      return day === 1 || day === 3 || day === 5;
    }

    // Yukarıdaki koşulların hiçbiri uymuyorsa, herhangi bir tarihi kabul etmeyin
    return false;
  };

  const isDateActive = (date) => {
    return !isWeekend(date) && !isHoliday(date) && !isNoLessonDay(date, dayOfWeek) && !isFriday(date)  && !isPassedTwoMonth(date);
  };

  useEffect(() => {
    getHolidayList();
    // startDate, isDateActive işlevinin aktif olduğu ilk tarihe ayarlanır.
    const today = new Date();
    let activeDate = registrationType === SalesTypeEnum.Extension ? activeTermEndDate : today;

    if(activeDate && !isDateActive(activeDate)) {
      while (step === 3) {
        activeDate =  new Date(activeDate?.setDate(activeDate?.getDate() + 1));

        // Yeni elde edilen gün aktifse, döngüyü sonlandır
        if (isDateActive(activeDate)) {
          break;
        }
      }
    }

    setStartDate(activeDate);
  }, [activeTermEndDate, dayOfWeek.id, step])

  useEffect(() => {
    if(termId) {
      getTermEndDateAndDay(termId);
    }
  }, []);

  useEffect(() => {
    if(memberId) {
      getMemberEmail(memberId);
    }
  }, [brandId]);
  //Step 3 Son

  //Step 4 Başlangıç
  const invoiceTypes = CurrentCardType.map(cct => ({ id: cct.id, name: cct[language] }));

  //Hataları input alanının altına yazdırmak için kullanılan referanslar
  const fullNameErrorRef = useRef();
  const taxNumberErrorRef = useRef();
  const invoiceTypeErrorRef = useRef();
  const addressErrorRef = useRef();
  const taxOfficeErrorRef = useRef();

  const firstInvoiceType = { id: -1, name: strings.member.member_detail.accounting.choose_invoice_type };
  const [invoiceType, setInvoiceType] = useState(firstInvoiceType);
  const invoiceTypeList = [firstInvoiceType, ...invoiceTypes];

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [isAbroad, setIsAbroad] = useState(false);
  const [taxOffice, setTaxOffice] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [address, setAddress] = useState("");
  const [currentCardList, setCurrentCardList] = useState([]);
  const [waitCreatePersonalInformation, setWaitCreatePersonalInformation] = useState(false);

  useEffect(() => {

    if(invoiceType.id !== -1) {
      fullNameErrorRef.current.innerText = '';
      addressErrorRef.current.innerText = '';
      taxNumberErrorRef.current.innerText = '';
    }

    if (invoiceType.id === 1) {
      taxOfficeErrorRef.current.innerText = '';
    }

  }, [invoiceType.id]);

  const submitInformationHandle = async (e) => {
    e.preventDefault();

    let valid = true;

    //Hataları sıfırlıyoruz
    fullNameErrorRef.current.innerText = '';
    invoiceTypeErrorRef.current.innerText = '';
    taxNumberErrorRef.current.innerText = '';
    addressErrorRef.current.innerText = '';

    if (invoiceType.id === 1) {
      taxOfficeErrorRef.current.innerText = '';
    }

    if (invoiceType.id === -1) {
      valid = false;
      invoiceTypeErrorRef.current.innerText = strings.errors.invoice_type_null_error;
    }

    if (fullName.length === 0) {
      valid = false;
      fullNameErrorRef.current.innerText = strings.errors.general_null_error;
    }

    if (address.length === 0) {
      valid = false;
      addressErrorRef.current.innerText = strings.errors.address_null_error;
    }

    if (!isAbroad) {
      if (invoiceType.id === 0 && taxNumber.length !== 0 && !isValidIdentityNumber(taxNumber)) {
        valid = false;
        taxNumberErrorRef.current.innerText = strings.errors.identity_number_format_error;
      }

      if (invoiceType.id === 1 && taxNumber.length === 0) {
        valid = false;
        taxNumberErrorRef.current.innerText = strings.errors.tax_number_null_error;
      }

      if (invoiceType.id === 1 && taxOffice.length === 0) {
        valid = false;
        taxOfficeErrorRef.current.innerText = strings.errors.tax_office_null_error;
      }
    }

    if (valid) {

      setWaitCreatePersonalInformation(waitCreatePersonalInformation => true);

      const model = {
        memberId: memberId,
        cardType: invoiceType.id,
        name: fullName,
        taxOffice: taxOffice.length !== 0 ? taxOffice : null,
        taxNumber: taxNumber.length !== 0 ? taxNumber : "11111111111",
        email: email,
        isAbroad: isAbroad,
        address: address,
        employeeId: employee.EmployeeId
      }

      const result = await CreateCurrentCard(model);
      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.sales_operations.registration_success, "success");
        setTimeout(() => window.location.href = "/member/detail/profile/" + memberId, 1000);
        setIsOperationsCompleted(isOperationsCompleted => true);
      } else {
        showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        setWaitCreatePersonalInformation(waitCreatePersonalInformation => false);
      }

    }
  }

  //Eğer eski cari kart ile devam edecekse mesaj ver, devam et
  const submitInformationWithoutHandle = () => {
    showTopAlert(strings.member.member_detail.sales_operations.registration_success, "success");
    setIsOperationsCompleted(isOperationsCompleted => true);
    setTimeout(() => window.location.href = "/member/detail/profile/" + memberId, 1000);
  }

  //Cari kartlar için aşağıdaki değişkenleri tutuyoruz
  const firstCurrentCard = { id: -1, name: strings.member.member_detail.accounting.choose_previous_current_card };
  const [selectedCurrentCard, setSelectedCurrentCard] = useState(firstCurrentCard);
  const [addNewCurrentCard, setAddNewCurrentCard] = useState(false);

  useEffect(() => {
    if (selectedCurrentCard !== -1) {
      if ((registrationType === RegistrationType.ExtraPackage || registrationType === RegistrationType.Extension || registrationType === RegistrationType.TryAgain)) {
        setAddNewCurrentCard(false);
      } else {
        setAddNewCurrentCard(true);
      }

    }
  }, [selectedCurrentCard])

  //Eski cari kartları varsa onları listeliyoruz
  const getCurrentCardSelectListByMemberId = async (memberId) => {
    const result = await GetCurrentCardSelectListByMemberId(memberId);

    let arrayList = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.value, name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setCurrentCardList([firstCurrentCard, ...arrayList]);
  }

  //Member Profile bilgileri getiriliyor
  const getMemberProfile = async (memberId) => {

    const model = {
      memberId: memberId,
      languageTitleType: getLanguageTypeByLanguageKey(language)
    }

    const result = await GetMemberProfile(model);
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setFullName(fullName => resultContent.fullname);
      setEmail(email => resultContent.email || "");
    }
  }

  useEffect(() => {
    if (step === 4 && (registrationType === RegistrationType.ExtraPackage || registrationType === RegistrationType.Extension || registrationType === RegistrationType.TryAgain)) {
      getCurrentCardSelectListByMemberId(memberId);
    }
    if (step === 4) {
      getMemberProfile(memberId);
    }
  }, [step])

  //Step 4 Son
  return (
    <>
      <div className="mt-5 lg:px-[10px] flex flex-col sm:flex-row gap-4 lg:gap-16 max-w-[1150px] mx-auto" ref={topRef}>
        <PageStep step={step} stepCount="4" />
        {step === 1 && (
          <div className="w-full">
            <PageTitle title={title} />
            <div className="shadow-content border mt-5 rounded-[5px]">
              <div className="bg-blue p-5 pb-[50px] rounded-t-[5px] flex flex-col gap-3">
                <Dropdown
                    classnames="rounded md:max-w-full"
                    data={[firstProductCategory, ...productCategoryList]}
                    selectedData={productCategory}
                    setSelectedData={setProductCategory}
                />
                <div className="flex flex-col md:flex-row gap-3 md:gap-1">
                  <Dropdown
                      classnames="rounded md:max-w-full"
                      dark={true}
                      data={monthList}
                      selectedData={month}
                      setSelectedData={setMonth}
                      isDisabled={productCategory.id === -1 && true}
                  />
                  <Dropdown
                      classnames="rounded md:max-w-full"
                      dark={true}
                      data={dayOfWeekList}
                      selectedData={dayOfWeek}
                      setSelectedData={setDayOfWeek}
                      isDisabled={(productCategory.id === -1 || month.id === -1) && true}
                  />
                  <Dropdown
                      classnames="rounded md:max-w-full"
                      dark={true}
                      data={minuteList}
                      selectedData={minute}
                      setSelectedData={setMinute}
                      isDisabled={(productCategory.id === -1 || month.id === -1 || dayOfWeek.id === -1) && true}
                  />
                </div>
              </div>
              <div>
                <div className="relative -mt-8">
                  <div
                      className="px-5 py-4 cursor-pointer text-dark-text bg-card rounded-t-md rounded-b-md flex justify-between items-center mx-5 shadow-paymentAccordion"
                      onClick={() => {
                        setIsClick(isClick => true);
                        toggleTicketAccordion(accordionRef, plusSignRef, minusSignRef)
                      }
                      }
                  >
                    <div
                        id="dropdown_section_01"
                        className="relative flex flex-col gap-2"
                    >
                      <div className="flex items-center">
                        <span className="material-symbols-outlined text-[28px] pr-4">
                          tune
                        </span>
                        <p>{strings.member.member_detail.sales_operations.discount_campaign_apply}</p>
                      </div>
                    </div>
                    <span
                        ref={plusSignRef}
                        className="material-symbols-outlined font-bold text-base cursor-pointer"
                    >
                      add
                    </span>
                    <span
                        ref={minusSignRef}
                        className="material-symbols-outlined font-bold text-base hidden cursor-pointer"
                    >
                      remove
                    </span>
                  </div>
                  <div
                      ref={accordionRef}
                      className="max-h-0 mx-5 px-4 gap-x-4 gap-y-5 flex-wrap max-md-lg:justify-center border-[#ebebeb] rounded-b-md animate-fadeOut transition-max-height duration-500 shadow-paymentAccordion"
                  >
                    <div className={`${!isClick ? 'hidden' : ''} flex flex-col md:flex-row gap-3 w-full`}>
                      <Dropdown
                          classnames="md:max-w-full"
                          data={campaignList}
                          selectedData={campaign}
                          setSelectedData={setCampaign}
                          isDisabled={(campaignList.length === 1 && campaignList[0].id === -1) ? true : false}
                          tooltip={(campaignList.length === 1 && campaignList[0].id === -1 && dayOfWeek.id !== -1) ? strings.errors.campaign_not_found : ""}
                      />
                      <DropdownMultiSelect
                          showChooseAll="notShow"
                          title={strings.detail_search_titles.discounts}
                          data={discountList || []}
                          selectedData={discount}
                          setSelectedData={setDiscount}
                          randomId="discountListMS"
                          classnames="md:!max-w-full"
                          inputPlaceholder={strings.detail_search_titles.search_discount}
                          isDisabled={discountList.length === 0 ? true : false}
                          tooltip={(discountList.length === 0 && dayOfWeek.id !== -1) ? strings.errors.discount_not_found : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col xl:flex-row xl:justify-between mx-5 items-center my-10 gap-10 xl:gap-2">
                <ul className="flex flex-col gap-3">
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.no_call_right}: {selectedProduct?.missedRightCount || "-"}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.terms.postpone_lesson_right}: {selectedProduct?.postponeRightCount || "-"}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.freeze_lesson_right}: {selectedProduct?.freezeRightCount || "-"}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.terms.teacher_time_change_right}: {selectedProduct?.teacherHourChangeRightCount || "-"}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d] max-w-[200px]">
                      <span
                          className="font-bold"
                      >
                        {strings.member.member_detail.sales_operations.total_lesson_count}:{" "}
                        {productLessonCount === 0
                            ? "-"
                            : productGiftLessonCount === 0
                                ? strings.member.member_detail.sales_operations.total_lesson
                                    .replace("#count#", productLessonCount)
                                : strings.member.member_detail.sales_operations.total_lesson_count_gift
                                    .replace("#count#", productLessonCount)
                                    .replace("#giftCount#", productGiftLessonCount)
                        }
                      </span>
                    </p>
                  </li>
                </ul>
                <div className="min-w-max">
                  <div className="w-[230px] h-[180px] flex flex-col justify-center items-center">
                    <PriceShape children={
                      <div className="w-full h-full flex flex-col items-center justify-center text-center">
                        <p className="font-bold text-blue text-[31px]">
                          <span>{Object.keys(selectedProduct).length > 0 ? selectedProductPrices.totalPrice + " " + selectedProduct?.priceDetail?.currencySymbol : "0.00"} </span>
                        </p>
                        <p className="font-bold text-[14px] text-[#8b008b]">
                          % {Object.keys(selectedProduct).length > 0 ? (discountDiscountRate + campaignDiscountRate) > 100 ? 100 : (discountDiscountRate + campaignDiscountRate) : "0"} {strings.member.member_detail.sales_operations.discount}
                        </p>
                      </div>
                    }/>

                  </div>
                </div>
                <ul className="flex flex-col gap-3 min-w-[242px]">
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.max_payment_option_count}: {selectedProduct?.priceDetail?.maxInstallment || "-"}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.per_lesson_price_without_discount}: {selectedProduct?.priceDetail?.lessonPrice || "0,00"} {selectedProduct?.priceDetail?.currencySymbol}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.per_lesson_price}: {selectedProduct?.priceDetail?.discountLessonPrice || "0,00"} {selectedProduct?.priceDetail?.currencySymbol}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.no_discount_price}: {selectedProductPrices?.discountPrice} {selectedProduct?.priceDetail?.currencySymbol}</span>
                    </p>
                  </li>
                  <li className="flex gap-2 2xl:gap-3">
                    <img className="w-5 h-5" src={Accept} alt=""/>
                    <p className="text-[#6c757d]">
                      <span
                          className="font-bold">{strings.member.member_detail.sales_operations.kdv}: {selectedProductPrices?.taxPrice} {selectedProduct?.priceDetail?.currencySymbol}</span>
                    </p>
                  </li>
                </ul>
              </div>

              {selectedProductPrices.totalPriceWithoutFormat > 0 &&
                  <ul className="max-w-max mx-auto -mt-3 flex flex-col gap-1">
                    {brandId !== Brands.KO &&
                      <li className='text-[16px] text-center'>
                        <span className="font-bold">{strings.member.member_detail.sales_operations.turkish_lira_price}:{" "}</span>
                        {selectedProductPrices.turkishLiraPrice} ₺
                      </li>
                    }
                    <li className='font-bold text-[16px] text-center'>
                      {strings.member.member_detail.sales_operations.max_installment_title
                          .replace("#price#", formatPrice((selectedProductPrices.totalPriceWithoutFormat / 12), selectedProduct?.priceDetail?.currencySymbol))
                          .replace("#maxInstallment#", selectedProduct?.priceDetail?.maxInstallment)
                      }
                    </li>
                  </ul>
              }

              {Object.keys(selectedProduct).length > 0 && (
                  <button
                      className={classNames('button gap-3 font-bold text-blue border-blue my-7 rounded mx-auto w-[calc(100%-40px)] bg-blue/20 transition-colors duration-500', {'hover:!bg-blue !bg-white hover:text-white focus:text-white focus:!bg-blue': productCategory.id !== -1 && month.id !== -1 && dayOfWeek.id !== -1 && minute.id !== -1})}
                      disabled={productCategory.id === -1 && month.id === -1 && dayOfWeek.id === -1 && minute.id === -1 ? true : false}
                      onClick={() => setStep(2)}
                  >
                    {strings.button.pay}
                  </button>
              )}

            </div>
          </div>
        )}
        {step === 2 && (
            <>
              <div className="w-full relative sm:w-[calc(100%-114px)]">
                {(!payPackageByCreditCardLoading || !payPackageByCreditCardLoading) &&
                  <button
                      className="absolute top-[-90px] sm:top-[14px] right-4 flex items-center hover:text-blue focus:text-blue"
                      onClick={() => setStep(1)}
                  >
                    <span id="back" className="material-symbols-outlined cursor-pointer">
                      arrow_back_ios
                    </span>
                    <label className="cursor-pointer font-bold" htmlFor="back">{strings.pagination.previous}</label>
                  </button>
                }
                {/* <h3 className="font-bold text-[19px]">{strings.member.member_detail.sales_operations.payment_operations}</h3> */}
                <PageTitle title={strings.member.member_detail.sales_operations.payment_operations}/>
                <ul id="tabs" className="flex gap-x-5 sm:gap-x-10 flex-wrap">
                  <li className={classNames('member-nav-button', {'member-nav-button-active': tab === 0})}>
                    <button
                        onClick={() => setTab(0)}>{strings.member.member_detail.sales_operations.credit_card}</button>
                  </li>
                  <li className={classNames('member-nav-button', {'member-nav-button-active': tab === 1})}>
                    <button onClick={() => setTab(1)}>{strings.member.member_detail.sales_operations.transfer}</button>
                  </li>
                  {/*
                    TODO: KREDİ EKRANLARI EKLENİNCE AÇILACAK
                    //KREDİ ÖDEMELERİ BU BÖLÜMDEN YAPILIYOR.
                    //BU BÖLÜM HER ŞEY YAYINA GİRDİKTEN SONRA ÖMER İLE ÇALIŞILARAK YAPILACAK

                    <li className={classNames('member-nav-button', { 'member-nav-button-active': tab === 2 })}>
                      <button onClick={() => setTab(2)}>{strings.member.member_detail.sales_operations.shopping_credit}</button>
                    </li>
                  */}
                </ul>

                <input
                    id="secure-payment-input"
                    ref={hiddenInputRef}
                    className="hidden"
                />

                <div id="tab-contents">
                  {tab === 0 && (
                      <CreditCard
                          cardNumber={cardNumber}
                          cardholderName={cardholderName}
                          cardExpiration={cardExpiration}
                          cardCvv={cardCvv}
                          setCardNumber={setCardNumber}
                          setCardholderName={setCardholderName}
                          setCardExpiration={setCardExpiration}
                          setCardCvv={setCardCvv}
                          setBank={setBank}
                          setBankName={setBankName}
                          bankPosList={bankPosList}
                          setIsOneShot={setIsOneShot}
                          setIsForeignCard={setIsForeignCard}
                      />
                  )}
                </div>
                <div className="flex flex-col gap-5 mt-5">
                  <div className="flex items-center gap-2">
                    <p className="text-[22px]">{Object.keys(selectedProduct).length > 0 ? selectedProductPrices.totalPrice + " " + selectedProduct?.priceDetail?.currencySymbol : "0.00"}</p>
                    <div className="group relative flex">
                  <span className="material-symbols-outlined cursor-pointer">
                    info
                  </span>
                      <div className="tooltip-top">
                        <p>{strings.member.member_detail.sales_operations.kdv_include}</p>
                      </div>
                    </div>
                  </div>
                  {tab === 2 && (
                      <div id="shopping_credit">
                        <Accordion
                            outSideClose={true}
                            title="Fibabank"
                            button={
                              <LargeButton
                                  iconName="done"
                                  iconClassnames="bg-green border-green"
                                  iconText={strings.member.member_detail.sales_operations.complete_order}
                              />
                            }
                            content={
                              <>
                                <div className="w-full overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                                  <table className="min-w-full leading-normal text-header">
                                    <thead>
                                    <tr>
                                      <th className="th text-center"><p
                                          className="min-w-max">{strings.member.member_detail.sales_operations.payment_option}</p>
                                      </th>
                                      <th className="th text-center"><p
                                          className="min-w-max">{strings.table.monthly_income} (₺)</p></th>
                                      <th className="th text-center"><p
                                          className="min-w-max">{strings.table.monthly_total_cost_rate}</p></th>
                                      <th className="th text-center"><p
                                          className="min-w-max">{strings.table.yearly_total_cost_rate}</p></th>
                                      <th className="th text-center"><p
                                          className="min-w-max">{strings.table.total_payment} (₺)</p></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="bg-white hover:bg-[#f0f0f0]">
                                      <td className="td text-center">10</td>
                                      <td className="td text-center">10</td>
                                      <td className="td text-center">10</td>
                                      <td className="td text-center">10</td>
                                      <td className="td text-center">10</td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div id='show-button'></div>

                              </>
                            }
                        />
                      </div>
                  )}
                  {/*

              //BU BÖLÜM TEKRARLI ÖDEME YAPILABİLMESİ İÇİN KULLANILIYOR.
              //İLK YAYINDAN SONRA ÖMER İLE ÇALIŞARAK BURASI DÜZENLENİLMELİ
              TODO:TEKRARLI ÖDEME

              tab === 0 && (
                <label className="flex justify-start items-start max-w-max">
                  <input
                    id="reccuring_payment"
                    type="checkbox"
                    className="hidden"
                    onClick={() => setIsRecurringPayment(isRecurringPayment => !isRecurringPayment)}
                  />
                  <label
                    htmlFor="reccuring_payment"
                    className={`${handleCheckbox(isRecurringPayment)} mr-2 w-5 h-5 flex-shrink-0 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                  >
                    <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isRecurringPayment })}>
                      done
                    </span>
                  </label>
                  <p className="font-bold">{strings.member.member_detail.sales_operations.recurring_payment}</p>
              </label>
              )*/}
                  {tab === 1 && (
                      <div className="flex flex-col gap-1">
                        <p className="font-bold">{strings.member.member_detail.sales_operations.bank_options}</p>
                        <div className="flex gap-y-1 flex-wrap">
                          {/* Eğer havale bölümü seçiliyse banka listesi veriliyor */}
                          {bankInfoList && bankInfoList.map((bankInfo, index) => (
                              <button
                                  key={index}
                                  className={classNames('bank-container', {'border-blue': bankInfo.bankPosId === bank})}
                                  onClick={() => {
                                    setBank(bankInfo.bankPosId);
                                  }}
                              >
                                <div className="group relative flex">
                                  <img src={process.env.REACT_APP_PROFILE_URL + bankInfo.image}
                                       className="cursor-pointer h-full"/>
                                  <div
                                      className="tooltip-top -top-[60px]">{primeData.localCompanyList.find(lc => lc.id === bankInfo.brandLocalCompanyId).name}</div>
                                </div>
                              </button>
                          ))}
                          {/* Eğer havale bölümü seçiliyse pos listesi veriliyor */}
                        </div>
                      </div>
                  )}
                  {tab === 0 && (
                      (Object.keys(selectedProduct).length > 0) &&
                      <Installment
                          setState={setPaymentOption}
                          state={paymentOption}
                          totalPrice={selectedProductPrices?.totalPrice}
                          totalPriceWithoutFormat={selectedProductPrices?.totalPriceWithoutFormat}
                          currencySymbol={selectedProduct?.priceDetail?.currencySymbol}
                          bankPosId={bank}
                          isOneShot={isOneShot}
                      />
                  )}
                  {tab === 0 && (
                      <button
                          className={`${(isCompletePayment || payPackageByCreditCardLoading || !cardNumber || !cardholderName || !cardExpiration || !cardCvv || bank === null ? true : isCompletePayment) ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} hover:bg-blue hover:text-white focus:text-white focus:bg-blue button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-full transition-colors duration-500`}
                          disabled={isCompletePayment || payPackageByCreditCardLoading || !cardNumber || !cardholderName || !cardExpiration || !cardCvv || bank === null ? true : isCompletePayment}
                          onClick={handlePayPackage}
                      >
                        {payPackageByCreditCardLoading && (
                            <Loading
                                text={strings.processing_upper}
                                className="mt-0"
                            />
                        )}
                        {!payPackageByCreditCardLoading && strings.button.complete_payment}
                      </button>
                  )}
                  {tab === 1 && (
                      <button
                          className={`${(bank === null || payPackageByTransferLoading) ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : isCompletePayment} hover:bg-blue hover:text-white focus:text-white focus:bg-blue button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-full transition-colors duration-500`}
                          disabled={(bank === null || payPackageByTransferLoading) ? true : isCompletePayment}
                          onClick={payPackageByTransfer}
                      >
                        {payPackageByTransferLoading && (
                            <Loading
                                text={strings.processing_upper}
                                className="mt-0"
                            />
                        )}

                        {!payPackageByTransferLoading && strings.button.complete_payment}
                      </button>
                  )}
                </div>
              </div>

              {(Object.keys(modalContent).length !== 0 && (
                  <Modal modal={modal} setModal={setModal} classes={{modal: "h-[600px] max-w-[600px]"}} closable={false} >
                    {modalContent.element === "3dModal" &&
                        <iframe
                            id="secure-payment-iframe"
                            src={`${securePaymentIframeLinks[paymentOption < 2 ? "Ziraat Bankası" : bankName]}?q=${securePaymentBase64Content}`}
                            className="w-full h-[550px]"
                        />
                    }
                  </Modal>
              ))}
            </>
        )}
        {step === 3 && (
            <div className="w-full">
              <PageTitle title={strings.member.member_detail.sales_operations.term_operations} />
            <form className="w-full mt-5">
              {((baseMemberEmail.length === 0) && Brands.KO === brandId) && (
                <div className="flex flex-col pb-0">
                  <label htmlFor="member_email">{strings.form_info.email}</label>
                  <Input
                      name="member_email"
                      defaultValue={memberEmail}
                      onChange={(e) => setMemberEmail(memberEmail => e.target.value)}
                  />
                  <span
                      ref={memberEmailErrorRef}
                      className="text-danger field-validation-valid px-[10px]"
                  >
                  </span>
                </div>
              )}
              <div className="flex flex-col pb-0 mt-2">
                <label htmlFor="term_begin_date">{strings.member.member_detail.terms.term_start_date}</label>
                <div className="relative">
                  <DatePicker
                    locale="tr"
                    dateFormat="dd/MM/yyyy"
                    className="form-input w-full"
                    selected={startDate}
                    onClickOutside={() => setIsOpen(false)}
                    minDate={registrationType === SalesTypeEnum.Extension ? activeTermEndDate : new Date()}
                    onChange={(date) => {
                      setStartDate(date);
                      setIsOpen(false);
                    }}
                    filterDate={isDateActive}
                    placeholderText={
                      strings.form_info.date_placeholder
                    }
                    open={isOpen}
                    readOnly={true}
                    onFocus={() => setIsOpen(true)}
                  />
                  <button type="button" onClick={() => setIsOpen(!isOpen)}>
                    <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                      calendar_today
                    </span>
                  </button>
                </div>
              </div>
            </form>
            {startDate && (
              <button
                className={`${waitCreateTerm ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} hover:bg-blue hover:text-white focus:text-white focus:bg-blue button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-full transition-colors duration-500`}
                disabled={waitCreateTerm}
                onClick={() => {
                  if(baseMemberEmail.length !== 0 || Brands.KO !== brandId) {
                    addMemberTerm();
                  } else {
                    handleCreateTermWithUpdateEmail();
                  }
                }}
              >
                {waitCreateTerm && (
                    <Loading
                      text={strings.processing_upper}
                      className="mt-0"
                    />
                )}
                {!waitCreateTerm && (
                    strings.button.create_term
                )}
              </button>
            )}
          </div>
        )}
        {step === 4 && (
          <div className="w-full">
            {/* <h3 className="font-bold text-[19px]">{strings.table.person_informations}</h3> */}
            <PageTitle title={strings.table.person_informations} />
            {currentCardList.length > 0 && (
              <div className="flex flex-row w-full gap-4">
                <Dropdown
                  classnames="md:max-w-full"
                  data={currentCardList}
                  selectedData={selectedCurrentCard}
                  setSelectedData={setSelectedCurrentCard}
                />
                {selectedCurrentCard.id === -1 && <button
                  className="button search-button text-white bg-blue border-blue mx-auto w-[250px]"
                  type="button"
                  onClick={() => setAddNewCurrentCard(true)}>
                  {strings.member.member_detail.accounting.add_new_current_card}
                </button>}
              </div>)}
            {selectedCurrentCard.id === -1 && addNewCurrentCard && <form onSubmit={submitInformationHandle}>
              <div className={`flex flex-col gap-4 mt-5`}>

                <div className="flex flex-col px-[10px] pb-0 w-full">
                  <label htmlFor="invoice_type">{strings.form_info.invoice_type}</label>
                  <Dropdown
                    classnames="md:!max-w-full"
                    data={invoiceTypeList}
                    selectedData={invoiceType}
                    setSelectedData={setInvoiceType}
                  />
                  <span
                    ref={invoiceTypeErrorRef}
                    className="text-danger field-validation-valid px-[10px]"
                  >
                  </span>
                </div>

                {invoiceType.id !== -1 && (<>
                  <div className="flex flex-col px-[10px] pb-0 w-full">
                    <label htmlFor="full_name">
                      {invoiceType.id === 0 ? strings.form_info.full_name : strings.form_info.company_name} <span className="text-red">*</span>
                    </label>
                    <Input
                      id="full_name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <span
                      ref={fullNameErrorRef}
                      className="text-danger field-validation-valid px-[10px]"
                    >
                    </span>
                  </div>

                  <div className="flex flex-col px-[10px] pb-0 w-full">
                    <label htmlFor="member_email">
                      {strings.form_info.email}
                    </label>
                    <Input
                      id="member_email"
                      value={email ? email : ""}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col md:flex-row text-start gap-4 w-full">
                    {invoiceType.id === 1 && (<div className="flex flex-col px-[10px] pb-0 w-full">
                      <label htmlFor="tax_office">
                        {strings.form_info.tax_office} {!isAbroad && <span className="text-red">*</span>}
                      </label>
                      <Input
                        id="tax_office"
                        onChange={(e) => setTaxOffice(e.target.value)}
                      />
                      <span
                        ref={taxOfficeErrorRef}
                        className="text-danger field-validation-valid px-[10px]"
                      >
                      </span>
                    </div>)}
                    <div className="flex flex-col px-[10px] pb-0 w-full">
                      <label htmlFor="tax_number">
                        {invoiceType.id === 0 ? strings.form_info.identity_number : strings.form_info.tax_number} {(invoiceType.id === 1 && !isAbroad) && <span className="text-red">*</span>}
                      </label>
                      <Input
                        id="tax_number"
                        onChange={(e) => setTaxNumber(e.target.value)}
                      />
                      <span
                        ref={taxNumberErrorRef}
                        className="text-danger field-validation-valid px-[10px]"
                      >
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row text-start gap-4 w-full">
                    <div className="flex flex-col px-[10px] pb-0 w-full">
                      <label htmlFor="member_address">
                        {strings.table.address} <span className="text-red">*</span>
                      </label>
                      <textarea
                        id="member_address"
                        type="text"
                        className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                        rows="2"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      <span
                        ref={addressErrorRef}
                        className="text-danger field-validation-valid px-[10px]"
                      >
                      </span>
                    </div>
                    <div className="flex flex-col px-[10px] pb-0  w-2/6">
                      <label className="min-w-max" htmlFor="is_abroad">{strings.form_info.is_abroad_taxpayer}</label>
                      <div className="h-[45px] flex justify-start items-center px-2">
                        <input
                          type="checkbox"
                          id="isActive1"
                          className="hidden"
                          onClick={() => setIsAbroad(isAbroad => !isAbroad)}
                        />
                        <label
                          htmlFor="isActive1"
                          className={`${handleCheckbox(isAbroad)} mr-2 w-5 h-5 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                        >
                          <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isAbroad })}>
                            done
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                </>)}

              </div>
              {invoiceType.id !== -1 && (<div className="flex gap-3 justify-center">
                <button
                  type="submit"
                  disabled={waitCreatePersonalInformation}
                  className={`${waitCreatePersonalInformation ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-[calc(100%-40px)] transition-colors duration-500 hover:bg-blue hover:text-white focus:text-white focus:bg-blue`}
                >
                  {waitCreatePersonalInformation && (
                      <Loading
                        text={strings.processing_upper}
                        className="mt-0"
                      />
                  )}
                  {!waitCreatePersonalInformation && (
                      strings.button.save_and_complete_upper
                  )}
                </button>
              </div>)}
            </form>}

            {(registrationType === RegistrationType.ExtraPackage || registrationType === RegistrationType.Extension || registrationType === RegistrationType.TryAgain) &&
              selectedCurrentCard.id !== -1 && <div className="flex gap-3 justify-center">
                <button
                  type="button"
                  disabled={waitCreatePersonalInformation}
                  className={`${waitCreatePersonalInformation ? 'bg-blue/20 hover:!bg-blue/20 hover:!text-blue' : ''} button gap-3 font-bold text-blue border-blue my-5 rounded mx-auto w-[calc(100%-40px)] transition-colors duration-500 hover:bg-blue hover:text-white focus:text-white focus:bg-blue`}
                  onClick={submitInformationWithoutHandle}
                >
                  {waitCreatePersonalInformation && (
                      <Loading
                          text={strings.processing_upper}
                          className="mt-0"
                      />
                  )}
                  {!waitCreatePersonalInformation && (
                    strings.button.save_and_complete_upper
                  )}
                </button>
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
};

export default Registration;