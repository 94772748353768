import React, {useEffect, useState} from 'react';
import {
  Modal,
  showCenteredAlertTwoButtonsWithParameters,
  showTopAlert,
  SquareButton
} from '../../../../../../components';
import {getMemberDeviceOS, isItSpecified} from '../../../../../../components/Utility';
import {DeviceOSName} from '../../../../../../consts/Constants';
import {ClickLogs} from '../../../../../../consts/Logs';
import useAnalytics from '../../../../../../hooks/Log/useAnalytics';
import {useLocalization} from '../../../../../../hooks/Utils/useLocalization';
import {
  ChangeMemberLessonAppStatus,
  DeleteMemberLessonApp
} from '../../../../../../services/Member/MemberLessonAppService';
import {GetMemberName} from '../../../../../../services/Member/MemberService';
import PermissionsModal from './PermissionsModal';
import Portal from '../../../../../../components/Portal';

const CallTypeTableRow = (props) => {

  const { application, index, activeCallType, sendRequestToEndpoint } = props;
  const strings = useLocalization();
  const analytics = useAnalytics();

  // Güncelleme yetkisi
  //const statusChangeRoles = getButtonPermission({ group: "callTypes", name: "statusChange" });
  //const statusChangePermission = checkPermission(statusChangeRoles, employeeTypeId);
  const statusChangePermission = true;

  // Silme yetkisi
  //const deleteActionRoles = getButtonPermission({ group: "callTypes", name: "deleteAction" });
  // const deletePermission = checkPermission(deleteActionRoles, employeeTypeId);
  const deletePermission = true;

  const [memberName, setMemberName] = useState();
  const [tokenStatus, setTokenStatus] = useState("No");
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  //Arama tipi durumunu aktif veya pasif yapar.
  const changeStatusCallType = async (memberLessonAppId, status) => {
    if (memberLessonAppId) {
      const result = await ChangeMemberLessonAppStatus(memberLessonAppId);

      if (result.status === 200) {
        if (status === 0) {
          showTopAlert(strings.member.member_detail.others.active_success_message, "success");
        }
        if (status === 1) {
          showTopAlert(strings.member.member_detail.others.passive_success_message, "success");
        }
        sendRequestToEndpoint();
        setTimeout(() => { window.location.reload() }, 2000);
      }
    }
  }

  //Arama tipini siler.
  const deleteCallType = async (memberLessonAppId) => {
    if (memberLessonAppId) {
      const result = await DeleteMemberLessonApp(memberLessonAppId);

      if (result.status === 200) {
        showTopAlert(strings.member.member_detail.others.call_type_delete_success_message, "success");
        sendRequestToEndpoint();
        setTimeout(() => { window.location.reload() }, 2000);
      }
    }
  }

  const getMemberName = async () => {
    const result = await GetMemberName(application.memberId);

    if (result.status === 200) {
      setMemberName(memberName => result.content.split(' ')[0]);
    }
  }

  // Yurtdışı tarafından gönderilen, url'in base64 encode edilmiş halini döndürür.
  const base64Encode = function(val) {
    return btoa(encodeURIComponent(val).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(parseInt(p1, 16)) ));
  };

  const testCall = () => {
    const newStr =
      'appId=' + application.appId +
      '&certificateId=' + application.certificateId +
      '&deviceToken=' + application.deviceToken + 
      '&deviceOS=' + getMemberDeviceOS(application.deviceOS, application.checkHuaweiPushkit).toLocaleLowerCase() +
      (application.channel ? ('&channel=' + application.channel) : '');
      
    const newBase64 = btoa(newStr);

    const d = new Date();
    const callDate = d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);

    let uri = "https://www.e-teacher.org/caller/#";
    let newUri = ""
    newUri = newUri + "?type=agora";
    newUri = newUri + "&token=" + newBase64;
    newUri = newUri + "&studentid=000000";
    newUri = newUri + "&studentname=Student " + memberName;
    newUri = newUri + "&teachername=Test Call";
    newUri = newUri + "&callid=" + new Date().toISOString();
    newUri = newUri + "&calldate=" + callDate;
    newUri = newUri + "&callnote=test";
    newUri = newUri + "&uploadurl=";

    window.open(uri + base64Encode(newUri), "edTalk", "height=300, width=400");
  }

  //Sadece 1 adet aktif  arama tipi varsa, o arama tipinin index'ini getirir.
  const activeCallTypeIndex = () => {
    let count = 0;

    for (let i = 0; i < activeCallType.length; i++) {
      if (activeCallType[i] === 1) {
        count++;
      }
    }

    if (count === 1) {
      let index = activeCallType.indexOf(1);

      return index;
    }

    return -1;
  }

  const getTokenColorByTokenStatus = (tokenStatus) => {
    switch(tokenStatus) {
      case "Yes":
        return 'bg-green';
      case "None":
        return 'bg-warning';
      default:
        return 'bg-red';
    }
  }

  const getTooltipTextByTokenStatus = (tokenStatus) => {
    switch(tokenStatus) {
      case "Yes":
        return strings.member.member_detail.others.token_active;
      case "None":
        return strings.member.member_detail.others.join_lesson_token;
      default:
        return strings.member.member_detail.others.token_passive;
    }
  }

  useEffect(() => {
    getMemberName();

    if(application.deviceToken !== "" && (application.deviceToken !== "None" && application.deviceOS !== DeviceOSName.None && application.deviceToken !== null)) {
      setTokenStatus(tokenStatus => "Yes");
    } else if (application.deviceToken === "None" && application.deviceOS === DeviceOSName.None) {
      setTokenStatus(tokenStatus => "None");
    }
  }, [application])


  return (
    <>
      <tr className="bg-white hover:bg-[#f0f0f0]">
        <td className="td align-middle">{application.applicationTitle}</td>
        <td className="td align-middle"><p className="min-w-max">{application.appLessonTypeTitle}</p></td>
        <td className="td align-middle">
          <div className="flex items-center max-w-[40px] relative group">        
            {getMemberDeviceOS(application.deviceOS)}
            {(application.deviceOS === DeviceOSName.None) && (tokenStatus === 'None') && (
              <div className='tooltip-top'>
                {strings.member.member_detail.others.dont_want_call}
              </div>
            )}
          </div>
        </td>
        <td className="td align-middle">
          <p className='w-[65px] text-center'>{(application.deviceOS === 2 || application.deviceOS === 3) && (application.version ? "v" + application.version : strings.errors.it_is_not_specified)}</p>
          {(application.deviceOS !== 2 && application.deviceOS !== 3) && <p className='w-[65px] text-[16px] text-center'>—</p>}
        </td>
        <td className="td align-middle">
          <div className="flex items-center justify-center relative group max-w-[100px]">
            <div className={`rounded-full w-4 h-4 shadow-circle ${getTokenColorByTokenStatus(tokenStatus)}`}></div>
            <div className="tooltip-top">
              <p>{getTooltipTextByTokenStatus(tokenStatus)}</p>
            </div>
          </div>
        </td>

        <td className="td align-middle">
          <p className="min-w-max">{isItSpecified(application.deviceName, strings.errors.it_is_not_specified)}</p>
        </td>
        <td className="td align-middle">
        <SquareButton
            iconName="devices"
            classnames="bg-green border-green"
            iconClassnames="text-[22px]"
            isPermissionButton={false}
            tooltip={strings.member.member_detail.others.show_permission}
            action={() => {
              setModal(true);
              setModalContent({ element: "permission", deviceId:application.deviceId });
            }}
        />
        </td>
        <td className="td align-middle">
          {/* 
              Eğer kullanıcının bir adet arama tipi varsa o arama tipini aktif, pasif veya silinmiş duruma getiremez.
              Burada bunu sağlamak için eğer bir adet aktif arama tipi var ise onu disabled hale getirip kullanıcının
              işlem yapmasını önlüyoruz.
          */}
          <SquareButton
            iconName={application.status === 1 ? 'check_circle' : 'cancel'}
            classnames={`
              ${(activeCallTypeIndex() === index || !statusChangePermission) ? 'opacity-70 !cursor-not-allowed hover:opacity-100' : ''}
              ${application.status === 1 ? 'bg-green border-green' : 'bg-red border-red'} transition-color duration-500`
            }
            iconClassnames={`${(activeCallTypeIndex() === index || !statusChangePermission) ? 'opacity-70 !cursor-not-allowed hover:opacity-100' : ''} text-[22px]`}
            isPermissionButton={false}
            tooltip={(activeCallTypeIndex() === index || !statusChangePermission) ? strings.errors.not_allowed : application.status === 1 ? strings.form_info.active : strings.form_info.pasive}
            action={() => {
              if (activeCallTypeIndex() !== index && statusChangePermission) {
                showCenteredAlertTwoButtonsWithParameters(
                  strings.alert.centered_alert_two_buttons.title,
                  application.status === 1
                    ? strings.member.member_detail.others.call_type_passive_question
                    : strings.member.member_detail.others.call_type_active_question,
                  strings.button.confirm,
                  strings.alert.centered_alert_two_buttons.cancel_text,
                  changeStatusCallType,
                  [application.id, application.status]
                )
              }
            }}
          />
        </td>
        <td className="td align-middle">
          <SquareButton
            iconName="delete"
            classnames={`${(activeCallTypeIndex() === index || !deletePermission) ? 'opacity-70 !cursor-not-allowed hover:opacity-100' : ''} bg-red border-red`}
            iconClassnames={`${(activeCallTypeIndex() === index || !deletePermission) ? 'opacity-70 !cursor-not-allowed hover:opacity-100' : ''} text-[22px]`}
            isPermissionButton={false}
            tooltip={(activeCallTypeIndex() === index || !deletePermission) ? strings.errors.not_allowed : strings.button.delete}
            action={() => {
              if (activeCallTypeIndex() !== index && deletePermission) {
                showCenteredAlertTwoButtonsWithParameters(
                  strings.alert.centered_alert_two_buttons.title,
                  strings.member.member_detail.others.call_type_delete_question,
                  strings.button.confirm,
                  strings.alert.centered_alert_two_buttons.cancel_text,
                  deleteCallType,
                  [application.id]
                )
              }
            }}
          />
        </td>
        <td className="td align-middle">
          <SquareButton
            iconName="call"
            classnames={`${(!application.status ||  (application.deviceToken === "None" && application.deviceOS === DeviceOSName.None)) ? 'opacity-70 !cursor-not-allowed hover:opacity-100' : ''} bg-warning border-warning`}
            iconClassnames={`${(!application.status ||  (application.deviceToken === "None" && application.deviceOS === DeviceOSName.None)) ? 'opacity-70 !cursor-not-allowed hover:opacity-100' : ''} text-[22px]`}
            isPermissionButton={false}
            tooltip={(!application.status || (application.deviceToken === "None" && application.deviceOS === DeviceOSName.None)) ? strings.errors.not_allowed : strings.member.member_detail.others.run_call_test}
            action={() => {
              if ((application.status && (application.deviceToken !== "None" && application.deviceOS !== DeviceOSName.None))) {
                testCall();
                //LOGGED
                analytics.useAnalytics(ClickLogs.CALL_TEST_BUTTON_CLICKED, { memberId: application.memberId })
              }
            }}
          />
        </td>
      </tr>
      <Portal container={document.getElementById('permission-modal-container')}>
        {(Object.keys(modalContent).length !== 0 && (
            <Modal
                modal={modal}
                setModal={setModal}
                classes={{ modal: "h-[400px] max-w-[600px]" }}
            >
                {modalContent.element === "permission" &&
                    <PermissionsModal deviceId={modalContent.deviceId} />
                }
            </Modal>
        ))}
      </Portal>
    </>
  )
}

export default CallTypeTableRow