import { Akbank, Finansbank, Garanti, Halkbank, IsBankasi, Yapikredi } from "../assets/img";

export const cookieName = process.env.REACT_APP_JWT_COOKIE_NAME;
export const alotechCookieName = process.env.REACT_APP_ALOTECH_TOKEN_COOKIE_NAME;
export const esneksCookieName = process.env.REACT_APP_ESNEKS_TOKEN_COOKIE_NAME;

export const Brands = Object.freeze(
    {
        "KO": 1,
        "AZ": 2,
        "AL": 3
    }
)

export const MemberFormOperationReasonType = Object.freeze(
    {
        "General": 0,
        "Drop": 1,
        "Reminder": 2
    }
);

export const MemberStatus = Object.freeze(
    {
        "Candidate": 1,
        "Student": 2,
        "Passive": 3
    }
);

export const MemberProfileStatus = Object.freeze(
    {
        "Private": 0,
        "Public": 1
    }
);

export const MemberProfileFollowStatus = Object.freeze(
    {
        "FOLLOWING_MEMBER": 1, // Takip edilen
        "PUBLIC_CONTACT_MEMBER": 2, // Rehberinde ve profili açık olan kişiler
        "EXIST_MEMBER_BY_PUBLIC_OTHER_CONTACTS": 3, // Rehberine eklemiş ve rehber izni olan kişiler
        "EXIST_MEMBER_BY_PRIVATE_OTHER_CONTACTS": 4, // Rehberine eklemiş ve rehber izni olmayan kişiler,
        "CURRENT_REFERER_MEMBER": 5, // Kişiyi aktif referans veren kişiler
        "OLD_REFERER_MEMBER": 6 // Kişiyi eskiden referans veren kişiler
    }
);

export const EmployeeStatusType = Object.freeze(
    {
        "Sistem": 1,
        "Yazılımcı": 2,
        "Yönetici": 3,
        "Satış Temsilcisi": 4,
        "Satış Takım Lideri": 5,
        "Satış Takım Lideri Yardımcısı": 6,
        "Verimlilik Temsilcisi": 7,
        "Verimlilik Takım Lideri": 8,
        "Verimlilik Takım Lideri Yardımcısı": 9,
        "Kurumsal Destek Temsilcisi": 10,
        "Kurumsal Takım Lideri": 11,
        "Kurumsal Takım Lideri Yardımcısı": 12,
        "Kurumsal Kilit Hesap Yöneticisi": 13,
        "Muhasebe": 14,
        "Destek": 15,
        "Referansatör": 16,
        "İnsan Kaynakları": 17,
        "Pazarlama": 18,
        "Ürün Yöneticisi": 19,
        "Santral": 20,
        "Supervisor": 21,
        "Eğitmen Supervisor": 22,
        "Eğitmen İnsan Kaynakları": 23,
        "Kurumsal Satış Temsilcisi": 24,
        "Stajyer": 25
    }
);

export const SalesTypeEnum = Object.freeze(
    {
        "Demo": 0,
        "FirstSale": 1,
        "ExtraPackage": 2,
        "Extension": 3,
        "TryAgain": 4,
        "ChangePackage": 5,
        "AdditionalService": 6,
        "Gift": 7,
    }
);

export const SalesTypeNames = Object.freeze(
    {
        "Demo": 'Demo Kayıt',
        "FirstSale": 'İlk Kayıt',
        "ExtraPackage": 'İkinci Kayıt'
    }
)

export const TermStatusType = Object.freeze(
    {
        "Passive": 0,
        "Active": 1,
        "Future": 2,
        "Deleted": 3,
        "Pending": 5
    }
);

export const PaymentStatusType = Object.freeze(
    {
        "FailedPayment": 0,
        "SuccessfulPayment": 1
    }
);

export const OrderStatusType = Object.freeze(
    {
        "NotPaid": 0,
        "Paid": 1
    }
);

export const GeneralStatusType = Object.freeze(
    {
        "Passive": 0,
        "Active": 1
    }
);

export const AccountingStatusType = Object.freeze(
    {
        "NotChecked": 0,
        "Checked": 1,
        "Cancelled": 2
    }
);

export const RightType = Object.freeze(
    {
        "Postpone": 1,
        "Freeze": 2,
        "Missed": 3,
        "TeacherHourChange": 4,
        "AdditionalLesson": 5
    }
);

//Takvim renklendirmesi için kullanılıyor.
export const ColorByLessonType = Object.freeze(
    {
        "Active": "#1572e8",
        "Attented": "#2da44e",
        "Missed": "#f25961",
        "Postponed": "#ffad46",
        "Frozen": "#1a2035",
        "Holiday": "#FF853C",
        "Weekend": "#222222",
        "NoLesson": "#e5e5e5",
        "SystemPostponed": "#ffd5a2",
        "Deleted": "#666666"
    }
);

export const ProductCategoryType = Object.freeze(
    {
        "GeneralEnglish": 1,
        "BusinessEnglish": 2,
        "CampusChild": 3,
        "Premium": 4,
        "Grammar": 5,
        "Demo": 6,
        "AdditionalService": 7
    }
);

export const GenderType = Object.freeze(
    {
        "Male": 0,
        "Female": 1,
        "Undefined": 2
    }
);

export const RegistrationType = Object.freeze(
    {
        "Demo": 0,
        "FirstSale": 1,
        "ExtraPackage": 2,
        "Extension": 3,
        "TryAgain": 4,
        "ChangePackage": 5

    }
);

export const CardType = Object.freeze(
    {
        "Person": 0,
        "Company": 1
    }
);


export const SourceType = Object.freeze(
    {
        "Web": 1,
        "Crm": 2,
        "Mobile": 3,
        "LandingPage": 4
    }
);

export const ApplicationName = Object.freeze(
    {
        "EdTalk": 1,
        "AzerCall": 2,
        "AlingCall": 3,
        "KoCall": 4,
        "KoWebCall": 7,
        "AlingWebCall": 8,
        "AzerWebCall": 9,
    }
);

export const DeviceOSName = Object.freeze(
    {
        "Web": 1,
        "IOS": 2,
        "Andorid": 3,
        "None": 4
    }
);

export const MemberApplicationStatus = Object.freeze(
    {
        "NewRegistration": "Yeni Kayıt",
        "DroppedPool": "Havuza Bırakıldı",
    }
)

export const CampaignTypes = Object.freeze(
    {
        "Moneybox": 0,
        "Reference": 1,
        "Other": 2,
        "Campaign": 3,
    }
)

export const CampaignTypeNames = Object.freeze(
    {
        0: "Kumbara İndirimi",
        1: "Referans İndirimi",
        2: "Diğer İndirimler",
        3: "Kampanya",
        4: "Taksit İndirimi",
        5: "Aile İndirimi"
    }
)

export const AIUseCaseRoles = Object.freeze(
    {
        "System": 0,
        "Member": 1,
        "Assistant": 2
    }
)

export const TermScheduleStatusType = Object.freeze(
    {
        "Active": 1,
        "Attended": 2,
        "Missed": 3,
        "Postponed": 4,
        "Frozen": 5,
        "Holiday": 6,
        "Weekend": 7,
        "NoLesson": 8,
        "SystemPostpone": 9,
        "Deleted": 10,
        "Transferred": 11
    }
)

export const StorageKeys = Object.freeze(
    {
        "ReminderLocalStorageKey": "reminders"
    }
);

export const ServiceErrorMessages = Object.freeze(
    {
        //Login
        "EMAIL_OR_PASSWORD_WRONG": "Email or password wrong!",
        //Reminder
        "DUPLICATE_REMINDER_ERROR": "There is a active reminder for this member!",
        "CALL_DATE_PAST_ERROR": "Call Date cannot set to past time.",
        "ALREADY_DATE_AND_TIME_ERROR": "There is already a reminder for this day and time.",
        "MEMBER_FORM_NOT_FOUND_ERROR": "Member form not found!",
        "CANNOT_DELETE_PAST_REMINDER_ERROR": "You can not delete a reminder from the past",
        //Sales Operations
        "EXTEND_PACKAGE_INVALID_TIME": "Invalid time!",
        //ESD
        "ESD_NOT_IN_TIME_INTERVAL": "You are not in the change timeframe!",
        'ESD_TWO_HOURS_ERROR': 'There is no 2 hours difference between the time you selected and the current time!',
        //Update Free Talk
        'UPDATE_FREE_TALK_ACTIVE_TERM_ERROR': 'Active term schedule not found for Friday!',
        'UPDATE_FREE_TALK_TIME_RANGE_ERROR': 'You are not in the unit update time range because of daily active lesson!',
        //Employee Update
        'UPDATE_EMPLOYEE_PASSIVE_BUG': 'The employee has an active student and cannot be made passive.',
        //Update Term Begin Date
        'BEGIN_DATE_MUST_BE_EQUAL_OR_GREATER': 'New begin date must be equal or greater than today!',
        'TERM_BEGIN_DATE_AND_BEGIN_DATE_ARE_EQUAL': 'Term begin date and selected begin date are already the same!',
        'ALL_LESSONS_MUST_BE_ACTIVE': 'All lessons must be active to change begin date!',
        'TIME_EXPIRED_ERROR': 'The selected time has expired!',
        'TWO_HOURS_ERROR': 'There is less than 2 hours time to lesson time!',
        //Update Unit
        'NOT_IN_TIME_RANGE': 'You are not in the unit update time range!',
        //Reference
        'REFERRER_MUST_BE_DIFFERENT_PERSON': 'The referrer must be a different person',
        //Hourly Discount
        'ACTIVE_DISCOUNT_ALREADY_EXIST': 'Active discount already exist!',
        //Create Reference
        'EXISTING_REFERENCE': 'Existing student cannot be added as a reference!',
        //Get Candidate
        'MEMBER_ALREADY_HAS_ACTIVE_SALES': 'Member already has an active sales.',
        "LIMIT_ERROR": 'Employee cannot take any other member from pool.',
        "DISTRIBUTION_STATUS_UNDISTRIBUTED": 'Employee member distribution status is undistributed!',
        "BRAND_ID_NOT_FOUND": 'Brand id not found!',
        "BEST_POSSIBLE_MEMBER_NOT_FOUND": 'Best possible member not found!',
        "MEMBER_FORM_NOT_FOUND": 'Any member form not found!',
        //Push members to pool
        "NO_MEMBERS_WERE_FOUND": 'No members were found!',
        //Move brand
        "EXISTING_MEMBER_ERROR": "The brand you want to move has a student with the same phone number!",
        //Employees
        "DISTRIBUTION_STATUS_INACTIVE": "Employee's distribution status is inactive!",
        // Task AI Analysis
        "TASK_NOT_FOUND": "Task not found!",
        "TASK_TYPE_DOES_NOT_SUPPORT": "Task type does not support to AI analysis!",
        "PROMPT_NOT_FOUND": "Prompt not found!",
        "TERM_ID_IS_REQUIRED": "Term id is required! (Parameter 'termId')",
        "ANY_CAPTION_NOT_FOUND": "Any caption not found!",
        "CAPTIONS_NOT_MERGED": "Captions not merged to AI analysis!",
        "AI_ANALYSIS_GAVE_ERROR": "AI analysis gave error!",
        "MEMBER_TASK_AI_ANALYSIS_NOT_FOUND": "Member task ai analysis not found!",
        // Task Weekly AI Analysis
        "MEMBER_TASK_WEEKLY_AI_RESULTS_NOT_FOUND": "Member task weekly ai results not found!",
        "AI_ANALYSIS_RESULT_LIST_NOT_MERGED": "Ai analysis result list not merged!",
        // Get Whatsapp
        "MEMBER_NOT_FOUND": "Member not found!",
        "BRAND_CANNOT_BE_ZERO": "Brand cannot be zero",
        "MEMBER_ALREADY_HAS_AN_ACTIVE_SALES": "Member already has an active sales.",
        "EMPLOYEE_NOT_FOUND": "Employee not found.",
        "EMPLOYEE_STATUS_MUST_BE_ACTIVE": "Employee status must be active!",
        "SALESPERSON_NOT_ASSIGNED": "Salesperson is not assigned because of limit and distribution status!",
        // Member Sales AI Analysis
        "AI_ANALYSIS_NOT_FOUND": "AI analysis not found!",
        "MEMBER_NOT_HAVE_ONBOARDING_USE_CASE": "Member is not answered onboarding questions yet!",
        "MEMBER_DOES_NOT_HAVE_ANY_AI_ANALYSIS_RESULT": "Member does not have any AI analysis result!",
        "SUMMARY_NOT_SUPPORT_LESS_THAN_TWO_AI_ANALYSIS_RESULTS": "Summary does not support less than two AI analysis results!",
        "STORYTELLING_NOT_SUPPORT_LESS_THAN_TWO_AI_ANALYSIS_RESULTS": "Storytelling does not support less than two AI analysis results!",
        "TERM_SCHEDULE_NOT_FOUND": "TermSchedule not found",
        // Delete Demo
        "NOT_IN_AVAILABLE_TIME_RANGE": "You are not in the available time range!"
    }
);

export const AIKeys = Object.freeze(
    {
        "CORRECTION": "correction",
        "INTERVIEW_PR0": "interview-pro",
        "LEVEL_FINDER": "level-finder",
        "VIDEO_GRAMMAR": "video-grammar",
        "VIDEO_EXERCISE" : "video-exercise"
    }
);

export const SalesAiType = Object.freeze(
    {
        "DEMO": 1,
        "INTERVIEW_PRO": 2,
        "LEVEL_TEST": 3,
        "ONBOARDING": 4,
        "STORYTELLING": 5,
        "SUMMARY": 6
    }
);

export const FamilyRole = Object.freeze(
    {   
        "MANAGER": 1,
        "MEMBER": 2,
        "ATTEND_REQUEST_MEMBER": 3 
    }
);

export const FamilyStatus = Object.freeze(
    {   
        "ACCEPTED": 1,
        "REJECTED": 2,
        "REMOVED": 3,
        "ATTEND_FAMILY_REQUEST": 4
    }
);
/* ---------------------------------------------------------------------------- */

export const UserTypes = [
    { id: 0, name: "Member / Üye" },
    { id: 1, name: "Employee / Çalışan" },
    { id: 2, name: "System / Sistem" }
];

export const TeacherMessageType = [
    { id: 0, name: "Eğitmenin Mesajı" },
    { id: 1, name: "Danışman Mesajı" },
    { id: 2, name: "Öğrenci Mesajı" },
    { id: 3, name: "Sistem Mesajı" }
];

export const GeneralStatusTypes = [
    { id: 0, name: "Passive / Pasif" },
    { id: 1, name: "Active / Aktif" }
];

export const AccountignStatusType = [
    { id: 0, en: "Accounting Unapproved", tr: "Muhasebe Onaysız", az: "Mühasibat Təsdiqlənməmiş", ar: "المحاسبة غير معتمدة" },
    { id: 1, en: "Accounting Approved", tr: "Muhasebe Onaylı", az: "Mühasibat Təsdiqlənmiş", ar: "تمت الموافقة على المحاسبة" },
    { id: 2, en: "Cancelled", tr: "İptal Edildi", az: "Ləğv edilir", ar: "تم إلغاؤه" }
]

export const Applications = [
    { id: 0, name: "Edtalk" },
];

export const ContactType = [
    { id: 1, name: "Telefon" },
    { id: 2, name: "E-Mail" },
    { id: 3, name: "Whatsapp" }
];

export const TransferStatus = [
    { id: 0, name: "Transfer Edilemez/İptal Edilemez" },
    { id: 1, name: "Transfer Edilebilir/İptal Edilemez" },
];

export const Gender = [
    { id: 2, name: 'Belirtilmedi', icon: 'person' },
    { id: 1, name: 'Kadın', icon: 'woman' },
    { id: 0, name: 'Erkek', icon: 'man' }
];

export const DateRanges = [
    { id: 1, name: 'Bugün' },
    { id: 2, name: 'Dün' },
    { id: 3, name: 'Son 7 gün' },
    { id: 4, name: 'Son 30 gün' },
    { id: 5, name: 'Bu ay' },
    { id: 6, name: 'Geçen ay' },
    { id: 7, name: 'Belirli bir tarih aralığı seç' },
];

export const DateRangesV2 = [
    { id: 1, name: 'Bugün' },
    { id: 2, name: 'Dün' },
    { id: 3, name: 'Geçmiş' },
    { id: 4, name: 'Gelecek' },
    { id: 5, name: 'Belirli bir tarih aralığı seç' },
];

export const Job = [
    { id: 1, en: "Academician", tr: "Akademisyen", az: "Akademisyen", ar: "Akademisyen" },
    { id: 2, en: "Attorney", tr: "Avukat", az: "Avukat", ar: "Avukat" },
    { id: 3, en: "Doctor", tr: "Doktor", az: "Doktor", ar: "Doktor" },
    { id: 4, en: "Civil Servant", tr: "Memur", az: "Memur", ar: "Memur" },
    { id: 5, en: "Engineer", tr: "Mühendis", az: "Mühendis", ar: "Mühendis" },
    { id: 6, en: "Student", tr: "Öğrenci", az: "Öğrenci", ar: "Öğrenci" },
    { id: 7, en: "Teacher", tr: "Öğretmen", az: "Öğretmen", ar: "Öğretmen" },
    { id: 8, en: "Private Sector", tr: "Özel Sektör", az: "Özel Sektör", ar: "Özel Sektör" },
    { id: 9, en: "Manager", tr: "Yönetici", az: "Yönetici", ar: "Yönetici" },
    { id: 10, en: "Other", tr: "Diğer", az: "Diğer", ar: "Diğer" },
    { id: 11, en: "Unknown", tr: "Belirtmedi/Bilinmiyor", az: "Belirtmedi/Bilinmiyor", ar: "Belirtmedi/Bilinmiyor" },
    { id: 12, en: "Unemployed", tr: "İşsiz", az: "İşsiz", ar: "İşsiz" },
    { id: 13, en: "Housewife", tr: "Ev Hanımı", az: "Ev Hanımı", ar: "Ev Hanımı" },
    { id: 14, en: "Finance", tr: "Finans", az: "Finans", ar: "Finans" },
    { id: 15, en: "Banker", tr: "Bankacı", az: "Bankacı", ar: "Bankacı" },
    { id: 16, en: "Employer", tr: "İşveren", az: "İşveren", ar: "İşveren" },
    { id: 17, en: "Health Employee", tr: "Sağlık Çalışanı", az: "Sağlık Çalışanı", ar: "Sağlık Çalışanı" },
    { id: 18, en: "Sales and Marketing", tr: "Satış ve Pazarlama", az: "Satış ve Pazarlama", ar: "Satış ve Pazarlama" },
    { id: 19, en: "Tourism Sector", tr: "Turizm Sektörü", az: "Turizm Sektörü", ar: "Turizm Sektörü" },
    { id: 20, en: "Retired", tr: "Emekli", az: "Emekli", ar: "Emekli" },
    { id: 21, en: "Architect", tr: "Mimar", az: "Mimar", ar: "Mimar" },
    { id: 22, en: "Foreign Trade", tr: "Dış Ticaret", az: "Dış Ticaret", ar: "Dış Ticaret" },
    {
        id: 23,
        en: "Human Resources Specialist",
        tr: "İnsan Kaynakları Uzmanı",
        az: "İnsan Kaynakları Uzmanı",
        ar: "İnsan Kaynakları Uzmanı"
    },
    { id: 24, en: "Co-worker", tr: "Şirket Çalışanı (KO)", az: "Şirket Çalışanı (KO)", ar: "Şirket Çalışanı (KO)" },
];


export const SalesType = [
    { id: 0, sales: "Demo", tr: "Demo Kayıt", ar: "عرض تجريبي", az: "Demo Qeydiyyatı", en: "Demo" },
    { id: 1, sales: "FirstSale", tr: "İlk Kayıt", ar: "أول عملية شراء", az: "İlk Satış", en: "First Sale" },
    { id: 2, sales: "ExtraPackage", tr: "İkinci Kayıt", ar: "حزمة إضافية", az: "Əlavə Paket", en: "Extra Package" },
    { id: 3, sales: "Extension", tr: "Uzatma", ar: "تمديد", az: "Təcili", en: "Extension" },
    { id: 4, sales: "TryAgain", tr: "Yeniden Dene", ar: "محاولة مرة أخرى", az: "Təkrar Cəhd", en: "Try Again" },
    {
        id: 5,
        sales: "ChangePackage",
        tr: "Paket Değişikliği",
        ar: "تغيير الحزمة",
        az: "Paket Dəyişikliyi",
        en: "Change Package"
    },
    {
        id: 6,
        sales: "AdditionalService",
        tr: "Ek Hizmetler",
        ar: "خدمات إضافية",
        az: "Əlavə Xidmətlər",
        en: "Additional Service"
    },
    { id: 7, sales: "Gift", tr: "Hediye", ar: "هدية", az: "Hədiyyə", en: "Gift" }
];

export const SalesTypeForGiveOffer = [
    { id: 1, sales: "FirstSale", tr: "Yeni Kayıt", en: "First Sale", az: "Yeni Qeydiyyat", ar: "تسجيل جديد" },
    {
        id: 2,
        sales: "ExtraPackage",
        tr: "İkinci Kayıt",
        en: "Second Registration",
        az: "İkinci Qeydiyyat",
        ar: "تسجيل ثانوي"
    },
    { id: 3, sales: "Extension", tr: "Uzatma", en: "Extension", az: "Təcili", ar: "تمديد" },
    {
        id: 4,
        sales: "ChangePackage",
        tr: "Paket Değişikliği",
        en: "Package Change",
        az: "Paket Dəyişikliyi",
        ar: "تغيير الباقة"
    },
];

export const TermStatusName = [
    { id: 0, en: "Passive", tr: "Pasif", az: "Pasif", ar: "Pasif" },
    { id: 1, en: "Active", tr: "Aktif", az: "Aktif", ar: "Aktif" },
    { id: 2, en: "Future", tr: "Gelecek", az: "Gelecek", ar: "Gelecek" },
    { id: 3, en: "Deleted", tr: "Silinmiş", az: "Silinmiş", ar: "Silinmiş" },
    { id: 5, en: "Pending", tr: "Onay Bekleyen", az: "Onay Bekleyen", ar: "Onay Bekleyen" },
];

export const CurrentCardType = [
    { id: 0, en: "Person", tr: "Şahıs", az: "Şahıs", ar: "Şahıs" },
    { id: 1, en: "Company", tr: "Firma", az: "Firma", ar: "Firma" },
];

export const UseStatus = [
    { id: 0, name: 'Kullanıldı' },
    { id: 1, name: 'Kullanılmadı' },
];

export const DiscountRateRanges = [
    { id: 0, name: '%0 - %5' },
    { id: 1, name: '%5 - %10' },
    { id: 2, name: '%10 - %15' },
    { id: 3, name: '%15 - %20' },
    { id: 4, name: '%20 - %25' },
    { id: 5, name: '%25 - %30' },
    { id: 6, name: '%30 - %35' },
    { id: 7, name: '%35 - %40' },
    { id: 8, name: '%40 - %45' },
    { id: 9, name: '%45 - %50' },
    { id: 10, name: '%50 - %55' },
    { id: 11, name: '%55 - %60' },
    { id: 12, name: '%60 - %65' },
    { id: 13, name: '%65 - %70' },
    { id: 14, name: '%70 - %75' },
    { id: 15, name: '%75 - %80' },
    { id: 16, name: '%80 - %85' },
    { id: 17, name: '%85 - %90' },
    { id: 18, name: '%90 - %95' },
    { id: 19, name: '%95 - %100' },
];

export const InvoiceStatus = [
    { id: 0, name: 'Onaylanmayan' },
    { id: 1, name: 'Onaylanan' },
];

export const RegisterType = [
    { id: 0, name: 'Şahıs' },
    { id: 1, name: 'Firma' }
];

export const TeacherStatus = [
    { id: 0, name: 'Pasif' },
    { id: 1, name: 'Aktif' },
    { id: 2, name: 'Ayrılacak' },
];

export const Banks = [
    { id: 0, name: 'Garanti Bankası', icon: Garanti },
    { id: 1, name: 'Akbank', icon: Akbank },
    { id: 2, name: 'Yapı Kredi', icon: Yapikredi },
    { id: 3, name: 'Türkiye İş Bankası', icon: IsBankasi },
    { id: 4, name: 'QNB Finansbank', icon: Finansbank },
    { id: 5, name: 'Halkbank', icon: Halkbank }
];

export const PaymentTypeName = [
    { id: 0, name: 'Credit Card', tr: 'Kredi Kartı' },
    { id: 1, name: 'Bank Transfer', tr: 'Havale/EFT' },
    { id: 2, name: 'Digital Credit', tr: 'Dijital Kredi' },
    { id: 3, name: 'Western Union', tr: 'Western Union' },
    { id: 4, name: 'Pay Cash In Hand', tr: 'Elden Ödeme' },
    { id: 5, name: 'PTT/UPT', tr: 'PTT/UPT' },
    { id: 6, name: 'MoneyGram', tr: 'MoneyGram' },
];

export const CombinedDiscount = [
    { id: 0, name: 'Kumbara İndirimi' },
    { id: 1, name: 'Referans İndirimi' }
];

export const CampaignType = [
    { id: 0, name: 'Kumbara İndirimi' },
    { id: 1, name: 'Referans İndirimi' },
    { id: 2, name: 'Diğer İndirimler' },
    { id: 3, name: 'Kampanya İndirimi' },
    { id: 4, name: 'Taksit İndirimi' },
    { id: 5, name: 'Aile İndirimi' }
];

//Silinecek
export const PaymentTypes = [
    { id: 0, name: 'Credit Card' },
    { id: 1, name: 'Bank Transfer' },
    { id: 2, name: 'Digital Credit' },
    { id: 3, name: 'Western Union' },
    { id: 4, name: 'Pay Cash In Hand' },
    { id: 5, name: 'PTT/UPT' },
    { id: 6, name: 'MoneyGram' },
];

export const PaymentOptions = [
    { id: 1, name: 'Tek Çekim' },
    { id: 2, name: '2 Taksit' },
    { id: 3, name: '3 Taksit' },
    { id: 4, name: '4 Taksit' },
    { id: 5, name: '5 Taksit' },
    { id: 6, name: '6 Taksit' },
    { id: 7, name: '7 Taksit' },
    { id: 8, name: '8 Taksit' },
    { id: 9, name: '9 Taksit' },
    { id: 10, name: '10 Taksit' },
    { id: 11, name: '11 Taksit' },
    { id: 12, name: '12 Taksit' },
];

export const Channels = [
    {
        id: 1,
        name: 'Student Page',
        tr: 'Öğrenci Ekranı',
        ar: 'Öğrenci Ekranı',
        az: 'Öğrenci Ekranı',
        en: 'Öğrenci Ekranı'
    },
    { id: 2, name: 'Panel', tr: 'Panel', ar: 'Panel', az: 'Panel', en: 'Panel' },
    { id: 3, name: 'Mobile App', tr: 'Mobil Uygulama', ar: 'Mobil Uygulama', az: 'Mobil Uygulama', en: 'Mobil Uygulama' },
    { id: 4, name: 'Landing Page', tr: 'Web Sitesi', ar: 'Web Sitesi', az: 'Web Sitesi', en: 'Web Sitesi' }
];

export const Minutes = [
    { id: 0, name: 10 },
    { id: 1, name: 20 },
    { id: 2, name: 30 },
    { id: 3, name: 40 },
    { id: 4, name: 50 },
    { id: 5, name: 60 }
];

export const TeacherBlockStatus = [
    { id: 4, tr: "Bloke Et", eng: 'Block', az: 'Bloke Et', ar: 'Bloke Et' },
    {
        id: 6,
        tr: "Geçiçi Olarak Bloke Et",
        eng: 'Temporarily Block',
        az: 'Geçiçi Olarak Bloke Et',
        ar: 'Geçiçi Olarak Bloke Et'
    }
];

export const LessonOperations = [
    { id: 0, name: 'Ders Sil' },
    { id: 1, name: 'Ders Ekle' }
];

export const CampaignMemberType = [
    { id: 0, name: 'Tüm Üyeler' },
    { id: 1, name: 'Aktif Öğrenciler' },
    { id: 2, name: 'Adaylar' },
    { id: 3, name: 'Pasif Öğrenciler' }
];

export const CampaignPopulationType = [
    { id: 0, name: 'Bireysel' },
    { id: 1, name: 'Kurumsal' }
];

//Öğrenciler detalı arama kısmında kullanılır
export const LessonCountSelect = [
    { id: 0, name: "0-5" },
    { id: 1, name: "5-10" },
    { id: 2, name: "10-20" },
    { id: 3, name: "20-30" },
    { id: 4, name: "30-40" },
    { id: 5, name: "40-50" },
    { id: 6, name: "50-60" },
    { id: 7, name: "60-70" },
    { id: 8, name: "70-80" },
    { id: 9, name: "80-90" },
    { id: 10, name: "90-100" },
    { id: 11, name: "100+" }
];

export const LessonDurationType = [
    { id: 10, name: "10 Dakika" },
    { id: 20, name: "20 Dakika" },
    { id: 30, name: "30 Dakika" },
    { id: 40, name: "40 Dakika" },
    // {id: 50, name: "50 Dakika"},
    // {id: 60, name: "60 Dakika"},
]

export const ProductDayCount = [
    { id: 2, name: "2 Gün" },
    { id: 3, name: "3 Gün" },
    { id: 5, name: "5 Gün" },

]

export const CandidateDiscountType = [
    { rate: 48, en: '48 Hours Discount', tr: '48 Saat İndirim', az: '48 Saat İndirim', ar: '48 Saat İndirim' }
];

export const AdditionalRightType = [
    { id: 1, tr: "Ders Erteleme", en: "Postpone Lesson", az: 'Ders Erteleme', ar: 'Ders Erteleme' },
    { id: 2, tr: "Ders Dondurma", en: "Freeze Lesson", az: 'Ders Dondurma', ar: 'Ders Dondurma' },
    { id: 3, tr: "Dersimi Kaçırdım", en: "Missed Lesson", az: 'Dersimi Kaçırdım', ar: 'Dersimi Kaçırdım' },
    {
        id: 4,
        tr: "Eğitmen/Saat Değişikliği",
        en: "Teacher/Time Change",
        az: 'Eğitmen/Saat Değişikliği',
        ar: 'Eğitmen/Saat Değişikliği'
    },
];

export const AdditionalInformations = [
    {
        id: 1,
        tr: 'Sistemimizde ders almaya başladığınızda sabit bir saatte kalma zorunluluğunuz yoktur. Sabah 06:00 ile gece 00:00 saatleri arasında uygun bulduğunuz zaman dilimlerinde hazırlamış olduğumuz öğrenci ekranlarından değişiklik sağlayabilirsiniz.',
        en: 'Sistemimizde ders almaya başladığınızda sabit bir saatte kalma zorunluluğunuz yoktur. Sabah 06:00 ile gece 00:00 saatleri arasında uygun bulduğunuz zaman dilimlerinde hazırlamış olduğumuz öğrenci ekranlarından değişiklik sağlayabilirsiniz.',
        az: 'Sistemimizde ders almaya başladığınızda sabit bir saatte kalma zorunluluğunuz yoktur. Sabah 06:00 ile gece 00:00 saatleri arasında uygun bulduğunuz zaman dilimlerinde hazırlamış olduğumuz öğrenci ekranlarından değişiklik sağlayabilirsiniz.',
        ar: 'Sistemimizde ders almaya başladığınızda sabit bir saatte kalma zorunluluğunuz yoktur. Sabah 06:00 ile gece 00:00 saatleri arasında uygun bulduğunuz zaman dilimlerinde hazırlamış olduğumuz öğrenci ekranlarından değişiklik sağlayabilirsiniz.',
    },
    {
        id: 2,
        tr: 'Fiyatlara KDV dahildir.',
        en: 'Fiyatlara KDV dahildir.',
        az: 'Fiyatlara KDV dahildir.',
        ar: 'Fiyatlara KDV dahildir.',
    },
    {
        id: 3,
        tr: 'Kredi kartı üzerinden yapılan ödemelerde vade farkı olmaksızın taksit imkanı sunulmaktadır.',
        en: 'Kredi kartı üzerinden yapılan ödemelerde vade farkı olmaksızın taksit imkanı sunulmaktadır.',
        az: 'Kredi kartı üzerinden yapılan ödemelerde vade farkı olmaksızın taksit imkanı sunulmaktadır.',
        ar: 'Kredi kartı üzerinden yapılan ödemelerde vade farkı olmaksızın taksit imkanı sunulmaktadır.'
    },
    {
        id: 4,
        tr: 'Havale olarak yapılan ödemelerde taksit imkanı bulunmamaktadır.',
        en: 'Havale olarak yapılan ödemelerde taksit imkanı bulunmamaktadır.',
        az: 'Havale olarak yapılan ödemelerde taksit imkanı bulunmamaktadır.',
        ar: 'Havale olarak yapılan ödemelerde taksit imkanı bulunmamaktadır.'
    },
    {
        id: 5,
        tr: 'Banka havale bilgilerini gönder.',
        en: 'Banka havale bilgilerini gönder.',
        az: 'Banka havale bilgilerini gönder.',
        ar: 'Banka havale bilgilerini gönder.'
    },
    {
        id: 6,
        tr: 'Yapay zeka ile sınırsız ders imkanı sunulmaktadır.',
        en: 'Yapay zeka ile sınırsız ders imkanı sunulmaktadır.',
        az: 'Yapay zeka ile sınırsız ders imkanı sunulmaktadır.',
        ar: 'Yapay zeka ile sınırsız ders imkanı sunulmaktadır.'
    }
];

export const CampaignEndDate = (brandId) => {
    switch (brandId) {
        case 1:
            return `#campaignCode# kampanya koduna sahip 48 saat indirimi için son kullanma tarihi <strong>#date#</strong>'dir`
        case 2:
            return `#campaignCode# kampaniya koduna sahib 48 saat endirimi üçün son istifadə tarixi <strong>#date#</strong> olaraq müəyyən edilmişdir.`
        case 3:
            return `تاريخ انتهاء خصم الـ 48 ساعة برمز الحملة #campaignCode# هو <strong>#date#</strong>`
        default:
            return `#campaignCode# kampanya koduna sahip 48 saat indirimi için son kullanma tarihi <strong>#date#</strong>'dir`
    }
}

export const PackageRightsItem = (brandId) => {
    switch (brandId) {
        case 1:
            return `#postponeRightCount# adet Ders Erteleme Hakkı<br />
#teacherHourChangeRightCount# adet Eğitmen Saat Değişikliği Hakkı<br />
#missedRightCount# adet Ders Kaçırdım Hakkı<br />
#freezeRightCount# adet Ders Dondurma Hakkı<br />
Videolar, eğitim materyelleri ve testler<br />
Size özel mentor<br />`
        case 2:
            return `#postponeRightCount# ədəd dərs təxirə salma hüququ<br />
#teacherHourChangeRightCount# ədəd müəllim saatı dəyişdirmə hüququ<br />
#missedRightCount# ədəd dərs qaçırma hüququ<br />
#freezeRightCount# ədəd dərs dondurmaq hüququ<br />
Videolar, eğitim materyelleri ve hüququ<br />
Sizə özəl olan mentor xidməti<br />`
        case 3:
            return `#postponeRightCount# حقوق تأجيل الدروس<br />
#teacherHourChangeRightCount# حقوق تغير التوقيت و المعلم<br />
#missedRightCount# فاتني الدروس<br />
#freezeRightCount# حق تجميد المقررات الدراسية<br />
مقاطع الفيديو الأدوات التدريبية والاختبارات <br />
مستشار تعليمي خاص لك<br />`
        default:
            return `#postponeRightCount# adet Ders Erteleme Hakkı<br />
#teacherHourChangeRightCount# adet Eğitmen Saat Değişikliği Hakkı<br />
#missedRightCount# adet Ders Kaçırdım Hakkı<br />
#freezeRightCount# adet Ders Dondurma Hakkı<br />
Videolar, eğitim materyelleri ve testler<br />
Size özel mentor<br />`
    }
}

export const WeekDays = [
    {
        id: 1,
        tr: "Pazartesi",
        en: 'Monday',
        az: 'Pazartesi',
        ar: 'Pazartesi'
    },
    {
        id: 2,
        tr: "Salı",
        en: 'Tuesday',
        az: 'Salı',
        ar: 'Salı'
    },
    {
        id: 3,
        tr: "Çarşamba",
        en: 'Wednesday',
        az: 'Çarşamba',
        ar: 'Çarşamba'
    },
    {
        id: 4,
        tr: "Perşembe",
        en: 'Thursday',
        az: 'Perşembe',
        ar: 'Perşembe'
    },
    {
        id: 5,
        tr: "Cuma",
        en: 'Friday',
        az: 'Cuma',
        ar: 'Cuma'
    },
    // { id: 6,
    //     tr: "Cumartesi",
    //     en: 'Saturday',
    //     az: 'Cumartesi',
    //     ar: 'Cumartesi'
    // },
    // { id: 0,
    //     tr: "Pazar",
    //     en: 'Sunday',
    //     az: 'Pazar',
    //     ar: 'Pazar'
    // },
];

export const ApplicationList = [
    { id: 1, name: 'Konuşarak Öğren' },
    { id: 2, name: 'Azer Ingilisce' },
    { id: 3, name: 'Alingliziah' },
    { id: 4, name: "Inter Ingilizce" }
]

export const LanguageList = [
    { id: 1, name: 'Türkçe' },
    { id: 2, name: 'Azerice' },
    { id: 3, name: 'Arapça' },
]

export const RequestTypes = [
    { id: 1, name: "GET" },
    { id: 2, name: "POST" },
    { id: 3, name: "PUT" },
    { id: 4, name: "DELETE" },
]

export const ParameterTypes = [
    { id: 1, name: "memberId" },
    { id: 2, name: "termId" }
]

export const LogTypes = [
    { id: 0, tr: "Öğrenci", en: "Öğrenci", az: "Öğrenci", ar: "Öğrenci" },
    { id: 1, tr: "Çalışan", en: "Çalışan", az: "Çalışan", ar: "Çalışan" },
    { id: 2, tr: "Sistem", en: "Sistem", az: "Sistem", ar: "Sistem" },
]

export const LogCategoryList = [
    {
        id: 1,
        tr: "Hesap",
        en: "Account",
        az: "Hesap",
        ar: "Hesap",
    },
    {
        id: 2,
        tr: "Muhasebe",
        en: "Accounting",
        az: "Muhasebe",
        ar: "Muhasebe",
    },
    {
        id: 3,
        tr: "Aday",
        en: "Candidate",
        az: "Aday",
        ar: "Aday",
    },
    {
        id: 4,
        tr: "Dönem",
        en: "Term",
        az: "Dönem",
        ar: "Dönem",
    },
    {
        id: 5,
        tr: "Kurumsal",
        en: "Corporate",
        az: "Kurumsal",
        ar: "Kurumsal",
    },
    {
        id: 6,
        tr: "Posta",
        en: "Mail",
        az: "Posta",
        ar: "Posta",
    },
    {
        id: 7,
        tr: "SMS",
        en: "SMS",
        az: "SMS",
        ar: "SMS",
    },
    {
        id: 8,
        tr: "WhatsApp",
        en: "Whatsapp",
        az: "WhatsApp",
        ar: "WhatsApp",
    },
    {
        id: 9,
        tr: "Eğitmen",
        en: "Teacher",
        az: "Eğitmen",
        ar: "Eğitmen",
    },
    {
        id: 10,
        tr: "Kitap",
        en: "Book",
        az: "Kitap",
        ar: "Kitap",
    },
    {
        id: 11,
        tr: "Sistem",
        en: "System",
        az: "Sistem",
        ar: "Sistem",
    },
    {
        id: 12,
        tr: "Pazar",
        en: "Market",
        az: "Pazar",
        ar: "Pazar",
    },
    {
        id: 13,
        tr: "Rapor",
        en: "Report",
        az: "Rapor",
        ar: "Rapor",
    },
    {
        id: 14,
        tr: "Genel",
        en: "General",
        az: "Genel",
        ar: "Genel",
    },
    {
        id: 15,
        tr: "Pazarlama",
        en: "Marketing",
        az: "Pazarlama",
        ar: "Pazarlama",
    },
    {
        id: 16,
        tr: "Bilet",
        en: "Ticket",
        az: "Bilet",
        ar: "Bilet",
    },
    {
        id: 17,
        tr: "Referans",
        en: "Reference",
        az: "Referans",
        ar: "Referans",
    },
    {
        id: 18,
        tr: "Arama Süreçleri",
        en: "Lesson Call Processes",
        az: "Arama Süreçleri",
        ar: "Arama Süreçleri",
    },
    {
        id: 19,
        tr: "Öğrenci",
        en: "Member",
        az: "Öğrenci",
        ar: "Öğrenci",
    },
    {
        id: 20,
        tr: "Aday Havuzu",
        en: "Member Pool",
        az: "Aday Havuzu",
        ar: "Aday Havuzu",
    },
    {
        id: 21,
        tr: "Görev",
        en: "Task",
        az: "Görev",
        ar: "Görev",
    },
    {
        id: 22,
        tr: "Hatırlatıcı",
        en: "Reminder",
        az: "Hatırlatıcı",
        ar: "Hatırlatıcı",
    },
    {
        id: 23,
        tr: "Bilet",
        en: "Ticket",
        az: "Bilet",
        ar: "Bilet",
    }
];


export const MemberResourceList = [
    {
        id: 1,
        tr: "Branding",
        en: "Branding",
        az: "Branding",
        ar: "Branding"
    },
    {
        id: 2,
        tr: "Referans",
        en: "Referans",
        az: "Referans",
        ar: "Referans"
    },
    {
        id: 3,
        tr: "Diğer",
        en: "Diğer",
        az: "Diğer",
        ar: "Diğer"
    }
];

export const CampaignMarketDetailTypes = [
    {
        id: 0,
        tr: "Kod Girerek Uygulama",
        en: "Kod Girerek Uygulama",
        az: "Kod Girerek Uygulama",
        ar: "Kod Girerek Uygulama"
    },
    {
        id: 1,
        tr: "Otomatik Uygulama",
        en: "Otomatik Uygulama",
        az: "Otomatik Uygulama",
        ar: "Otomatik Uygulama"
    },
    {
        id: 2,
        tr: "Otomatik listeleme",
        en: "Otomatik listeleme",
        az: "Otomatik listeleme",
        ar: "Otomatik listeleme"
    }
]

export const ReferenceSourceList = [
    {
        id: 1,
        tr: "Öğrenci Paneli",
        en: "Öğrenci Paneli",
        az: "Öğrenci Paneli",
        ar: "Öğrenci Paneli",
        content: "ogrenci_paneli"
    },
    {
        id: 2,
        tr: "Referansatör",
        en: "Referansatör",
        az: "Referansatör",
        ar: "Referansatör",
        content: "referansator"
    },
    {
        id: 3,
        tr: "Satışçı",
        en: "Satışçı",
        az: "Satışçı",
        ar: "Satışçı",
        content: "satisci"
    },
    {
        id: 4,
        tr: "Verimlilik Danışmanı",
        en: "Verimlilik Danışmanı",
        az: "Verimlilik Danışmanı",
        ar: "Verimlilik Danışmanı",
        content: "verimlilik_danismani"
    },
    {
        id: 5,
        tr: "Santral",
        en: "Santral",
        az: "Santral",
        ar: "Santral",
        content: "santral"
    },
    {
        id: 6,
        tr: "WhatsApp",
        en: "WhatsApp",
        az: "WhatsApp",
        ar: "WhatsApp",
        content: "whatsapp"
    },
    {
        id: 7,
        tr: "NPS Aramasından Geldi",
        en: "NPS Aramasından Geldi",
        az: "NPS Aramasından Geldi",
        ar: "NPS Aramasından Geldi",
        content: "nps"
    },
    {
        id: 8,
        tr: "İade görevi araması",
        en: "İade görevi araması",
        az: "İade görevi araması",
        ar: "İade görevi araması",
        content: "iade_gorevi"
    },
    {
        id: 9,
        tr: "Aktif-Pasif Referans Arama Görevi",
        en: "Aktif-Pasif Referans Arama Görevi",
        az: "Aktif-Pasif Referans Arama Görevi",
        ar: "Aktif-Pasif Referans Arama Görevi",
        content: "referans_arama_gorevi"
    },
    {
        id: 10,
        tr: "Referans Kampanya Mesajı",
        en: "Referans Kampanya Mesajı",
        az: "Referans Kampanya Mesajı",
        ar: "Referans Kampanya Mesajı",
        content: "referans_kampanya_mesaji"
    }
]

export const UseCaseList = [
    { id: 1, en: "İş hayatında kullanmak", tr: "İş hayatında kullanmak", az: "İş hayatında kullanmak", ar: "İş hayatında kullanmak" },
    { id: 2, en: "Kariyerinde yükselmek", tr: "Kariyerinde yükselmek", az: "Kariyerinde yükselmek", ar: "Kariyerinde yükselmek" },
    { id: 3, en: "İş toplantılarında kullanmak", tr: "İş toplantılarında kullanmak", az: "İş toplantılarında kullanmak", ar: "İş toplantılarında kullanmak" },
    { id: 4, en: "Mülakat iş görüşmesine katılmak", tr: "Mülakat iş görüşmesine katılmak", az: "Mülakat iş görüşmesine katılmak", ar: "Mülakat iş görüşmesine katılmak" },
    { id: 5, en: "Yurtdışı iş seyahatleri", tr: "Yurtdışı iş seyahatleri", az: "Yurtdışı iş seyahatleri", ar: "Yurtdışı iş seyahatleri" },
    { id: 6, en: "Yurtdışı tatil", tr: "Yurtdışı tatil", az: "Yurtdışı tatil", ar: "Yurtdışı tatil" },
    { id: 7, en: "Yurtdışına çalışmak için çıkmak", tr: "Yurtdışına çalışmak için çıkmak", az: "Yurtdışına çalışmak için çıkmak", ar: "Yurtdışına çalışmak için çıkmak" },
    { id: 8, en: "Yakın akraba veya arkadaş ile iletişim", tr: "Yakın akraba veya arkadaş ile iletişim", az: "Yakın akraba veya arkadaş ile iletişim", ar: "Yakın akraba veya arkadaş ile iletişim" },
    { id: 9, en: "Okulda kullanacak öğrenciler", tr: "Okulda kullanacak öğrenciler", az: "Okulda kullanacak öğrenciler", ar: "Okulda kullanacak öğrenciler" },
];

export const LevelList = [
    { id: 2, en: "Başlangıç (Starter)", tr: "Başlangıç (Starter)", az: "Başlangıç (Starter)", ar: "Başlangıç (Starter)" },
    { id: 3, en: "Temel (Beginner)", tr: "Temel (Beginner)", az: "Temel (Beginner)", ar: "Temel (Beginner)" },
    { id: 4, en: "Orta (Intermediate)", tr: "Orta (Intermediate)", az: "Orta (Intermediate)", ar: "Orta (Intermediate)" },
    { id: 5, en: "İleri (Advanced)", tr: "İleri (Advanced)", az: "İleri (Advanced)", ar: "İleri (Advanced)" },
];

export const CriterionTypeList = [
    { id: 0, en: "NPS", tr: "NPS", az: "NPS", ar: "NPS" },
    { id: 1, en: "Demo NPS", tr: "Demo NPS", az: "Demo NPS", ar: "Demo NPS" },
    { id: 2, en: "Mentor NPS", tr: "Mentor NPS", az: "Mentor NPS", ar: "Mentor NPS" },
];