import classNames from "classnames";
import tr from "date-fns/locale/tr";
import React, { useCallback, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Whatsapp } from "../../../../../assets/svg";
import {
  BrandBadge,
  Click2Call,
  CopyToClipboard,
  CorporateBadge,
  DropdownSearch,
  Input,
  LoadingSpinner,
  MemberStatusBadge,
  Modal,
  showCenteredAlertTwoButtons,
  showCenteredHtmlAlert,
  showTopAlert,
  TextBadge
} from "../../../../../components";
import {
  allowLatinCharacters,
  allowPasteLatinCharacters,
  getLanguageTypeByLanguageKey,
  getUseCaseById,
  ISOStringToDate2,
  maskPhoneNumber,
  validateEmail,
  formatTimezoneOffset
} from "../../../../../components/Utility";
import { AIKeys, Brands, Gender, Job, MemberProfileStatus, MemberStatus } from "../../../../../consts/Constants";
import { CustomEventDescriptions, CustomEvents } from "../../../../../consts/DataLayer";
import useAuth from "../../../../../hooks/Log/useAuth";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import useMemberCorporateInformations from "../../../../../hooks/Member/useMemberCorporateInformations";
import useMemberInPool from "../../../../../hooks/Member/useMemberInPool";
import useMemberStatus from "../../../../../hooks/Member/useMemberStatus";
import useAiRouting from "../../../../../hooks/Utils/useAiRouting";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { getButtonPermission } from "../../../../../rights/Utility";
import {
  AddOrUpdateMemberProfileNote,
  CheckIsDemoStudent,
  GetMemberDemoRightCount,
  GetMemberProfile,
  UpdateMemberDemoRightCount,
  UpdateMemberProfile
} from "../../../../../services/Member/MemberProfileService";
import { GetAiUseCaseSessionExists } from "../../../../../services/UseCaseHistory/UseCaseHistoryService";
import Accordion from "../../Common/Accordion";
import { ReasonToLearnEnglishModal } from "../../index";
import MemberActiveCampaign from "../Common/MemberActiveCampaign";
import ContactModal from "./components/ContactModal";
import MemberSalesAiAnalysis from "./components/MemberSalesAiAnalysis";
import NpsScoresModal from "./components/NpsScoresModal";
import SalesAiAnalysisModal from "./components/SalesAiAnalysisModal";
import FamilyModal from "./components/FamilyModal";

const Profile = () => {
  registerLocale("tr", tr);

  const strings = useLocalization();

  const dataLayer = useDataLayer();
  const { memberId } = useParams() || false;
  const { employee, employeeTypeId, checkPermission } = useAuth();

  const language = useSelector(state => state.localizationStore.language);
  const notSpecified = strings.errors.it_is_not_specified;

  const memberStatus = useMemberStatus(memberId);
  const memberCorporateInformations = useMemberCorporateInformations(memberId);
  const memberBrandId = useMemberBrand(memberId);
  const isMemberInPool = useMemberInPool(memberId) || false;

  const memberPoolRoles = getButtonPermission({ group: "memberDetail", name: "memberDetailPoolRole" });
  const memberPoolPermission = isMemberInPool ? checkPermission(memberPoolRoles, employeeTypeId) : true;

  const firstProfession = { id: -1, name: strings.form_info.choose_job };
  //Meslekler İngilizce geliyor. Onları dil Türkçe ise Türkçeye çeviriyoruz.
  const jobList = Job.map(job => ({ id: job.id, name: job[language] }));
  const professionList = [firstProfession, ...jobList];

  const [member, setMember] = useState({});
  const [modalContent, setModalContent] = useState({});
  const [modal, setModal] = useState(false);

  const [fullName, setFullName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [job, setJob] = useState(null);
  const [professionId, setProfessionId] = useState(-1);
  const [birthDate, setBirthDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [gender, setGender] = useState(null);
  const [profileNote, setProfileNote] = useState("");
  const [demoRightCount, setDemoRightCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showAiDemoAnalysis, setShowAiDemoAnalysis] = useState(null);
  const [aiUseCaseSessionExists, setAiUseCaseSessionExists] = useState({});

  const levelTestUseCaseId = useAiRouting(AIKeys.LEVEL_FINDER);
  const interviewProUseCaseId = useAiRouting(AIKeys.INTERVIEW_PR0);

  const handleWhatsAppClick = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  //Member Profile bilgileri getiriliyor
  const getMemberProfile = async (memberId) => {

    const model = {
      memberId: memberId,
      languageTitleType: getLanguageTypeByLanguageKey(language)
    }

    const result = await GetMemberProfile(model);

    if (result.status === 200) {
      setMember(JSON.parse(result.content));
    } else {
      showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
    }
  }

  //Member demo hakkı bilgisi getiriliyor
  const getMemberDemoRightCount = async (memberId) => {
    const result = await GetMemberDemoRightCount(memberId);

    if (result.status === 200)
      setDemoRightCount(demoRightCount => JSON.parse(result.content));
  }

  const submitHandle = async (e) => {
    e.preventDefault();

    let valid = true;
    let errorList = '';

    if (fullName === '') {
      valid = false;
      errorList += strings.errors.full_name_null_error + "<br />"
    }

    //E-Mail alanı olmak zorunda değil. Kontrolü yalnızca boş veya null değilse yapacağız
    if (email !== '' && email !== null) {
      if (!validateEmail(email)) {
        valid = false;
        errorList += strings.errors.email_format_error + "<br />"
      }
    }

    if (valid) {
      showCenteredAlertTwoButtons
        (
          strings.alert.centered_alert_two_buttons.title_confirm,
          strings.alert.centered_alert_two_buttons.save_question,
          strings.button.save,
          strings.button.cancel,
          sendToEndPoint
        )
    } else {
      showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error")
    }
  };

  const sendToEndPoint = async () => {
    setLoading(loading => true);

    const model = {
      memberId: member.memberId,
      createUserId: employee.EmployeeId,
      fullname: fullName,
      phoneNumber: phoneNumber,
      email: email,
      professionId: professionId !== -1 ? professionId : null,
      birthday: birthDate,
      gender: gender
    }

    const modelForNote = {
      createUserId: employee.EmployeeId,
      memberId: member.memberId,
      note: profileNote
    }

    let valid = true;

    if (valid) {

      const result = await UpdateMemberProfile(model);
      const resultForNote = await AddOrUpdateMemberProfileNote(modelForNote);
      const resultForMemberDemoRight = await UpdateMemberDemoRightCount(memberId, demoRightCount);

      if (result.status === 200 && resultForNote.status === 200 && resultForMemberDemoRight.status === 200) {
        showTopAlert(strings.member.member_detail.profile.profile_success_message, "success");
        await getMemberProfile(member.memberId);
        await getMemberDemoRightCount(member.memberId);

        if (profileNote?.length > 0)
          //DATA LAYER LOGGED
          dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.TAKE_NOTE_ON_STUDENT_PROFILE);

      } else if (resultForNote.status !== 200) {
        showTopAlert(strings.member.member_detail.profile.profile_note_error_message, "error");
      } else if (resultForMemberDemoRight.status !== 200) {
        showTopAlert(strings.member.member_detail.profile_demo_right_error_message, "error");
      } else {
        showTopAlert(strings.member.member_detail.profile.profile_error_message, "error");
      }
    }

    setLoading(loading => false);
  }

  const checkIsDemoStudent = useCallback(async (memberId) => {
    const result = await CheckIsDemoStudent(memberId);
    if (result.status === 200) {
      const tempShowAiDemoAnalysis = JSON.parse(result.content) ?? false;
      setShowAiDemoAnalysis(tempShowAiDemoAnalysis);
    }
  }, []);

  const getAiUseCaseSessionExists = useCallback(async (memberId, useCaseIds) => {
    const result = await GetAiUseCaseSessionExists(memberId, useCaseIds);
    if (result.status === 200) {
      const resultContent = JSON.parse(result.content);
      const levelTestUseCaseExisting = resultContent.find(x => x.useCaseId === levelTestUseCaseId)?.isExists ?? false;
      const interviewProUseCaseExisting = resultContent.find(x => x.useCaseId === interviewProUseCaseId)?.isExists ?? false;
      setAiUseCaseSessionExists({
        levelTestUseCaseExisting,
        interviewProUseCaseExisting
      });
    }
  }, [levelTestUseCaseId, interviewProUseCaseId]);

  useEffect(() => {
    if (memberId && memberStatus === MemberStatus.Candidate) {
      checkIsDemoStudent(memberId);
      if (levelTestUseCaseId !== -1 && interviewProUseCaseId !== -1) {
        getAiUseCaseSessionExists(memberId, [levelTestUseCaseId, interviewProUseCaseId]);
      }
    }
  }, [memberId, memberStatus, checkIsDemoStudent, getAiUseCaseSessionExists, levelTestUseCaseId, interviewProUseCaseId]);

  useEffect(() => {
    if (job) {
      setProfessionId(professionId => job.id);
    }
  }, [job])

  useEffect(() => {
    if (Object.keys(member).length !== 0) {
      setFullName(fullName => member.fullname);
      setPhoneNumber(phoneNumber => member.phoneNumber);
      setEmail(email => member.email);

      if (member.professionId) {
        setProfessionId(professionId => member.professionId);
        let tempJob = jobList.filter(job => job.id === member.professionId);
        tempJob.length >= 1 && setJob(job => tempJob[0]);
      } else {
        setJob(job => firstProfession)
      }

      if (member.birthday) {
        let tempDate = ISOStringToDate2(member.birthday);
        let dateArr = tempDate.replace(/\./g, "-").replace(/\//g, "-").split("-");
        setBirthDate(birthDate => new Date(dateArr[2], dateArr[1] - 1, dateArr[0], 12, 0, 0))
      }

      setGender(gender => member.gender);
      setProfileNote(profileNote => member.note);
    }
  }, [member])

  useEffect(() => {
    if (profileNote?.length > 500) {
      setProfileNote(profileNote => profileNote.slice(0, 500))
    }
  }, [profileNote])

  useEffect(() => {

    let abortController = new AbortController();

    if (memberId) {
      getMemberProfile(memberId);
      getMemberDemoRightCount(memberId);
    }

    return () => {
      abortController.abort();
    }
  }, [])

  return (
    <>
      <form onSubmit={submitHandle}>
        <div className="flex flex-col md:flex-row gap-2.5 md:items-center flex-wrap md:ml-[10px]">
          <TextBadge>
            <span className="font-bold mr-0.5 max-sm:text-[12px]">Id:</span>
            <p className="max-sm:text-[12px]">{memberId}</p>
            <CopyToClipboard iconClassName="text-[16px]" text={memberId} className="ml-1 mt-1" />
          </TextBadge>
          {memberCorporateInformations.corporateStatus === 1 && (
            <CorporateBadge firmName={memberCorporateInformations.companyName} />
          )}
          <BrandBadge brandId={memberBrandId} />
          <MemberStatusBadge memberStatus={memberStatus} />
          {MemberStatus.Candidate === memberStatus && <MemberActiveCampaign memberId={memberId} />}
        </div>

        {/* Ad-Soyad, Telefon Numarası, E-Posta Adresi */}
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <div className="flex items-center gap-1">
                <label className="text-[13px] font-bold mb-2" htmlFor="username">{strings.form_info.full_name}</label>
                <CopyToClipboard text={fullName} className="mb-1" />
              </div>
              <Input
                type="text"
                value={fullName || ''}
                onChange={(e) => setFullName(e.target.value)}
                onKeyDown={allowLatinCharacters}
                onPaste={allowPasteLatinCharacters}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <div className="flex items-center gap-1">
                <label className="text-[13px] font-bold mb-2" htmlFor="phone">{strings.form_info.phone_number}</label>
                <CopyToClipboard text={phoneNumber} className="mb-1" />
              </div>
              <div className="relative">
                <input className="form-input w-full" value={maskPhoneNumber(phoneNumber) || ''} disabled={true} />
                {memberBrandId === Brands.KO &&
                  <div className="absolute right-12 top-2.5">
                    <Click2Call phoneNumber={phoneNumber} fullName={fullName} />
                  </div>
                }
                <div className="absolute right-5 top-[9px]">
                  <div className="relative group">
                    <button
                      type="button"
                      className="material-symbols-outlined cursor-pointer hover:opacity-80 transition-opacity"
                      onClick={() => handleWhatsAppClick(phoneNumber)}
                    >
                      <img className="w-[25px] h-[25px]" src={Whatsapp} alt="" />
                    </button>
                    <div className="tooltip-top">{strings.member.member_detail.profile.whatsapp}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2" htmlFor="email">
                {strings.form_info.email}
              </label>
              <Input
                type="text"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Meslek, Doğum Tarihi, Demo Ders Hakkı */}
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2" htmlFor="job">
                {strings.form_info.job}
              </label>
              <DropdownSearch
                inputPlaceholder={strings.member.member_detail.profile.search_job}
                classnames="md:max-w-full"
                data={professionList}
                selectedData={job || firstProfession}
                setSelectedData={setJob}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2" htmlFor="birthday">
                {strings.form_info.birth_date}
              </label>
              <div className="relative">
                <DatePicker
                  locale="tr"
                  dateFormat="dd/MM/yyyy"
                  className="form-input w-full lg:max-w-[480px]"
                  selected={birthDate !== null && birthDate}
                  onChange={(date) => {
                    setBirthDate(date);
                    setIsOpen(false);
                  }}
                  placeholderText={
                    strings.form_info.date_placeholder
                  }
                  onClickOutside={() => setIsOpen(false)}
                  open={isOpen}
                  onFocus={() => setIsOpen(true)}
                />
                <button type="button" onClick={() => setIsOpen(!isOpen)}>
                  <span className="material-symbols-outlined absolute right-6 top-2 text-lg">
                    calendar_today
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2" htmlFor="usecase">
                {strings.form_info.demo_right_count}
              </label>
              <Input
                id="demoRight"
                type="number"
                value={demoRightCount}
                minValue="0"
                onChange={(e) => setDemoRightCount(+e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Kendi Seçtiği Seviye, AI ile Belirlenen Seviye, Timezone */}
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2">
                {strings.member.member_detail.profile.self_level_collection}
              </label>
              <Input
                id="selfLevelCollection"
                type="text"
                value={member?.selfLevelCollection?.length === 0
                  ? notSpecified
                  : member.selfLevelCollection
                  ?? ""
                }
                isDisabled={true}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2">
                {strings.member.member_detail.profile.ai_level_collection}
              </label>
              <Input
                id="aiLevelCollection"
                type="text"
                value={member?.aiLevelCollection?.length === 0
                  ? notSpecified
                  : member?.aiLevelCollection
                  ?? ""
                }
                isDisabled={true}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2">
                {strings.form_info.timezone}
              </label>
              <Input
                id="timezone"
                type="text"
                value={member?.timeZone ? formatTimezoneOffset(member.timeZone, strings.form_info.hour) : notSpecified}
                isDisabled={true}
              />
            </div>
          </div>
        </div>

        {/* Haftalık Seri, Haftalık Seri Ligi ve Sıralaması, Profil Görünürlüğü */}
        <div className="flex flex-col lg:flex-row lg:justify-between">

          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2">
                {strings.member.member_detail.profile.weekly_streak}
              </label>
              <Input
                id="weeklyStreak"
                type="text"
                value={member?.streak ?? notSpecified}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2">
                {strings.member.member_detail.profile.weekly_streak_league}
              </label>
              <Input
                id="weeklyStreak"
                type="text"
                value={member?.streakLeagueDetail ? member?.streakLeagueDetail?.title + " / " + member?.streakLeagueDetail?.rank + ". sırada" : notSpecified + " / " + notSpecified}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <label className="text-[13px] font-bold mb-2">
                {strings.member.member_detail.profile.profile_status}
              </label>
              <Input
                id="profile_status"
                type="text"
                value={member?.profileStatus === MemberProfileStatus.Public ? strings.member.member_detail.profile.public : strings.member.member_detail.profile.private}
                isDisabled={true}
              />
            </div>
          </div>
        </div>

        {/* Kullanım Amaçları, NPS Puanları, AI ile Belirlenen Seviye */}
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <div className="flex items-center gap-1 group">
                <label
                  htmlFor="usecase"
                  className={classNames("text-[13px] font-bold mb-2", {
                    "text-blue group-hover:underline": member?.reasonToLearnEnglishId !== 0
                  })}
                >
                  {strings.form_info.use_case}
                </label>
                {member?.reasonToLearnEnglishId !== 0 &&
                  <div className="relative">
                    <button
                      id="usecase"
                      type="button"
                      className="material-symbols-outlined mb-2 text-[18px] cursor-pointer"
                      onClick={() => { setModal(true); setModalContent({ element: "usecase" }); }}
                    >
                      info
                    </button>
                    <div className="tooltip-top -top-[55px]">
                      {strings.member.member_detail.profile.click_for_see_all_reasons}
                    </div>
                  </div>
                }
              </div>
              <Input
                type="text"
                isDisabled={true}
                value={getUseCaseById(member?.reasonToLearnEnglishId) || strings.errors.it_is_not_specified}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <div className="flex items-center gap-1 group">
                <label
                  htmlFor="nps"
                  className="text-[13px] font-bold mb-2 text-blue group-hover:underline"
                >
                  {strings.member.member_detail.profile.nps_scores}
                </label>
                <div className="relative">
                  <button
                    id="nps"
                    type="button"
                    className="material-symbols-outlined mb-2 text-[18px] cursor-pointer"
                    onClick={() => { setModal(true); setModalContent({ element: "nps" }); }}
                  >
                    info
                  </button>
                  <div className="tooltip-top -top-[55px]">
                    {strings.member.member_detail.profile.click_for_see_all_nps_scores}
                  </div>
                </div>
              </div>
              <Input
                type="text"
                isDisabled={true}
                value={strings.member.member_detail.profile.nps_type + ", " + strings.member.member_detail.profile.nps_comment + ", " + strings.member.member_detail.profile.nps_rate + ", " + strings.table.operation_date}
              />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <div className="flex items-center gap-1 group">
                <label
                  htmlFor="contact"
                  className="text-[13px] font-bold mb-2 text-blue group-hover:underline"
                >
                  {strings.member.member_detail.profile.contact.title}
                </label>
                <div className="relative">
                  <button
                    id="contact"
                    type="button"
                    className="material-symbols-outlined mb-2 text-[18px] cursor-pointer"
                    onClick={() => { setModal(true); setModalContent({ element: "contact" }); }}
                  >
                    info
                  </button>
                  <div className="tooltip-top -top-[55px] right-[-120px] left-[-120px]">
                    {strings.member.member_detail.profile.contact.click_for_see_all_contact_members}
                  </div>
                </div>
              </div>
              <Input
                type="text"
                isDisabled={true}
                value={strings.table.name + ", " + strings.table.status}
              />
            </div>
          </div>
        </div>

        {/* Cinsiyet */}
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
                <div className="flex items-center gap-1 group">
                  <label
                    htmlFor="family"
                    className={classNames("text-[13px] font-bold mb-2", {
                      "text-blue group-hover:underline": member?.hasFamily
                    })}
                  >
                    {strings.member.member_detail.profile.family_package.title}
                  </label>
                  {member?.hasFamily && 
                    <div className="relative">
                      <button
                        id="family"
                        type="button"
                        className="material-symbols-outlined mb-2 text-[18px] cursor-pointer"
                        onClick={() => { setModal(true); setModalContent({ element: "family" }); }}
                      >
                        info
                      </button>
                      <div className="tooltip-top -top-[55px]">
                        {strings.member.member_detail.profile.family_package.click_for_see_all_family_packages}
                      </div>
                    </div>
                  }
                </div>
                <Input
                  type="text"
                  isDisabled={true}
                  value={member?.hasFamily 
                    ? strings.member.member_detail.profile.family_package.family_members
                    : strings.member.member_detail.profile.family_package.not_has_family
                  }
                />
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]">
            <div className="flex flex-col px-[10px] pb-0">
              <p className="text-[13px] font-bold mb-2 cursor-default">{strings.form_info.gender}</p>
              <div className="mt-1 flex gap-2 ">
                {Gender && Gender.map((item, index) => (
                  <button
                    key={index}
                    id={item.id}
                    onClick={(e) => setGender(parseInt(e.target.id))}
                    type="button"
                    className={`group relative gender-btn ${gender === item.id ? 'border-blue bg-blue/20 text-blue' : ''}`}
                  >
                    <span className="material-symbols-outlined pointer-events-none">{item.icon}</span>
                    <div className="tooltip-top">
                      <p>{item.name}</p>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-5 w-full lg:w-[32.5%]"></div>
        </div>

        {/* Not */}
        <div className="mt-5">
          <div className="flex flex-col px-[10px] pb-0">
            <label className="text-[13px] font-bold mb-2" htmlFor="note">
              {strings.member.member_detail.candidate_operations.note}
            </label>
            <div className="relative">
              <textarea
                id="note"
                name="note"
                value={profileNote || ""}
                onChange={(e) => setProfileNote(e.target.value)}
                className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                type="text"
                rows="2"
              >
              </textarea>
              <p className={classNames("absolute -bottom-4 right-0", {
                "text-red": profileNote?.length >= 490
              })}>
                {profileNote?.length ? profileNote?.length : 0}<span className="font-bold">/500</span>
              </p>
            </div>
          </div>
        </div>

        {memberPoolPermission &&
          <button
            type="submit"
            disabled={loading}
            className={classNames("button search-button text-white bg-blue border-blue mx-auto w-[100px] mt-7", {
              "opacity-70 hover:opacity-70 cursor-not-allowed": loading
            })}
            onClick={submitHandle}
          >
            {loading && <LoadingSpinner />}
            {!loading && strings.button.save}
          </button>
        }

        {/* Demo Satış Analizi */}
        {memberStatus === MemberStatus.Candidate && (
          <Accordion
            outSideClose={false}
            isOpen={false}
            title={strings.member.member_detail.profile.sales_analysis.title}
            content={
              <MemberSalesAiAnalysis
                memberId={memberId}
                showAiDemoAnalysis={showAiDemoAnalysis}
                aiUseCaseSessionExists={aiUseCaseSessionExists}
                modal={modal}
                modalContent={modalContent}
                setModal={setModal}
                setModalContent={setModalContent}
              />
            }
          />
        )}
      </form>

      {(Object.keys(modalContent).length !== 0 && (
        <Modal
          modal={modal}
          setModal={setModal}
          classes={{
            modal: 
              modalContent.element === "usecase" ? 'h-[420px] max-w-[350px]' :
              modalContent.element === "nps" ? 'h-[90vh] max-w-[800px]' :
              modalContent.element === "contact" ? 'h-[800px] max-h-[90vh] max-w-[1000px]' :
              modalContent.element === "salesaianalysis" ? 'h-[90vh] max-w-[900px]' :
              modalContent.element === "family" ? 'h-[90vh] max-w-[800px]' : ''
          }}
        >
          {modalContent.element === "usecase" && <ReasonToLearnEnglishModal memberId={member?.memberId} />}
          {modalContent.element === "nps" && <NpsScoresModal memberId={member?.memberId} />}
          {modalContent.element === "contact" && <ContactModal memberId={member?.memberId} />}
          {modalContent.element === "family" && <FamilyModal memberId={member?.memberId} timeZone={employee?.TimeZone} />}
          {modalContent.element === "salesaianalysis" && 
            <SalesAiAnalysisModal 
                memberId={memberId}
                showAiDemoAnalysis={showAiDemoAnalysis}
                aiUseCaseSessionExists={aiUseCaseSessionExists}
                {...modalContent.props}
            />
          }
        </Modal>
      ))}
    </>
  );
};

export default Profile;